import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import ProductsController from '../../core/ProductsMobx'
import { getGlobalPositionGraphic, getGlobalPositionSummaryGraphic } from '../../utils/fintup-api'
import transverser from '../../utils/transverser'
import utils from '../../utils/utils'
import moment from 'moment'
import RSVP from 'rsvp'

class GlobalPositionGraphicLineMobx {
  constructor() {
    extendObservable(this, {
      summaryGrapficData: {},
      graphicData: {}
    })
  }

  get products() {
    let filterProducts = ProductsController.getProducts
    return filterProducts
  }

  getProductFromKey(key) {
    if (key.endsWith('summary')) {
      return {}
    }
    let aKeys = key.split('_')
    let lastKeyPart = aKeys[2]
    return this.products[lastKeyPart]
  }

  getGlobalPositionGraphicOperation(requestData) {
    if (requestData.policyNumber) {
      return getGlobalPositionGraphic(requestData)
    } else {
      return getGlobalPositionSummaryGraphic(requestData)
    }
  }

  obtainPromise(key) {
    let aKeys = key.split('_')
    let firstKeyPart = `${aKeys[0]}_${aKeys[1]}`
    let promises = {
      amount_1m: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'month', number: 1, type: 'amount', productId: product.productId, policyNumber: product.policyNumber }) },
      amount_1y: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'year', number: 1, type: 'amount', productId: product.productId, policyNumber: product.policyNumber }) },
      amount_3y: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'year', number: 3, type: 'amount', productId: product.productId, policyNumber: product.policyNumber }) },
      amount_max: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'max', number: 1, type: 'amount', productId: product.productId, policyNumber: product.policyNumber }) },
      percentage_1m: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'month', number: 1, type: 'percentage', productId: product.productId, policyNumber: product.policyNumber }) },
      percentage_1y: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'year', number: 1, type: 'percentage', productId: product.productId, policyNumber: product.policyNumber }) },
      percentage_3y: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'year', number: 3, type: 'percentage', productId: product.productId, policyNumber: product.policyNumber }) },
      percentage_max: (product) => { return this.getGlobalPositionGraphicOperation({ period: 'max', number: 1, type: 'percentage', productId: product.productId, policyNumber: product.policyNumber }) }
    }
    return promises[firstKeyPart]
  }

  fillGraphicData(responses, responseTarget, graphType) {
    let titleKey = graphType.type === 'amount' ? 'GlobalPositionGraphic.miDinero' : 'GlobalPositionGraphic.crecimiento'
    return [{
      title: utils.translator.translate(titleKey),
      data: (transverser.get(responseTarget, [])(responses))
        .filter(item => {
          let graphValue = item.value
          return utils.isNumber(graphValue) && graphValue !== 'Infinity'
        })
        .map(item => {
          let graphValue = item.value
          let momentDate = moment(item.valueDate, 'DD/MM/YYYY').format('DD MMMM YYYY')
          if (graphType.type !== 'amount') {
            graphValue = graphValue.toFixed(2)
          }
          return {
            label: momentDate,
            data: graphValue
          }
        })
    }]
  }

  obtainGraphicDataKey(param, graphicProduct) {
    let typeObject
    if (param) {
      typeObject = param
    }
    return `${typeObject.type}_${typeObject.time}_${graphicProduct}`
  }

  getGraphic(graphicKey, graphType, graphicProduct) {
    let graficDataStore = this.graphicData
    if (graficDataStore[graphicKey]) {
      return RSVP.resolve(graficDataStore[graphicKey])
    } else {
      let promise = this.obtainPromise(graphicKey)
      let product = this.getProductFromKey(graphicKey)
      return promise(product)
        .then(response => {
          let graphicData = this.fillGraphicData(response, 'data.position', graphType)
          let newKey = this.obtainGraphicDataKey(graphType, graphicProduct)
          this.graphicData[newKey] = graphicData
          return RSVP.resolve(graphicData)
        })
    }
  }

  destroyGraphicData() {
    this.summaryGrapficData = {}
    this.graphicData = {}
  }
}

var GlobalPositionGraphicLineModel = new GlobalPositionGraphicLineMobx()
export default observer(GlobalPositionGraphicLineModel)
