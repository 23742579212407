import React from 'react'
import FintupView from "../FintupView"
import './RegisterCode.scss'
import FintupButton from '../common/forms/FintupButton'
import navUtils from '../../core/navUtils'
import SingleFormContainer from '../common/SingleFormContainer'
import LoadingApp from '../common/LoadingApp'
import InputTextLabel from '../common/forms/InputTextLabel'
import constants from '../../utils/constants'
import utils from '../../utils/utils'
import { injectIntl } from 'react-intl'
import { postActivationUser, doLoginPost } from '../../utils/fintup-api'
import FintupComponent from '../FintupComponent'
import classNames from 'classnames'
import loginHelper from '../home/loginHelper'
import transverser from '../../utils/transverser'


class RegisterCode extends FintupComponent {

  constructor (props) {
    super(props)
    this.state = {
      activationProcess: false,//false, //Change to false
      activationStatus: false,//false, //Change to false
      code: '',
      alert: {
        text: '',
        type: ''
      }, 
      timeRedirectLogin: 2000
    }
  }

  doValidate(){
    if (utils.isEmpty(this.state.code)) {
      this.setStatePromise({
        alert: {
          text: this.props.intl.formatMessage({ id: 'activationCodeView.errorEmpty' }),
          type: constants.alertType.ERROR
        }
      })
      return
    }

    let data = {
        token: this.state.code
    }
    postActivationUser(data)
      .then(() => {
        return this.setStatePromise({
          alert: {
            text: this.props.intl.formatMessage({ id: 'activationCodeView.ok' }),
            type: constants.alertType.SUCCESS
          },
          activationProcess: true,
          activationStatus: true
        }).then(() => {
          setTimeout(() => this.doLogin(), this.state.timeRedirectLogin)
        })
      })
      .catch(this.handleServiceError.bind(this))
      .catch(() => this.setStatePromise({activationProcess: false}))
    return
  }

  doLogin(){
    doLoginPost({
      username: this.props.user.email,
      password: this.props.user.password
    })
    .then(loginHelper.responseSuccessLogin.bind(this))
    .catch(this.handleServiceError.bind(this))
  }

  handleChange (data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    this.setState({[fieldName]: fieldValue})
  }

  get mainView () {
    if(this.state.activationProcess){
      if(this.state.activationStatus){
        return (
          <LoadingApp className="noFullHeight marginTop50"/>
        )
        {/*<div>
            <FintupButton className="text-center" textKey="Login.button" action={this.doLogin.bind(this)}/>
          </div>*/}
      }
      else{
        return (<LoadingApp className="noFullHeight marginTop50"/>)
      }
    }
    else{
      return (
        <div>
         <InputTextLabel name="code" placeholder="activationCodeView.placeholderCode" value={this.state.code} callBackOnChange={this.handleChange.bind(this)}/>
         <FintupButton className="text-center" size={constants.BUTTON_SIZE.XLARGE} textKey="activationCodeView.validateButton" action={this.doValidate.bind(this)}/>
       </div>
     )
    }
  }

  get componentClasses () {
    return classNames('registerCode', this.props.className)
  }

  render () {
    return (
      <div className={this.componentClasses}>
          <SingleFormContainer
            titleKey="activationView.title"
            legendKey={this.legendKey}
            alertText={this.state.alert.text}
            alertType={this.state.alert.type}
            className={this.props.classNameContainer}>
            {this.mainView}
          </SingleFormContainer>
      </div>
    )
  }
}

export default injectIntl(RegisterCode)