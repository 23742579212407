import React from 'react'
import FintupComponent from '../FintupComponent'
import { doLogoutPost } from '../../utils/fintup-api'
import SessionController from '../../core/SessionMobx'
import urls from '../../utils/urls'
import utils from '../../utils/utils'
import navUtils from '../../core/navUtils'
import { FormattedMessage } from 'react-intl'
import { observer } from 'mobx-react'
import './NavPrivate.scss'
import FintupIcon from '../common/FintupIcon'
import classNames from 'classnames'
import NavPrivateModel from './NavPrivateModel'
import config, { getBooleanConfig } from '../../config/config'

class NavPrivate extends FintupComponent {
  doLogout() {
    let isEmulated = SessionController.isEmulatedSession
    navUtils.closeMenu()
    doLogoutPost()
      .finally(() => {
        SessionController.exitSession()
        navUtils.logoutRedirect(isEmulated)
      })
  }

  renderCenterControls(additionalClasses) {
    if (getBooleanConfig('hasSuperiorMainBar', true)) {
      let myInvestClasses = classNames('centralContent', { active: NavPrivateModel.myInvest.active })
      let myExpertClasses = classNames('centralContent', { active: NavPrivateModel.myExpert.active })
      let containerClasses = classNames('centralContainer', additionalClasses)
      return (
        <div className={containerClasses}>
          {/*<div className={myInvestClasses}><span onClick={() => NavPrivateModel.changeMenu('myInvest')} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="NavPrivate.invest"/></span></div>
          <div className={myExpertClasses}><span onClick={() => NavPrivateModel.changeMenu('myExpert')} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="NavPrivate.expert"/></span></div>*/}
        </div>
      )
    } else {
      return ''
    }
  }

  renderInvitationControl() {
    if (getBooleanConfig('hasInvitationBar', true)) {
      let inviteContainerClasses = classNames('fRight inviteContainer', { active: NavPrivateModel.invite.active })
      return (
        <div className={inviteContainerClasses}>
          <span onClick={() => NavPrivateModel.changeMenu('invite')} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="NavPrivate.invite" /></span>
        </div>
      )
    } else {
      return ''
    }
  }

  render() {
    return (
      <div className="navPrivate">
        <div className="navPrivateDesktop">
          <div className="fLeft">
            <img className="default-logo hidden-xs" src={config.privUrlLogo || urls.image.logoV2Mobile} alt="Fintup" role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} />
            <img className="default-logo show-xs" src={config.mobileUrlLogoSmall || urls.image.logoV2MobileSmall} alt="Fintup" role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} />
          </div>
          <div className="fRight logout" onClick={this.doLogout.bind(this)} role="presentation" onKeyPress={utils.noop}>
            <img src="/img/exit.svg" alt="exit" />
          </div>
          <div className="fRight profileContainer">
            <img src="/img/menubar-profile-icon.svg" alt="profile" onClick={() => NavPrivateModel.changeMenu('profile')} role="presentation" onKeyPress={utils.noop} />
          </div>
          <div className="fRight sep">
            <img src="/img/fintup-vertical-line.svg" alt="sep" />
          </div>
          {this.renderInvitationControl()}
          {this.renderCenterControls('hidden-xs')}
          <div className="clearAll" />
        </div>
        <div className="navPrivateMobile">
          {this.renderCenterControls('show-xs')}
          <div className="clearAll" />
        </div>
        <div className="clearAll" />
      </div>
    )
  }
}

export default observer(NavPrivate)
