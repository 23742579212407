import FintupComponent from "../FintupComponent";
import { contratableInvestProduct, getPortfolioData, getPortfoliosContratablesDataByProduct, getPortfoliosRecommendablesDataByProduct, getContractPersonalData, doGetDocumentTypes, doGetCountryList, doGetProvinceList, doGetMaritalTypeList, getContractFinancialData } from "../../utils/fintup-api";
import transverser from "../../utils/transverser";
import SessionController from "../../core/SessionMobx";
import React from 'react'
import FintupIcon from "../common/FintupIcon";
import { FormattedMessage, injectIntl } from "react-intl";
import ContractTabs from "./ContractTabs";
import TitleLabel from "../common/forms/TitleLabel";
import SliderText from "../common/forms/SliderText";
import utils from "../../utils/utils";
import InputTextLabel from "../common/forms/InputTextLabel";
import RadioGroup from "../common/forms/RadioGroup";
import RadioButton from "../common/forms/RadioButton";
import constants from "../../utils/constants";
import SelectLabel from "../common/forms/SelectLabel";
import DatePickerLabel from "../common/forms/DatePickerLabel";
import DynamicFormGroup from "../common/forms/DynamicFormGroup";
import _ from 'lodash'
import FintupButton from "../common/forms/FintupButton";
import RSVP from "rsvp";
import uniqid from 'uniqid'
import './ContractStep0.scss';
import ContractExpertHelp from "./ContractExpertHelp";
import FintupFabExpert from "../common/forms/FintupFabExpert";


class ContractStep0 extends FintupComponent {

  constructor(props) {
    super(props)
    this.state = {
      actualStep: 1,
      //Get data from contractgeneralview model
      portfolioId: transverser.get('model.test.contractData.portfolioId', '')(this.props),
      email: SessionController.email,
      amountInvest: transverser.get('model.test.responsesData.step2.amount', '')(this.props),
      minimumContribution: transverser.get('model.investProductData.minimumContribution', '')(this.props),
      minimumContributionWithPeriodicContribution: transverser.get('model.investProductData.minimumContributionWithPeriodicContribution', '')(this.props),
      allowZeroInitialContribution: transverser.get('model.investProductData.allowZeroInitialContribution', false)(this.props),
      minimumPeriodicContribution: transverser.get('model.investProductData.minimumPeriodicContribution', '')(this.props),

      //Default data
      radioButtonPeriodicContribution: (transverser.get('model.test.responsesData.step2.amountMonthly', "2")(this.props)) != 0 ? "1" : "2",
      periodicType: (transverser.get('model.test.responsesData.step2.amountMonthly', '')(this.props)) != 0 ? 1 : '',
      periodicAmount: transverser.get('model.test.responsesData.step2.amountMonthly', '')(this.props),
      beneficiariesOption1: constants.CONTRACT.ACCOUNT.HEIRS.HEIRS,
      beneficiariesOption2: constants.CONTRACT.ACCOUNT.HEIRS.DESIGNATION,

      //Error fields text
      portfolioIdErrorText: '',
      amountInvestErrorText: '',
      periodicTypeErrorText: '',
      accountErrorText: '',
      periodicAmountErrorText: ''
    }
  }

  hideErrorsText() {
    return this.setStatePromise({
      portfolioIdErrorText: '',
      amountInvestErrorText: '',
      periodicTypeErrorText: '',
      accountErrorText: '',
      periodicAmountErrorText: ''
    })
  }

  componentDidMount() {
    //Check if exists values fillables
    let state = transverser.get('model.step0')(this.props)
    let existsStep = transverser.get('model.step0', false)(this.props) ? true : false

    if (!existsStep) {
      getContractFinancialData()
        .then(response => {
          let contractAccount = response.data || {}
          if (contractAccount.iban) {
            contractAccount.account = contractAccount.iban
            delete contractAccount.iban
          }
          let stateModel = {
            account: transverser.get('account', '')(contractAccount),
            beneficiariesType: transverser.get('beneficiariesType', constants.CONTRACT.ACCOUNT.HEIRS.HEIRS)(contractAccount),
            beneficiariesList: transverser.get('beneficiariesList', [])(contractAccount).map(element => {
              return {
                dni: element.id.dni,
                name: element.name,
                percentage: element.percentage
              }
            }),
          }

          return this.setStatePromise(stateModel)
        })
    } else {
      this.setStatePromise(state)
    }
  }

  /**
   * Reactive values formGroup
   * @param {*} values 
   */
  handleChangeFormGroup(values) {
    this.setStatePromise({ beneficiariesList: values })
  }

  /**
   * Remove deleted fromGroup's properties
   * Format: formGroupName-propertyName
   * @param {*} id 
   */
  handleRemoveFormGroup(id) {
    let stateArray = Object.keys(this.state)
    stateArray.forEach(elem => {
      if (elem.startsWith(id)) {
        this.setStatePromise({ [elem]: undefined })
      }
    })
  }

  changeBeneficiaryValue(data) {
    let beneficiariesObject = {
      beneficiariesList: this.state.beneficiariesList
    }
    transverser.set(data.name, data.value)(beneficiariesObject)
    this.setStatePromise({ beneficiariesList: beneficiariesObject.beneficiariesList })
  }

  /**
   * Validation fields and check
   * if we can go to the next step
   */
  next() {
    /**/
    let portfolioId = this._getState('portfolioId', '')
    let amountInvest = this._getState('amountInvest', '')
    let radioButtonPeriodicContribution = this._getState('radioButtonPeriodicContribution', '')
    let periodicType = this._getState('periodicType', '')
    let periodicAmount = parseInt(this._getState('periodicAmount', 0), 10)
    let minimumContribution = this.state.minimumContribution
    let minimumContributionWithPeriodicContribution = this.state.minimumContributionWithPeriodicContribution
    let minimumPeriodicContribution = this.state.minimumPeriodicContribution
    let allowZeroInitialContribution = this.state.allowZeroInitialContribution
    let account = this._getState('account', '')
    let beneficiariesType = this._getState('beneficiariesType', '')
    let beneficiariesList = this.normalizeBeneficiaries(this._getState('beneficiariesList', []))

    let isError = false;
    let isErrorInvest = false;
    let isErrorInvestPeriodic = false;

    //1 - portfolio
    this.setStatePromise({ portfolioIdErrorText: '' })
    if (portfolioId === '') {
      this.setStatePromise({ portfolioIdErrorText: 'contractStep.validation.portfolio' })
      isError = true
      utils.scrollToTop()
    }

    //2 - amountInvest
    this.setStatePromise({ amountInvestErrorText: '' })
    if ((amountInvest === '' || amountInvest == 0) && !allowZeroInitialContribution) {
      this.setStatePromise({ amountInvestErrorText: 'miniTestStep3.textError3' })
      isError = true
      isErrorInvest = true
      utils.scrollToTop()
    }

    //Only check if not exists previous error in same field
    if (!isErrorInvest) {
      this.setStatePromise({ amountInvestErrorText: '' })
      //Es distinto 0 y no llega al mínimo (y la aportación periódica no llega al mínimo)
      //No tiene aportaciones periódicas, la cantidad inicial solo debe cumplir el mínimo inicial
      if ((!utils.validatePositiveAmount(amountInvest, { min: minimumContribution })
        && radioButtonPeriodicContribution == 2)
        ||
        //Tiene aportaciones periódicas 
        //y la cantidad inicial debe cumplir que si tiene cantidad sea superior al mínimo con aportaciones periódicas
        ((amountInvest !== '' && amountInvest != 0 && !utils.validatePositiveAmount(amountInvest, { min: minimumContributionWithPeriodicContribution }))
          && radioButtonPeriodicContribution == 1)) {
        //error no llega al mínimo
        this.setStatePromise({ amountInvestErrorText: 'contractStep.validation.amountMinimum' })
        isError = true
        isErrorInvest = true
        utils.scrollToTop()
      }
    }

    //3 - radioButtonPeriodicContribution
    //Yes periodic contribution
    if (radioButtonPeriodicContribution == 1) {

      //3a - periodicType
      this.setStatePromise({ periodicTypeErrorText: '' })
      if (periodicType === '') {
        this.setStatePromise({ periodicTypeErrorText: 'requestDataPensionPlan.error.periodicType' })
        isError = true
        utils.scrollToTop()
      }

      //3b - periodicAmount
      let annualMinimumPerodicContribution = minimumPeriodicContribution * 12
      let annualPeriodicContribution = 12 / parseInt(periodicType, 10) * periodicAmount

      //Se permite 0, Es 0 y periódica no llega al minimo
      this.setStatePromise({ periodicAmountErrorText: '' })
      if (allowZeroInitialContribution && amountInvest == 0 && !utils.validatePositiveAmount(periodicAmount, { min: minimumPeriodicContribution })) {
        //error, hay que tener aportaciones periódicas si la inversión inicial es 0
        this.setStatePromise({ periodicAmountErrorText: 'contractStep.validation.periodicMinimum' })
        isError = true
        isErrorInvestPeriodic = true
        utils.scrollToTop()
      }

      //Only check if not exists previous error in same field
      if (!isErrorInvestPeriodic) {
        this.setStatePromise({ periodicAmountErrorText: '' })
        //Es distinto 0 y no llega al mínimo (y la aportación periódica no llega al mínimo)
        if (!utils.validatePositiveAmount(amountInvest, { min: minimumContribution }) &&
          !utils.validatePositiveAmount(periodicAmount, { min: minimumPeriodicContribution })) {
          //error no llega al mínimo
          this.setStatePromise({ periodicAmountErrorText: 'contractStep.validation.periodicMinimum' })
          isError = true
          isErrorInvestPeriodic = true
          utils.scrollToTop()
        }
      }

      //Only check if not exists previous error in same field
      if (!isErrorInvestPeriodic) {
        this.setStatePromise({ periodicAmountErrorText: '' })
        //Check trimester, semester, anualy
        if ((annualPeriodicContribution < annualMinimumPerodicContribution)) {
          //error no llega al mínimo
          this.setStatePromise({ periodicAmountErrorText: 'contractStep.validation.periodicMinimum' })
          isError = true
          isErrorInvestPeriodic = true
          utils.scrollToTop()
        }
      }
    }

    //4 - IBAN account
    this.setStatePromise({ accountErrorText: '' })
    if (!utils.isIban(account)) {
      this.setStatePromise({ accountErrorText: 'contractAccount.validation.account' })
      isError = true
      utils.scrollToTop()
    }

    //5 - beneficiariesType (radio button)
    if (radioButtonPeriodicContribution != null) {
      //5a - beneficiariesList

      if (beneficiariesType === this.state.beneficiariesOption2 && !this.validateBeneficiaries(beneficiariesList)) {
        this.handleNotification({ titleKey: 'regsterView.validation.error', textKey: 'contractAccount.beneficiaries.validation', type: constants.NOTIFICATION_TYPE.ERROR })
        isError = true
      }

      if (beneficiariesType === this.state.beneficiariesOption2 && !this.validateBeneficiariesPercentage(beneficiariesList)) {
        this.handleNotification({ titleKey: 'regsterView.validation.error', textKey: 'contractAccount.beneficiaries.percentage.validation', type: constants.NOTIFICATION_TYPE.ERROR })
        isError = true
      }
    }

    if (isError) {
      return
    }

    this.hideErrorsText().then(() => {
      this.props.updateModel({
        ...this,
        step0: this.state,
      })
    })

    this.props.nextStep()

  }

  validateBeneficiaries(beneficiariesList) {
    let validBeneficiaries = beneficiariesList.reduce((result, beneficiary) => {
      let dni = beneficiary.dni
      return result && (utils.isNif(dni) || utils.isNie(dni) || utils.isCif(dni))
    }, true)
    return validBeneficiaries
  }

  validateBeneficiariesPercentage(beneficiariesList) {
    let total = beneficiariesList.reduce((accumulate, beneficiaryData) => {
      let beneficiaryPercentage = parseInt(beneficiaryData.percentage || 0, 10)
      return accumulate + beneficiaryPercentage
    }, 0)
    return total === 100
  }

  get renderPeriodicContribution() {
    return this.state.radioButtonPeriodicContribution == 1 ?
      (
        <div>
          <div className="row">
            <TitleLabel
              title="3a"
              descriptionKey="contractStep.title4"
              theme="new"></TitleLabel>
            <SelectLabel theme="new"
              selectedValue={this.state.periodicType}
              name="periodicType"
              isError={this.state.periodicTypeErrorText != ''}
              errorTextKey={this.state.periodicTypeErrorText}
              placeholder="requestDataPensionPlan.periodicType" options={transverser.get('listOptions.periodicTypeOptions', '')(this.props.model)}
              callBackOnChange={this.commonHandleChange.bind(this)} />
          </div>
          <div className="row">
            <TitleLabel
              title="3b"
              descriptionKey="contractStep.title5"
              theme="new"></TitleLabel>
            <InputTextLabel type="number"
              name="periodicAmount"
              maxLength="9"
              placeholder="contractStep.text1" theme="new"
              legendTextKey={(this.props.intl.formatMessage({ id: "contractStep.legend.periodicAmountInvestMinimum" }, { minimum: this.state.minimumPeriodicContribution }))}
              leftLegend={true}
              isError={this.state.periodicAmountErrorText != ''}
              errorTextKey={this.state.periodicAmountErrorText}
              value={this.state.periodicAmount}
              callBackOnChange={this.commonHandleChange.bind(this)} />
          </div>
        </div>
      ) : ''
  }

  addBeneficiaryRow() {
    let beneficiariesList = this.state.beneficiariesList
    beneficiariesList.push({
      dni: '',
      name: '',
      percentage: 100 - this.totalPercentage,
      key: uniqid()
    })
    return this.setStatePromise({ beneficiariesList: beneficiariesList })
  }

  getBeneficiaryRemoveControl(beneficiariesList, currentBeneficiaryIndex) {
    return beneficiariesList.length == 1
      //&& currentBeneficiaryIndex + 1 !== constants.CONTRACT.ACCOUNT.BENEFICIARIES.MAX_BENEFICIARIES
      ?
      ''
      : <FintupButton
        iconImage="/img/trash.svg"
        className="btn-trash"
        size={constants.BUTTON_SIZE.ONLY_ICON}
        action={() => this.removeBeneficiaryRow(currentBeneficiaryIndex)} />
  }

  normalizeBeneficiaries(beneficiariesStateList) {
    return beneficiariesStateList.filter(beneficiary => {
      let emptyDni = beneficiary.dni.trim() === ''
      let emptyName = beneficiary.name.trim() === ''
      let percentage = `${beneficiary.percentage}`.trim()
      let emptyPercentage = percentage === '' || percentage === '0'
      return !emptyDni && !emptyName && !emptyPercentage
    }).map(beneficiary => {
      return {
        dni: beneficiary.dni,
        name: beneficiary.name,
        percentage: beneficiary.percentage
      }
    })
  }

  removeBeneficiaryRow(index) {
    let beneficiariesList = this.state.beneficiariesList
    beneficiariesList = utils.removeArrayIndex(beneficiariesList, index)
    return this.setStatePromise({ beneficiariesList: beneficiariesList })
  }

  get beneficiariesRowDataRender() {
    return transverser.get('beneficiariesList', [])(this.state).map((beneficiary, index) => {
      let beneficaryDni = `beneficiariesList[${index}].dni`
      let beneficaryName = `beneficiariesList[${index}].name`
      let beneficaryPercentage = `beneficiariesList[${index}].percentage`
      return (
        <div className="beneficiarie" key={`beneficiary_${index}`}>
          {this.getBeneficiaryRemoveControl(this.state.beneficiariesList, index)}
          <InputTextLabel
            type="text"
            name={beneficaryDni}
            readOnly={this.state.readOnly}
            placeholder="contractBeneficiary.text4"
            theme="new"
            value={this.state.beneficiariesList[index].dni}
            leftLegend={true}
            legendTextKey="contractStep.text.beneficiaries"
            callBackOnChange={this.changeBeneficiaryValue.bind(this)} />

          <InputTextLabel
            type="text"
            placeholder="contractBeneficiary.text3"
            theme="new"
            readOnly={this.state.readOnly}
            name={beneficaryName}
            value={this.state.beneficiariesList[index].name}
            callBackOnChange={this.changeBeneficiaryValue.bind(this)} />

          <InputTextLabel
            type="number"
            placeholder="contractBeneficiary.text5"
            theme="new"
            readOnly={this.state.readOnly}
            type="number" name={beneficaryPercentage}
            value={this.state.beneficiariesList[index].percentage}
            callBackOnChange={this.changeBeneficiaryValue.bind(this)} />
        </div>
      )
    })
  }

  get renderBeneficiaries() {
    return this.state.beneficiariesType == this.state.beneficiariesOption2 ?
      (
        <div className="row">
          <TitleLabel
            title="5a"
            descriptionKey="contractStep.title8"
            theme="new"></TitleLabel>
          {this.beneficiariesRowDataRender}
          {
            (this.state.beneficiariesList
              && this.state.beneficiariesList.length < constants.CONTRACT.ACCOUNT.BENEFICIARIES.MAX_BENEFICIARIES) ?
              <FintupButton fintupIcon="plus"
                textKey={this.props.textKeyButton ? this.props.textKeyButton : 'add'}
                size={constants.BUTTON_SIZE.XLARGE}
                action={this.addBeneficiaryRow.bind(this)} />
              : ''
          }

        </div>
      ) : ''
  }

  render() {
    return (
      <div className="contractStep0">
        <div className="content text-center">
          <ContractTabs values={this} actualStep={this.state.actualStep} blockNextItems={true} titleKey="contractGeneralView.title1" elementActive="1"></ContractTabs>
          <div className="container contentData">
            <div className="row">
              <TitleLabel
                title="1"
                descriptionKey="contractStep.title1"
                theme="new"></TitleLabel>
              <SelectLabel theme="new"
                selectedValue={this.state.portfolioId}
                name="portfolioId"
                isError={this.state.portfolioIdErrorText != ''}
                errorTextKey={this.state.portfolioIdErrorText}
                placeholder="contractStep.text3" options={transverser.get('listOptions.contratablePortfoliosOptions', '')(this.props.model)}
                callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel
                title="2"
                descriptionKey="contractStep.title2"
                theme="new"></TitleLabel>
              <InputTextLabel type="number"
                name="amountInvest"
                maxLength="9"
                legendTextKey={(this.props.intl.formatMessage({ id: "contractStep.legend.amountInvestMinimum" }, { minimum: this.state.minimumContribution, minimum2: this.state.minimumContributionWithPeriodicContribution }))}
                leftLegend={true}
                placeholder="compoundInterestView.initialAmount.placeholder" theme="new"
                isError={this.state.amountInvestErrorText != ''}
                errorTextKey={this.state.amountInvestErrorText}
                value={this.state.amountInvest}
                callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel
                title="3"
                descriptionKey="contractStep.title3"
                theme="new"></TitleLabel>
              <RadioGroup theme="new" >
                <RadioButton radioId="radioSi"
                  name="radioButtonPeriodicContribution"
                  textKey="YES"
                  value="1"
                  selectedOption={this.state.radioButtonPeriodicContribution}
                  onChangeRadio={this.commonHandleChange.bind(this)} />
                <RadioButton radioId="radioNo"
                  name="radioButtonPeriodicContribution"
                  textKey="NO.lower"
                  value="2"
                  selectedOption={this.state.radioButtonPeriodicContribution}
                  onChangeRadio={this.commonHandleChange.bind(this)} />
              </RadioGroup>
            </div>
            {this.renderPeriodicContribution}
            <div className="row">
              <TitleLabel
                title="4"
                descriptionKey="contractStep.title6"
                theme="new"></TitleLabel>
              <InputTextLabel
                theme="new"
                placeholder="contractStep.text2"
                legendTextKey="contractRegularContribution.iban.legend"
                maxLength="24"
                leftLegend={true}
                isError={this.state.accountErrorText != ''}
                errorTextKey={this.state.accountErrorText}
                name="account"
                value={this.state.account}
                pasteTransform={utils.removeBlanks} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel
                title="5"
                descriptionKey="contractStep.title7"
                theme="new"
              >
              </TitleLabel>
              <RadioGroup theme="new">
                <RadioButton radioId="radioSi1"
                  name="beneficiariesType"
                  textKey="contractBeneficiary.text1"
                  value={this.state.beneficiariesOption1}
                  selectedOption={this.state.beneficiariesType}
                  onChangeRadio={this.commonHandleChange.bind(this)} />
                <RadioButton radioId="radioNo1"
                  name="beneficiariesType"
                  textKey="contractBeneficiary.text2"
                  value={this.state.beneficiariesOption2}
                  selectedOption={this.state.beneficiariesType}
                  onChangeRadio={this.commonHandleChange.bind(this)} />
              </RadioGroup>
            </div>
            {this.renderBeneficiaries}
          </div>

          <FintupButton
            fintupIcon="flecha-seleccionable-derecha"
            iconRight={true} textKey="button.common.next"
            className="buttonContainer buttonAttention"
            buttonClassName="btn-bold"
            size={constants.BUTTON_SIZE.XLARGE}
            action={() => this.next()} />
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        {/*<ContractExpertHelp></ContractExpertHelp>*/}
        <FintupFabExpert />
      </div>
    )
  }
}

export default injectIntl(ContractStep0)
