import React from 'react'
import MultipleFormContainer from '../common/MultipleFormContainer'
//import ContractPersonalData from './ContractPersonalData'
//import ContractBleach from './ContractBleach'
//import ContractAccount from './ContractAccount'
import { profileGet, contratableInvestProduct, doGetCountryList, doGetProvinceList, doGetMaritalTypeList, getPortfoliosContratablesDataByProduct, doGetDocumentTypes, doGetInvestOriginList, doGetInvestTargetList, getContractInvestData } from '../../utils/fintup-api'
import SuitabilitySteps from '../suitability/SuitabilitySteps'
import _ from 'lodash'
import './ContractGeneralView.scss'
import AdviserModel from '../common/AdviserModel'
import FintupPrivateView from '../FintupPrivateView'
import transverser, { tGet } from '../../utils/transverser'
import classNames from 'classnames'
//import ContractRegularContribution from './ContractRegularContribution'
//import ContractUserDocument from './ContractUserDocument'
import urls from '../../utils/urls'
import HeaderTags from '../common/HeaderTags'
import ContractStep0 from './ContractStep0'
import ContractStep1 from './ContractStep1'
import ContractStep2 from './ContractStep2'
import ContractStep3 from './ContractStep3'
import ContractStep5 from './ContractStep5'
import ContractStep4 from './ContractStep4'
import RSVP from "rsvp";
import { injectIntl } from 'react-intl'
import constants from '../../utils/constants'

class ContractGeneralView extends FintupPrivateView {
  constructor (props) {
    super(props)
    let productToContract = tGet('routeParams.product')(this.props)
    this.state = {
      actualStep: 1,
      generalStep: 8,
      renderContent: false,
      initialModel: {
        productToContract: productToContract,
        hasPeriodicBlock: productToContract === 'inversion'
      }
    }
  }

  componentDidMount () {
    profileGet()
      .then(response => {
        let initialModel = this.state.initialModel
        let newInitialModel = _.merge(response.data, initialModel)
        return this.setStatePromise({initialModel: newInitialModel})
      })
      .then(() => AdviserModel.loadAdviser())
      .then(contratableInvestProduct)
      .then(response => {
        let initialModel = _.merge(this.state.initialModel, {investProductData: response.data})
        return this.setStatePromise({initialModel: initialModel})
      })
      //Check Contract data -> if client has made suitability test or minitest
      .then(getContractInvestData)
      .then(response => {
        let initialModel = _.merge(this.state.initialModel, {test: response.data})
        return this.setStatePromise({initialModel: initialModel})
       /* return this.setStatePromise({
          initialModel: {
            stepContract: response.data.contractData,
            ...response.data.responsesData,
            externalId: { externalId: response.data.externalId }
          }
        })*/
      })
      .then(() => { //Get list options
        let optionsPromise = RSVP.hash({
          documentTypes: doGetDocumentTypes(),
          countryList: doGetCountryList(),
          provinceList: doGetProvinceList(),
          maritalTypeList: doGetMaritalTypeList(),
          contratablePortfolios: getPortfoliosContratablesDataByProduct(transverser.get('initialModel.investProductData.id', '')(this.state)),
          originList: doGetInvestOriginList(),
          targetList: doGetInvestTargetList()
        })
        return optionsPromise
      })
      .then((responses) => { //Response list options
        let documentTypes = responses.documentTypes.data.documentTypes
        let countryList = responses.countryList.data.countryList
        let provinceList = responses.provinceList.data.provincesList
        let maritalTypeList = responses.maritalTypeList.data.maritalTypes
        let contratablePortfolios = responses.contratablePortfolios.data
        let originList = responses.originList.data.investOriginList
        let targetList = responses.targetList.data.investTargetList
        let documentTypesOptions = documentTypes.map((documentType) => {
          return {
            labelText: documentType.text,
            value: documentType.value
          }
        })
        let countryListOptions = countryList.map((country) => {
          return {
            labelText: country,
            value: country
          }
        })
        let provinceListOptions = provinceList.map((province) => {
          return {
            labelText: province,
            value: province
          }
        })
        let maritalTypeOptions = maritalTypeList.map((maritalType) => {
          return {
            labelText: maritalType.text,
            value: maritalType.value
          }
        })
  
        let sortArray = _.sortBy(contratablePortfolios, (item) => item.id)
        let contratablePortfoliosOptions = sortArray.map(item => {
          return {
            label: item.name+" - "+this.props.intl.formatMessage({id: `summaryProduct.risk.risk${item.riskTypeId}_5`}),
            value: item.id
          }
        })

        let originListOptions = originList.map((origin) => {
          return {
            text: origin.text,
            value: origin.value,
            backValue: origin.backValue
          }
        })
        let targetListOptions = targetList.map((target) => {
          return {
            text: target.text,
            value: target.value,
            backValue: target.backValue
          }
        })

        let initialModel = _.merge(this.state.initialModel, {
          portfolios: contratablePortfolios,
          listOptions: {
            docOptions: documentTypesOptions,
            countryOptions: countryListOptions,
            provinceOptions: provinceListOptions,
            maritalTypeOptions: maritalTypeOptions,
            periodicTypeOptions: constants.SELECT_OPTIONS.PERIODIC_TYPE,
            contratablePortfoliosOptions: contratablePortfoliosOptions,
            investOriginOptions: originListOptions,
            investTargetOptions: targetListOptions
          }
        })
        return this.setStatePromise({initialModel: initialModel})
      })
      .then(() => this.setStatePromise({renderContent: true}))
      .catch(error => console.error(error))
  }

  setGeneralStep (generalStepIndex) {
    return this.setStatePromise({ generalStep: generalStepIndex })
  }

  get stepsMarker () {
    let productParameter = tGet('props.routeParams.product', '')(this)
    switch (productParameter) {
      case 'plan-pensiones':
      case 'ahorro':
        return undefined
      default:
        return <SuitabilitySteps activeStep={this.state.generalStep}/>
    }
  }

  contractPersonalDataBackUrl () {
    let productParameter = tGet('props.routeParams.product', '')(this)
    switch (productParameter) {
      case 'plan-pensiones':
      case 'ahorro':
        return urls.internal.contract
      default:
        return undefined
    }
  }

  render () {
    if (this.state.renderContent) {
      return this.renderForm()
    } else {
      return ''
    }
  }

  renderForm () {
    let componentClasses = classNames(
      'contractGeneralView',
      {'withoutStepMarker': _.isUndefined(this.stepsMarker)}
    )
    let stepList = [
      {
        stepView: <ContractStep0 backUrl={this.contractPersonalDataBackUrl()} name="ContractStep0" />,
        generalStep: 8
      },
      {
        stepView: <ContractStep1 backUrl={this.contractPersonalDataBackUrl()} name="ContractStep1" />,
        generalStep: 9
      },
      {
        stepView: <ContractStep2 name="ContractStep2" />,
        generalStep: 10
      },
      {
        stepView: <ContractStep3 name="ContractStep3" />,
        generalStep: 11
      },
      {
        stepView: <ContractStep4 name="ContractStep4" />,
        generalStep: 12
      },
      {
        stepView: <ContractStep5 name="ContractStep5" />,
        generalStep: 13
      },
      /*{
        stepView: <ContractBleach name="ContractBleach" />,
        generalStep: 4
      }*/
    ]

    /*if (this.state.initialModel.investProductData.id === 20674 || !this.state.initialModel.hasPeriodicBlock) {
      stepList.push({
        stepView: <ContractAccount name="ContractAccount" />,
        generalStep: 4
      })
    } else {
      stepList.push({
        stepView: <ContractRegularContribution name="ContractRegularContribution" />,
        generalStep: 4
      })
    }*/

    return (
      <div className={componentClasses}>
        <HeaderTags noIndex={true} title="headerTags.contractGeneralData.title" description="headerTags.globalposition.description"></HeaderTags>
        {<MultipleFormContainer
          alertScroll={true}
          initialModel={this.state.initialModel}
          //stepsMarker={this.stepsMarker}
          //updateStepMarker={this.setGeneralStep.bind(this)}
          name={this.getPath()}
          actualStep={this.state.actualStep}
          steps={stepList}
          ref={ref => (this.multipleForm = ref)} />}
      </div>
    )
  }
}

export default injectIntl(ContractGeneralView)
