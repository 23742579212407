import React from 'react'
import FintupComponent from '../../components/FintupComponent'
import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'
import classNames from 'classnames'
import './SuitabilitySteps.css'

class SuitabilitySteps extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      stepsKey: uniqid()
    }
  }

  getLiteral (key) {
    return this.props.intl.formatMessage({ id: key })
  }

  get activeStep () {
    let activeStep = this.props.activeStep - 1
    return activeStep
  }

  get stepData () {
    let stepsData = [
      {
        inactiveText: '1',
        activeText: this.getLiteral('suitabilitySteps.init')
      },
      {
        inactiveText: '2',
        activeText: this.getLiteral('suitabilitySteps.suitability')
      },
      {
        inactiveText: '3',
        activeText: this.getLiteral('suitabilitySteps.selectPlan')
      },
      {
        inactiveText: '4',
        activeText: this.getLiteral('suitabilitySteps.contratacion')
      }
    ]
    return stepsData.map((step, index) => {
      let isActiveStep = this.activeStep === index
      let stepText = isActiveStep ? step.activeText : step.inactiveText
      let isPreviousStep = index < this.activeStep
      let classes = classNames('suitabilityStep', isActiveStep ? 'active' : 'notActive', isPreviousStep ? 'previousStep' : '')
      return (
        <div className={classes} key={index}>
          {stepText}
        </div>)
    })
  }

  get componentClasses () {
    return classNames('suitabilitySteps', this.props.stepsComplete ? 'end' : '')
  }

  render () {
    return (
      <div className={this.componentClasses}>
        {this.stepData}
        <div className="clearAll"/>
      </div>
    )
  }
}

export default injectIntl(SuitabilitySteps)
