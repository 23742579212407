import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import './RadioButton.scss'

/*
  Props:
    disabled: Deshabilita el componente
  theme: 'new'
    * 'new': Nuevo estilo de componentes similar al diseño minitest

*/
class RadioButton extends Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
  }

  handleChange (ev) {
    let data = {
      ev: ev,
      name: this.props.name,
      value: ev.target.value,
      extraData: this.props.extraData
    }
    this.props.onChangeRadio(data)
  }

  get horizontarClasses () {
    return this.props.horizontal ? 'fLeft marginRight20' : ''
  }

  get labelText () {
    let text = this.props.textKey ? this.props.intl.formatMessage({ id: this.props.textKey }) : this.props.text
    return text
  }

  render () {
    let isChecked = this.props.selectedOption === this.props.value
    let elementClasses = classNames('radioButton', {[`theme-${this.props.theme}`]: this.props.theme}, this.horizontarClasses)
    return (
      <p className={elementClasses}>
        <input disabled={this.props.disabled} type="radio" id={this.props.radioId} name={this.props.name} value={this.props.value} checked={isChecked} onChange={this.handleChange.bind(this)} />
        <label htmlFor={this.props.radioId}>{this.labelText}</label>
      </p>
    )
  }
}

export default injectIntl(RadioButton)
