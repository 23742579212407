import React from 'react'
import './MiniTestStep4.scss'
import FintupComponent from '../FintupComponent'
import { FormattedMessage } from 'react-intl'
import constans from '../../utils/constants'
import FintupSlider from '../common/forms/FintupSlider'
import FintupButton from '../common/forms/FintupButton'
import MiniTestTabs from './MiniTestTabs'
import transverser from '../../utils/transverser'

class MiniTestStep4 extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("PROPS MINITESTSTEP4", props)

    this.state = {
      actualStep: 4,
      minSlide: 1,
      maxSlide: 5,
      valueSlide: transverser.get('model.typeInvestor', 3)(this.props), //initial or props value
      valueIconSlide: transverser.get('model.typeInvestorIcon', "/img/minitest/investor3.svg")(this.props), //initial or props value
      valueTextSlide: transverser.get('model.typeInvestorText', "miniTestStep4.value3")(this.props), //initial or props value
    }
  }

  /**
   * Validation fields and check
   * if we can go to the next step
   */
  next(){
    this.props.updateModel({
      ...this,
      typeInvestor: this.state.valueSlide,
      typeInvestorIcon: this.state.valueIconSlide,
      typeInvestorText: this.state.valueTextSlide
    })
    this.props.nextStep()
  }

  changeValueSlider(data){
    
    let newValueSlider = data.ev.target.value
    let newValueIconSlider = this.state.valueIconSlide
    let newValueTextSlider = this.state.valueTextSlide

    switch(newValueSlider){
      case 1:
      default: 
        newValueIconSlider = "/img/minitest/investor1.svg"
        break;
      case 2: 
        newValueIconSlider = "/img/minitest/investor2.svg"
        break;
      case 3: 
        newValueIconSlider = "/img/minitest/investor3.svg"
        break;
      case 4: 
        newValueIconSlider = "/img/minitest/investor4.svg"
        break;
      case 5: 
        newValueIconSlider = "/img/minitest/investor5.svg"
        break;
    }
    newValueTextSlider = `miniTestStep4.value${newValueSlider}`
    this.setStatePromise({valueSlide: newValueSlider})
    .then(() => this.setStatePromise({valueIconSlide: newValueIconSlider}))
    .then(() => this.setStatePromise({valueTextSlide: newValueTextSlider}))
  }

  render () {
    return (
      <div className="miniTestStep4">
        <div className="content text-center">
          <MiniTestTabs values={this} actualStep={this.state.actualStep}></MiniTestTabs>
          <div className="row">
            <h3><FormattedMessage id="miniTestStep4.title" /></h3>
            <span className="subtitle-text"><FormattedMessage id="miniTestStep4.text1" /></span>
          </div>
          <div className="row">
            <div className="investStyle m-auto">
              <img src={this.state.valueIconSlide} alt={(<FormattedMessage id={this.state.valueTextSlide}/>)}></img>
              <p><FormattedMessage id={this.state.valueTextSlide}/></p>
            </div>
          </div>
          <div className="row slider m-auto">
            <FintupSlider showTooltip={false} min={this.state.minSlide} max={this.state.maxSlide} start={this.state.valueSlide} callBackOnChange={this.changeValueSlider.bind(this)}/>
            <div className="labels">
              <span><FormattedMessage id="miniTestStep4.text2"/></span>
              <span><FormattedMessage id="miniTestStep4.text3"/></span>
            </div>
          </div>
        </div>
        <div className="miniTestStepButtonContainer">
          <FintupButton fintupIcon="flecha-seleccionable-izquierda" iconRight={true} textKey="button.common.next" className="buttonContainer buttonPrevMiniTest" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.ONLY_ICON} action={() => this.props.prevStep()} />
          <FintupButton fintupIcon="flecha-seleccionable-derecha" iconRight={true} textKey="button.common.next" className="buttonContainer" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.XLARGE} action={this.next.bind(this)} />
        </div>
      </div>
    )
  }
}

export default MiniTestStep4