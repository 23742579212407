import React from 'react'
import FintupComponent from '../FintupComponent'
import { doLoginSocial, doRegisterSocial } from '../../utils/fintup-api'
import './SocialLogin.scss'
import { GoogleAPI, GoogleLogin } from 'react-google-oauth'
import config, { getBooleanConfig } from '../../config/config'
import _ from 'lodash'
import RSVP from 'rsvp'
import constants from '../../utils/constants'
import { injectIntl } from 'react-intl'
import classNames from 'classnames'
import { FormattedMessage } from 'react-intl'

/**
 * El componente no se renderiza si la propiedad de configuracion socialLogin esta definida como false
 * Props:
 *  additionalRquestData: Añade datos adicionales a la request, para poder hacer validacion en el servidor (aceptado de terminos y condiciones)
 *  registerMinitest: Check if minitest -> First register customer potential, after register in app by google 
 */
class SocialLogin extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      renderGoogleButton: true
    }

    console.log("MINITEST social login: ", this.props.registerMinitest)
  }

  googleLoginSuccess (data) {
    //Dynamic property, protect from changes of Google responses
    let dataObject
    for(var property in data){
      if(data[property].hasOwnProperty('access_token')){
        dataObject = data[property]
        break;
      }
    }
    let loginSocialData = {
      accessToken: dataObject.access_token,
      idToken: dataObject.id_token,
      loginHint: dataObject.login_hint,
      loginType: 'google',
      invitation: this.props.invitationCode,
      origin: this.props.registerMinitest ? constants.REGISTER.ORIGIN.MINITEST : null,
    }

    if (this.props.additionalRquestData) {
      _.merge(loginSocialData, this.props.additionalRquestData)
    }
    
    this.minitestAction(loginSocialData).then(() => {
      let componentPromise

      if (this.props.register) {
        console.log("DO REGISTER SOCIAL DATA", loginSocialData)
        componentPromise = doRegisterSocial(loginSocialData)
      } else {
        console.log("DO LOGIN SOCIAL DATA", loginSocialData)
  
        componentPromise = doLoginSocial(loginSocialData)
      }
      
      componentPromise
        .then(this.props.successLogin)
        .catch(this.props.errorLogin)
    }).catch((result) => {
      console.log("error invest socialLogin: ", result)
      this.props.showAlert({ textKey: result.message })
    })
  }

  minitestAction(loginSocialData){
    //Check register by minitest
    if(this.props.registerMinitest){
      return this.props.registerMinitestAction(loginSocialData)
    }
    else{
      return RSVP.resolve()
    }
  }

  // /////////////////////

  onInitFailure (data) {
    console.log('ko', data, 'No se ha podido cargar el login de google')
    this.setStatePromise({ renderGoogleButton: false })
  }

  onUpdateSigninStatus (data) {
    console.log('cambio de estado en el login ok', data)
  }

  responseFailureGoogle (data) {
    console.log('KO -> No se ha podido realizar el login con google:', data)
    let koKey = this.props.register ? 'register.google.ko' : 'Login.google.ko'
    this.props.showAlert({ textKey: koKey })
  }

  get onValidate(){
    return this.props.onValidate ? this.props.onValidate.bind(this) : () => true
  }

  get renderText(){
    return (this.props.textKey) ? (<FormattedMessage id={this.props.textKey} />): this.props.text || 'Google'
   }

  get googleButton () {
    return config.socialLogin && this.state.renderGoogleButton
      ? (
        <GoogleAPI clientId={config.googleLoginApiKey}
          onUpdateSigninStatus={this.onUpdateSigninStatus.bind(this)}
          onInitFailure={this.onInitFailure.bind(this)} >
          <GoogleLogin text={this.renderText} width="125px"
            onLoginSuccess={this.googleLoginSuccess.bind(this)}
            onLoginFailure={this.responseFailureGoogle.bind(this)}
            disabled={this.props.disabled ? this.props.disabled : false}/>
        </GoogleAPI>
      )
      : ''
  }

  get containerClasses () {
    return classNames(
      'socialLogin',
      {[`theme-${this.props.theme}`]: this.props.theme},
      this.props.className,
    )
  }

  render () {
    let renderComponent = getBooleanConfig('socialLogin', true)
    if (renderComponent) {
      return (
        <div className={this.containerClasses} onClick={() => this.onValidate()}>
          {this.googleButton}
        </div>
      )
    } else {
      return ''
    }
  }
}

export default injectIntl(SocialLogin)
