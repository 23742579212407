// Path should work with dot or array notation.
var PATH_REGEX = /[.["'\]]+/

function get (property, defaultValue) {
  // Split and clean empty paths.
  var path = property.split(PATH_REGEX).filter(Boolean)
  return function (element) {
    return typeof element === 'object'
      ? _readProperty(path, element, defaultValue)
      : element
  }
}

function set (property, value) {
  // Split and clean empty paths.
  var path = property.split(PATH_REGEX).filter(Boolean)
  return function (element) {
    return typeof element === 'object'
      ? _setProperty(path, element, value)
      : element
  }
}

function _readProperty (path, obj, defaultValue) {
  var pathLength = path.length

  return path.reduce(function (acc, key, index) {
    var value = acc && acc[key]
    var isLast = index + 1 === pathLength
    var isObject = value === Object(value)

    if (!isObject && !isLast) {
      return defaultValue
    }
    // should allow null and falsy values.
    return (value || (value !== undefined))
      ? value
      : defaultValue
  }, obj)
}

function _setProperty (path, obj, value) {
  return path.reduce(function (acc, key, index, array) {
    var isLast = (index === array.length - 1)
    acc[key] = isLast ? value : (acc[key] || {})
    return acc[key]
  }, obj)
}

const tGet = get
const tSet = set

let transverser = {
  get: get,
  set: set
}

export { tGet, tSet }

export default transverser
