import SessionController from '../../core/SessionMobx'
import RSVP from 'rsvp'
import config from '../../config/config'
import moment from 'moment'

const responseSuccessLogin = function (response) {
  let sessionMinutes = parseInt(config.sessionMaxTimeMinutes, 10)

  let limitSessionDate = moment().add(sessionMinutes, 'minutes')

  SessionController.setSession({
    user: response.data.user.username,
    name: response.data.user.name,
    token: response.data.token,
    userData: response.data.user,
    oneTimePassword: response.data.oneTimePassword,
    emulateSession: false,
    partnerSession: false,
    redirectUrl: response.data.redirectUrl,
    extendData: {
      limitSessionDate: limitSessionDate
    }
  })
  this.setState()
  SessionController.redirectToUnloggedUrl()
  return RSVP.resolve()
}

const loginHelper = {
  responseSuccessLogin: responseSuccessLogin
}

export default loginHelper
