import React, { Component } from 'react'
import './Overlay.scss'

class Overlay extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    return (
      <div className="overlay" onClick={this.props.clickOverlay}></div>
    )
  }
}

export default Overlay
