import React from 'react'
import FintupView from './FintupView'
import { Helmet } from 'react-helmet'
import config from '../config/config'
import HeaderTags from './common/HeaderTags'

class FintupHeader extends FintupView {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    return (
     <HeaderTags></HeaderTags>
    )
  }
}

export default FintupHeader
