import React from 'react'
import './MiniTestStep5.scss'
import FintupComponent from '../FintupComponent'
import { FormattedMessage } from 'react-intl'
import constans from '../../utils/constants'
import FintupButton from '../common/forms/FintupButton'
import transverser from '../../utils/transverser'
import utils from '../../utils/utils'
import { injectIntl } from 'react-intl'


class MiniTestStep5 extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("PROPS MINITESTSTEP5", props)

    this.state = {
      actualStep: 5,
    }
  }

  getModelProperty(value){
    return transverser.get(`model.${value}`)(this.props)
  }

  /**
   * Validation fields and check
   * if we can go to the next step
   */
  next(){
    this.props.nextStep()
  }

  render () {
    return (
      <div className="miniTestStep5">
        <div className="content text-center">
          <div className="row">
            <h3><FormattedMessage id="miniTestStep5.title" /></h3>
            <span className="subtitle-text"><FormattedMessage id="miniTestStep5.text1" /></span>
          </div>
          <div className="row containerInvest">
            <div className="investStyle investObjective" onClick={() => this.props.goToStep(1)}>
              <p><FormattedMessage id={this.getModelProperty('objectiveText')}/></p>
              <p><FormattedMessage id="miniTestTab.title1"/></p>
              <img src="/img/minitest/edit.svg" alt={this.props.intl.formatMessage({'id': 'miniTestTab.title1'})}></img>
            </div>
            <div className="investStyle investInvestor" onClick={() => this.props.goToStep(4)}>
              <p><FormattedMessage id={this.getModelProperty('typeInvestorText')}/></p>
              <p><FormattedMessage id="miniTestTab.title2"/></p>
              <img src="/img/minitest/edit.svg" alt={this.props.intl.formatMessage({'id': 'miniTestTab.title2'})}></img>
            </div>
          </div>
          <div className="row containerInvest">
            <div className="investStyle investTime" onClick={() => this.props.goToStep(2)}>
              <p>{this.getModelProperty('years')}<span className="titleMini"><FormattedMessage id="miniTestTab.title3a"/></span></p>
              <p><FormattedMessage id="miniTestTab.title3"/></p>
              <img src="/img/minitest/edit.svg" alt={this.props.intl.formatMessage({'id': 'miniTestTab.title3'})}></img>
            </div>
            <div className="investStyle investMoney" onClick={() => this.props.goToStep(3)}>
              <div>
                <p>{utils.formatCurrencyNoDecimals(this.getModelProperty('contributionInitial'))}</p>
                <p><FormattedMessage id="miniTestTab.title4"/></p>
              </div>
              <div>
                <p>{utils.formatCurrencyNoDecimals(this.getModelProperty('contributionMonthly'))}</p>
                <p><FormattedMessage id="miniTestTab.title5"/></p>
              </div>
              <img src="/img/minitest/edit.svg" alt={this.props.intl.formatMessage({'id': 'miniTestTab.title5'})}></img>
            </div>
          </div>
          <div className="miniTestStepButtonContainer">
            <FintupButton textKey="miniTestStep5.textButton" className="buttonContainer" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.XXLARGE} action={this.next.bind(this)} />
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(MiniTestStep5)