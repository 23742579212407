import React from 'react'
import './FintupIcon.scss'
import FintupComponent from '../FintupComponent'
import classNames from 'classnames'
import uniqid from 'uniqid'
import utils from '../../utils/utils'

/**
 * props:
 *  icon: icono a mostrar en función de la css
 *  size: Tamaño de la fuente del icono
 *  className: Clases que se le añade al contenedor
 *  onClick: Accion lanzada al hacer onclick sobre el componente
 *  color: Para personalizar el color del icono: okColor: Aceptar Fintup, cancelColor: Cancelar fintup
 *  weight: ancho de la fuente del icono
*/
class FintupIcon extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      iconId: uniqid()
    }
  }

  get iconClasses () {
    return classNames('fintupIcons',
      this.props.icon,
      this.props.className,
      {[`icon-size-${this.props.size}`]: this.props.size},
      {[`icon-weight-${this.props.weight}`]: this.props.weight},
      this.props.color,
      { CircleIcon: this.props.circle },
      { [`CircleIcon--${this.props.circle}`]: this.props.circle }
    )
  }

  get renderPaths () {
    let resultHtml = []
    const pathNumbers = {
      'inversion-coloreado': 16,
      'ahorro-coloreado': 16
    }

    if (pathNumbers[this.props.icon]) {
      resultHtml = Array(pathNumbers[this.props.icon]).join('.').split('.')
    }

    return resultHtml.map((item, index) => {
      return (
        <span key={`fintupIcon-${this.state.iconId}-${index}`} className={`path${index + 1}`}/>
      )
    })
  }

  doClick () {
    if (this.props.onClick) {
      this.props.onClick()
    }
  }

  render () {
    return (
      <span className={this.iconClasses} onClick={this.doClick.bind(this)} onKeyPress={utils.noop} role="presentation">{this.renderPaths}</span>
    )
  }
}

export default FintupIcon
