import RSVP from 'rsvp'
import config from './config'
import _ from 'lodash'
import { doGetHotConfig, doGetHotConfigBackend } from '../utils/fintup-api'
import { tSet } from '../utils/transverser'
function init () {
  return doGetHotConfig()
    .then(response => {
      _.merge(config, response.data)
      return RSVP.resolve()
    })
    .then(doGetHotConfigBackend)
    .then(response => {
      for (let propertyKey in response.data) {
        if (response.data.hasOwnProperty(propertyKey)) {
          tSet(propertyKey, response.data[propertyKey])(config)
        }
      }
      return RSVP.resolve()
    })
    .catch(error => {
      console.log(error)
      return RSVP.resolve() // Evitamos que falle la carga de la aplicacion por fallar esta promesa
    })
}

let hotConfig = {
  init: init
}
export default hotConfig
