import React from 'react'
import { browserHistory } from 'react-router'
import FintupView from '../FintupView'
import { FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import SingleFormContainer from '../common/SingleFormContainer'
import './RegisterView.scss'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'
import SocialLogin from '../common/SocialLogin'
import InputTextLabel from '../common/forms/InputTextLabel'
import RSVP from 'rsvp'
import navUtils from '../../core/navUtils'
import { doRegister } from '../../utils/fintup-api'
import urls from '../../utils/urls'
import utils from '../../utils/utils'
import Checkbox from '../common/forms/Checkbox'
import loginHelper from '../home/loginHelper'
import ConfigRoutes from '../../core/ConfigRoutes'
import classNames from 'classnames'
import { tGet } from '../../utils/transverser'
import _ from 'lodash'
import Terms from '../common/Terms'
import TermsModel from '../common/TermsModel'
import RegisterCode from './RegisterCode'
import HeaderTags from '../common/HeaderTags'

class RegisterView extends FintupView {
  constructor (props) {
    super(props)
    let location = browserHistory.getCurrentLocation()
    let routeData = ConfigRoutes.getRouteData(location.pathname)
    this.state = {
      alert: {},
      user: {},
      isWidget: routeData.isWidget,
      isActivationCodeView: false,
      showTerms: false,
      invitationCode: this.props.routeParams.invitationCode
    }
  }

  showAlert (data) {
    let alert = {}
    if (data.textKey !== '') {
      alert = {
        alertText: <FormattedMessage id={data.textKey} />,
        alertType: data.alertType || undefined
      }
    }
    return this.setStatePromise({alert: alert})
  }

  errorLogin (result) {
    this.setStatePromise({alert: { alertText: result.message }})
  }

  responseErrorLogin (response) {
    this.errorLogin(response)
    return RSVP.reject()
  }

  doRegister () {
    if (utils.isEmpty(this.state.user.name)) {
      this.showAlert({textKey: 'regsterView.validation.name'})
      return
    }

    if (utils.isEmpty(this.state.user.surname)) {
      this.showAlert({textKey: 'regsterView.validation.surname'})
      return
    }

    if (!utils.validateEmail(this.state.user.email)) {
      this.showAlert({textKey: 'regsterView.validation.email'})
      return
    }

    let password = _.trim(tGet('user.password')(this.state))
    let passwordConfirm = _.trim(tGet('user.passwordConfirm')(this.state))

    if (password === '') {
      this.showAlert({textKey: 'ChangePassword.fillPassword'})
      return
    }

    if (!utils.validatePassword(password)) {
      this.showAlert({textKey: 'ChangePassword.validatePassword'})
      return
    }

    if (password !== passwordConfirm) {
      this.showAlert({textKey: 'ChangePassword.samePassword'})
      return
    }

    if (!this.state.user.dataProcessingTerms) {
      this.showAlert({textKey: 'regsterView.validation.dataProcessingTerms'})
      return
    }

    if (!this.state.user.communicationTerms) {
      this.showAlert({textKey: 'regsterView.validation.communicationTerms'})
      return
    }

    if (!this.state.user.profilesTerms) {
      this.showAlert({textKey: 'regsterView.validation.profilesTerms'})
      return
    }

    let registerParam = tGet('routeParams.registerParam')(this.props)
    let registerData = _.merge(
      {
        origin: registerParam,
        invitation: this.state.invitationCode
      },
      this.state.user)

    this.showAlert({textKey: ''})
      .then(() => doRegister(registerData))
      .then(() => { //Change view to registerCode component
        this.setStatePromise({isActivationCodeView: true})
        //navUtils.goToExternal(urls.internal.activationCode)
      })
      /*.then(() => {
        return this.showAlert({textKey: 'regsterView.validation.ok', alertType: constants.alertType.SUCCESS})
      })*/
      .catch(this.handleServiceError.bind(this))
  }

  /* En caso de estar cargados como widget tenemos que ir al 100%
  En casos de estar cargados como aplicacion estamos dentro de contenedores y tenemos que centrarns */
  get containerViewClasses () {
    return classNames(
      {'col-sm-offset-3 col-sm-6 col-xs-12': !this.state.isWidget},
      {'col-xs-12': this.state.isWidget}
    )
  }

  get viewClasses () {
    return classNames(
      'newStyles row registerView',
      {'fintupResponsiveForm': !this.state.isWidget}
    )
  }

  get renderTitle () {
    return this.state.isWidget
      ? ''
      : (<div className="text-center outText">
        <FormattedHTMLMessage id="regsterView.title.out"/>
      </div>)
  }

  get renderFoter () {
    return this.state.isWidget
      ? ''
      : (
        <div className="forgetContainer" onClick={() => navUtils.goToInternal(urls.internal.login)} role="presentation" onKeyPress={utils.noop}>
          <FormattedMessage id="regsterView.login" />
        </div>
      )
  }

  get renderContent () {
    let dataProcessingTermsSubText = <a href={TermsModel.urls.dataProcessingTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.dataProcessingTerms.acceptLink" /></a>
    let dataProcessingTermsText = <FormattedMessage id="regsterView.dataProcessingTerms" values={{ tag1: dataProcessingTermsSubText }} />
    let communicationTermsSubText = <a href={TermsModel.urls.communicationTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.communicationTerms.acceptLink" /></a>
    let communicationTermsText = <FormattedMessage id="regsterView.communicationTerms" values={{ tag1: communicationTermsSubText }} />
    let profilesTermsSubText = <a href={TermsModel.urls.profilesTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.profilesTerms.acceptLink" /></a>
    let profilesTermsText = <FormattedMessage id="regsterView.profilesTerms" values={{ tag1: profilesTermsSubText }} />
    return (
      <div>
        <SocialLogin
          invitationCode={this.state.invitationCode}
          register={true}
          showAlert={this.showAlert.bind(this)}
          successLogin={loginHelper.responseSuccessLogin.bind(this)}
          errorLogin={this.responseErrorLogin.bind(this)}
          additionalRquestData={{
            dataProcessingTerms: this.state.user.dataProcessingTerms,
            communicationTerms: this.state.user.communicationTerms,
            profilesTerms: this.state.user.profilesTerms
          }}/>
        <div className="clearAll marginTop15"/>
        <div className="separator"><hr/></div>
        <div className="separatorCenter"><FormattedMessage id="regsterView.or" /></div>
        <div className="separator"><hr/></div>
        <InputTextLabel placeholder="regsterView.name" name="user.name" callBackOnChange={this.commonHandleChange.bind(this)} />
        <InputTextLabel placeholder="regsterView.surname" name="user.surname" callBackOnChange={this.commonHandleChange.bind(this)}/>
        <InputTextLabel placeholder="regsterView.email" name="user.email" callBackOnChange={this.commonHandleChange.bind(this)}/>
        <InputTextLabel type="password" placeholder="regsterView.password" name="user.password" callBackOnChange={this.commonHandleChange.bind(this)}/>
        <InputTextLabel type="password" placeholder="regsterView.passwordConfirm" name="user.passwordConfirm" callBackOnChange={this.commonHandleChange.bind(this)}/>
        <Checkbox
          name="user.dataProcessingTerms"
          text={dataProcessingTermsText} onChangeCheckbox={this.commonCheckboxChange.bind(this)}
          value={this.state.user.dataProcessingTerms} />
        <Checkbox
          name="user.communicationTerms"
          text={communicationTermsText} onChangeCheckbox={this.commonCheckboxChange.bind(this)}
          value={this.state.user.communicationTerms} />
        <Checkbox
          name="user.profilesTerms"
          text={profilesTermsText} onChangeCheckbox={this.commonCheckboxChange.bind(this)}
          value={this.state.user.profilesTerms} />
        <FintupButton type="submit" size={constants.BUTTON_SIZE.FULL} buttonClassName="btnSelector" textKey="regsterView.register" action={this.doRegister.bind(this)} />
        {this.renderFoter}
        <div className="clearAll"/>
      </div>
    )
  }

  get renderViewActivationRegister(){
    if(this.state.isActivationCodeView){
      return (
        <RegisterCode classNameContainer="double" user={this.state.user}></RegisterCode>
      )
    }
    else{
      return (
        <div>
          {this.renderTitle}
          <SingleFormContainer alertText={this.state.alert.alertText} alertType={this.state.alert.alertType} className="double" titleKey="regsterView.title" legendKey="regsterView.legend">
            {this.renderContent}
          </SingleFormContainer>
        </div>
      )
    }
  }

  render () {
    
    return (
      <div className={this.viewClasses}>
        <div className={this.containerViewClasses}>
          {this.renderViewActivationRegister}
        </div>
        <Terms/>
      </div>
    )
  }
}

export default RegisterView
