import React from 'react'
import { injectIntl, FormattedMessage } from "react-intl";
import FintupComponent from "../FintupComponent";
import './ContractStep2.scss';
import FintupButton from '../common/forms/FintupButton';
import constants from '../../utils/constants';
import ContractTabs from './ContractTabs';
import TitleLabel from '../common/forms/TitleLabel';
import InputTextLabel from '../common/forms/InputTextLabel';
import SelectLabel from '../common/forms/SelectLabel';
import { getContractPersonalData } from '../../utils/fintup-api';
import transverser from '../../utils/transverser';
import utils from '../../utils/utils';
import urls from '../../utils/urls';
import ContractExpertHelp from './ContractExpertHelp';

class ContractStep2 extends FintupComponent
{
  constructor(props){
    super(props)

    this.state = {
      actualStep: 3,

      //Error fields text
      streetErrorText: '',
      cityErrorText: '',
      provinceErrorText: '',
      postalCodeErrorText: '',
      countryResidenceErrorText: '',
    }
  }

  hideErrorsText(){
    return this.setStatePromise({
      streetErrorText: '',
      cityErrorText: '',
      provinceErrorText: '',
      postalCodeErrorText: '',
      countryResidenceErrorText: '',
    })
  }

  componentDidMount(){
    //Check if exists values fillables
    let state = transverser.get('model.step2')(this.props)
    let existsStep = transverser.get('model.step2', false)(this.props) ? true : false


    if(!existsStep){
      getContractPersonalData()
      .then(response => {
          let defaultSreet = transverser.get('address.address', '')(this.props.model)
          let defaultCity = transverser.get('address.city', '')(this.props.model)
          let defaultPostalCode = transverser.get('address.postalCode', '')(this.props.model)
          let defaultPovince = transverser.get('address.province', '')(this.props.model)
  
          let hasDefaultProfile = false
  
          if (defaultSreet && defaultSreet !== '') {
            hasDefaultProfile = true
          }
  
          let peronalDataModel = {
            street: transverser.get('street', defaultSreet)(response.data) || defaultSreet,
            city: transverser.get('city', defaultCity)(response.data) || defaultCity,
            province: transverser.get('province', defaultPovince)(response.data) || defaultPovince,
            postalCode: transverser.get('postalCode', defaultPostalCode)(response.data) || defaultPostalCode,
            countryResidence: 'España',
            hasDefaultProfile: hasDefaultProfile
          }
  
          let postalCode = utils.formatPostalCode(peronalDataModel.postalCode)
          peronalDataModel.postalCode = postalCode
          return this.setStatePromise(peronalDataModel)
      })
    }else{
      this.setStatePromise(state)
    }
  }

  /**
  * Validation fields and check
  * if we can go to the next step
  */
  next(){
    let street = this._getState('street', '').trim()
    let city = this._getState('city', '').trim()
    let province = this._getState('province', '').trim()
    let postalCode = this._getState('postalCode', '').trim()
    let countryResidence = this._getState('countryResidence', '').trim()

    let isError = false;

    //8 - street
    this.setStatePromise({streetErrorText: ''})
    if (street === '') {
      this.setStatePromise({streetErrorText: 'ContractPersonalData.validation.street'})
      isError = true
      utils.scrollToTop()
    }

    //9 - postalCode
    this.setStatePromise({postalCodeErrorText: ''})
    if (!utils.validateCP(postalCode)) {
      this.setStatePromise({postalCodeErrorText: 'ContractPersonalData.validation.cp'})
      isError = true
      utils.scrollToTop()
    }

    //10 - city
    this.setStatePromise({cityErrorText: ''})
    if (city === '') {
      this.setStatePromise({cityErrorText: 'ContractPersonalData.validation.city'})
      isError = true
      utils.scrollToTop()
    }

    //11 - province
    this.setStatePromise({provinceErrorText: ''})
    if (province === '') {
      this.setStatePromise({provinceErrorText: 'ContractPersonalData.validation.province'})
      isError = true
      utils.scrollToTop()
    }

    //12 - countryResidence
    this.setStatePromise({countryResidenceErrorText: ''})
    if (countryResidence === '') {
      this.setStatePromise({countryResidenceErrorText: 'ContractPersonalData.validation.countryResidence'})
      isError = true
      utils.scrollToTop()
    }

    if(isError){
      return
    }

    this.hideErrorsText().then(() => {
      this.props.updateModel({
        ...this,
        step2: this.state,
      })
    })
    
    this.props.nextStep()
  } 

  render(){
    return(
      <div className="contractStep2">
        <div className="content text-center">
          <ContractTabs values={this} blockNextItems={true} actualStep={this.state.actualStep} titleKey="contractGeneralView.title1" elementActive="2"></ContractTabs>
          <div className="container contentData">
            <div className="row">
              <TitleLabel 
                title="8"
                descriptionKey="contractStep.title17"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={this.state.hasDefaultProfile} theme="new"
              placeholder="ContractPersonalData.calle" 
              isError={this.state.streetErrorText != ''}
              errorTextKey={this.state.streetErrorText}
              name="street" value={this.state.street} callBackOnChange={this.commonHandleChange.bind(this)}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="9"
                descriptionKey="contractStep.title18"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={this.state.hasDefaultProfile} theme="new"
              maxLength="5" type="number" 
              isError={this.state.postalCodeErrorText != ''}
              errorTextKey={this.state.postalCodeErrorText}
              placeholder="ContractPersonalData.cp" name="postalCode" value={this.state.postalCode} callBackOnChange={this.commonHandleChange.bind(this)}/>

            </div>
            <div className="row">
              <TitleLabel 
                title="10"
                descriptionKey="contractStep.title19"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={this.state.hasDefaultProfile} theme="new"
               placeholder="ContractPersonalData.ciudad"
               isError={this.state.cityErrorText != ''}
               errorTextKey={this.state.cityErrorText}
               name="city" value={this.state.city} callBackOnChange={this.commonHandleChange.bind(this)}/>

            </div>
            <div className="row">
              <TitleLabel 
                title="11"
                descriptionKey="contractStep.title20"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <SelectLabel disabled={this.state.hasDefaultProfile} theme="new"
              name="province" placeholder="ContractPersonalData.provincia" 
              options={transverser.get('listOptions.provinceOptions', '')(this.props.model)} selectedValue={this.state.province} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="12"
                descriptionKey="contractStep.title34"
                theme="new">
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={true} theme="new"
               placeholder="ContractPersonalData.residencia"
               isError={this.state.countryResidenceErrorText != ''}
               errorTextKey={this.state.countryResidenceErrorText}
               name="countryResidence" value={this.state.countryResidence}/>
            </div>
          </div>
          <div className="buttonsContainer">
            <FintupButton fintupIcon="flecha-seleccionable-izquierda"
            iconRight={true} textKey="button.common.next" 
            className="buttonContainer buttonPrevMiniTest"
            buttonClassName="btn-bold" size={constants.BUTTON_SIZE.ONLY_ICON} action={this.props.prevStep} />
            <FintupButton
              fintupIcon="flecha-seleccionable-derecha" 
              iconRight={true} textKey="button.common.next" 
              className="buttonContainer buttonAttention"
              buttonClassName="btn-bold"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={() => this.next()}/>
          </div>
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        <ContractExpertHelp></ContractExpertHelp>
      </div>
    )
  }
}
export default injectIntl(ContractStep2)
