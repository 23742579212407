import { extendObservable } from 'mobx'
import RSVP from 'rsvp'
class LoadingController {
  constructor () {
    extendObservable(this, {
      isLoading: false
    })
  }

  setLoading () {
    this.isLoading = true
    return RSVP.resolve()
  }

  unsetLoading () {
    this.isLoading = false
    return RSVP.resolve()
  }
}
var LoadingMobx = new LoadingController()
export default LoadingMobx
