import React from 'react'
import FintupView from "../FintupView"
import './RegisterCodeNew.scss'
import FintupButton from '../common/forms/FintupButton'
import navUtils from '../../core/navUtils'
import SingleFormContainer from '../common/SingleFormContainer'
import LoadingApp from '../common/LoadingApp'
import InputTextLabel from '../common/forms/InputTextLabel'
import constants from '../../utils/constants'
import utils from '../../utils/utils'
import { injectIntl } from 'react-intl'
import { postActivationUser, doLoginPost } from '../../utils/fintup-api'
import FintupComponent from '../FintupComponent'
import classNames from 'classnames'
import loginHelper from '../home/loginHelper'
import transverser from '../../utils/transverser'


class RegisterCodeNew extends FintupComponent {

  constructor (props) {
    super(props)
    this.state = {
      activationProcess: false,//false, //Change to false
      activationStatus: false,//false, //Change to false
      code: '',
      codeFieldErrorText: "",
      timeRedirectLogin: 2000
    }
  }

  doValidate(){
    let isError = false;
    this.setStatePromise({codeFieldErrorText: ''})
    if (utils.isEmpty(this.state.code)) {
      this.setStatePromise({codeFieldErrorText: 'activationCodeView.errorEmpty'})
      isError = true
    }

    if(isError)
      return;

    let data = {
      token: this.state.code
    }
    postActivationUser(data)
      .then(() => {
        return this.setStatePromise({
          activationProcess: true,
          activationStatus: true
        })
        .then(() => {
          this.handleNotification({textKey: 'activationCodeView.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
        })
        .then(() => {
          setTimeout(() => this.doLogin(), this.state.timeRedirectLogin)
        })
      })
      .catch((result) => {
        console.log(result)
        this.handleNotification({titleKey: 'activationCodeView.errortitle', textKey: result.message, type: constants.NOTIFICATION_TYPE.ERROR})
      })
      .catch(() => this.setStatePromise({activationProcess: false}))
    return
  }

  doLogin(){
    doLoginPost({
      username: this.props.user.email,
      password: this.props.user.password
    })
    .then(loginHelper.responseSuccessLogin.bind(this))
    .catch(this.handleServiceError.bind(this))
  }

  handleChange (data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    this.setState({[fieldName]: fieldValue})
  }

  get mainView () {
    if(this.state.activationProcess){
      if(this.state.activationStatus){
        return (
          <LoadingApp className="noFullHeight marginTop50"/>
        )
      }
      else{
        return (<LoadingApp className="noFullHeight marginTop50"/>)
      }
    }
    else{
      return (
        <div>
          <div className="col-md-offset-3 col-md-5">
            <InputTextLabel 
              type="text"
              placeholder="activationCodeView.placeholderCode"
              name="code" 
              theme="new"
              value={this.state.code}
              isError={this.state.codeFieldErrorText != ''}
              errorTextKey={this.state.codeFieldErrorText}
              callBackOnChange={this.handleChange.bind(this)} />
          </div>
          <div className="col-md-4 buttonsFields">
            <FintupButton 
              type="submit"
              textKey="activationCodeView.validateButton" 
              className="buttonAttention"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={this.doValidate.bind(this)}/>
          </div>
       </div>
     )
    }
  }

  get componentClasses () {
    return classNames('registerCode', 'container', this.props.className)
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <div className="row">
          {this.mainView}
        </div>
      </div>
    )
  }
}

export default injectIntl(RegisterCodeNew)