import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import uniqid from 'uniqid'
import RangeSlider from 'react-rangeslider'
import classNames from 'classnames'
import FintupComponent from '../../FintupComponent'
import _ from 'lodash'
import 'react-rangeslider/lib/index.css'
import './FintupSlider.scss'
import SliderLabel from './SliderLabel'
import RSVP from 'rsvp'

/* Props:
  min: Valor Minimo
  max: Valor Maximo
  start: Valor Inicial, en caso de no indicarse se hace la media de ambos valores
  name: Nombre del componente que se utilizara para modificar de los componentes padre
  callBackOnChange: Funcion para recoger el valor del slider cuando cambia
  showTitleValue: Muestra junto al titulo del slider el valor seleccionado
  step: Establece el incremento del slider
  titleValueFormat: Formatea el valor del slider mostrado junto al titulo
  minZero: En caso de que el valor minimo del slider sea menor que 0 lo deja en 0
  options: Se le pasa un array de opciones para el caso de querer un slider asociativo con opciones cerradas
  orderField: Del array de opciones asociatitas se indica el field por el que se ordenaran esas opciones
  valueField: Del array de opciones asociativas el campo del objeto que se corresponde con el valor
  labelField: Del array de opciones asociativas el campo que se corresponde con el texto
  showLabelLines: Cuando es un Slider asociativo indica si se muestan las lineas de la leyenda
  labelTextKeys: Indica si los labels se pasan como texto o como keys de texto
  render: Si es undefined o true el elemento se renderiza en caso de false no
  labels: En caso de querer añadir labels al slider
  format: Funcion para formatear el label que se muestra al mover el slider
  changeValueOnComplete: true -> Solo eleva el nuevo valor cuando se termina de mover el slider
  showTooltip: true o false. Si se quiere mostrar el tooltip
*/
class FintupSlider extends FintupComponent {
  constructor(props, context) {
    super(props, context)
    let startValue = this.getStartValue(props)
    this.state = {
      customOptionsSlider: !!this.props.options,
      start: startValue,
      value: startValue,
      isChanging: false,
      componentName: this.props.name || uniqid(),
      showTooltip: this.props.showTooltip
    }
  }

  getStartValue(props) {
    if (this.props.options) {
      let sortedOptions = this.props.options.sort(this.sortOptionPredicate.bind(this))
      let selectedOptionIndex = _.findIndex(sortedOptions, option => option[this.props.valueField] === props.start)
      return selectedOptionIndex
    } else if (props.start) {
      return props.start
    } else {
      return Math.round((props.min + props.max) / 2)
    }
  }

  handleChangeStart = () => {
    this.setStatePromise({ isChanging: true })
  };

  handleChange = value => {
    this.setStatePromise({ value: value })
      .then(() => {
        if (_.isUndefined(this.props.changeValueOnComplete) || !this.props.changeValueOnComplete) {
          return this.elevateNewValue()
        } else {
          return RSVP.resolve()
        }
      })
  }

  obtainRealValue() {
    let selectedValue = this.state.value
    if (this.state.customOptionsSlider) {
      let selectedElement = this.customOptionsList[this.state.value]
      selectedValue = selectedElement.value
    }
    return selectedValue
  }

  realValueToSliderValue(value) {
    if (!this.state.customOptionsSlider) {
      return value
    }
    if (this.customOptionsList.length === 0) {
      return 0
    }

    let findedItem = this.customOptionsList.find(element => element.value === value)

    if (_.isUndefined(findedItem)) {
      return 0
    }
    return findedItem.indexItem
  }

  componentWillReceiveProps(newProps) {
    if (newProps.start !== this.state.value) {
      this.setStatePromise({ value: this.realValueToSliderValue(newProps.start) })
    }
  }

  elevateNewValue() {
    if (this.props.callBackOnChange) {
      let selectedValue = this.obtainRealValue()
      let data = {
        ev: {
          target: {
            name: this.state.componentName,
            value: selectedValue
          }
        }
      }
      return this.props.callBackOnChange(data)
    } else {
      return RSVP.resolve()
    }
  }

  handleChangeComplete = () => {
    this.setStatePromise({ isChanging: false })
      .then(() => {
        if (this.props.changeValueOnComplete) {
          return this.elevateNewValue()
        } else {
          return RSVP.resolve()
        }
      })
  }

  get componentClasses() {
    return classNames(
      'fintupSlider',
      this.state.isChanging ? 'changing' : '',
      !_.isEqual(this.sliderLabels, {}) ? 'withLabels' : '',
      { 'withoutImageLabels': !this.hasLabelImages }
    )
  }

  get titleValue() {
    if (this.props.showTitleValue) {
      let value = this.state.customOptionsSlider
        ? this.sliderLabels[this.state.value]
        : this.props.titleValueFormat
          ? this.props.titleValueFormat(this.state.value)
          : this.state.value
      return (
        <span className="titleValue">{value}</span>
      )
    } else {
      return ''
    }
  }

  get titleRow() {
    if (this.props.titleKey) {
      return (
        <div className="row">
          <div className="col-xs-12">
            <h1>
              <FormattedMessage id={this.props.titleKey} />
              {this.titleValue}
            </h1>
          </div>
        </div>
      )
    } else {
      return ''
    }
  }

  get minValue() {
    if (this.state.customOptionsSlider) {
      let customMinOption = this.customOptionsList.length > 0
        ? this.customOptionsList[0].indexItem
        : 0
      return customMinOption
    } else if (this.props.hasOwnProperty("min")) {
      return this.props.min
    } else {
      let minDelta = this.sliderStep * 50
      let minValue = this.state.start - minDelta
      return this.props.minZero && minValue < 0 ? 0 : minValue
    }
  }

  get maxValue() {
    if (this.state.customOptionsSlider) {
      let customMaxOption = this.customOptionsList.length > 0
        ? _.last(this.customOptionsList).indexItem
        : 0
      return customMaxOption
    } else if (this.props.max) {
      return this.props.max
    } else {
      let maxDelta = this.sliderStep * 50
      return this.state.start + maxDelta
    }
  }

  get sliderStep() {
    return this.props.step || 1
  }

  sortOptionPredicate(itemA, itemB) {
    return itemA[this.props.orderField] >= itemB[this.props.orderField] ? 1 : -1
  }

  get customOptionsList() {
    if (this.state.customOptionsSlider) {
      return this.props.options.sort(this.sortOptionPredicate.bind(this)).map((option, index) => {
        let value = option[this.props.valueField]
        let text = option[this.props.labelField]
        return {
          value: value,
          text: text,
          indexItem: index
        }
      })
    } else {
      return null
    }
  }

  get hasLabelImages() {
    let labels = this.sliderLabels
    for (let propertyKey in labels) {
      if (labels.hasOwnProperty(propertyKey)) {
        let sliderLabelIterate = labels[propertyKey]
        let imgProp = sliderLabelIterate.props.img
        if (!_.isUndefined(imgProp)) {
          return true
        }
      }
    }
    return false
  }

  get sliderLabels() {
    let labels = {}
    if (this.customOptionsList !== null) {
      this.customOptionsList.forEach(element => {
        labels[element.indexItem] = this.props.labelTextKeys
          ? <SliderLabel textKey={element.text} />
          : <SliderLabel text={element.text} />
      })
      return labels
    } else {
      return this.props.labels || {}
    }
  }

  render() {
    return _.isUndefined(this.props.render) || this.props.render
      ? (
        <div className={this.componentClasses}>
          {this.titleRow}
          <RangeSlider
            labels={this.sliderLabels}
            min={this.minValue}
            max={this.maxValue}
            step={this.sliderStep}
            value={this.state.value}
            onChangeStart={this.handleChangeStart}
            onChange={this.handleChange}
            onChangeComplete={this.handleChangeComplete}
            tooltip={this.state.showTooltip}
            format={this.props.format}
          />
        </div>
      )
      : ''
  }
}

export default injectIntl(FintupSlider)
