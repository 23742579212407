import React from 'react'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'
import './CopyRightNew.scss'
import FintupComponent from '../FintupComponent'
import config from '../../config/config'

class CopyRightNew extends FintupComponent {
  render () {
    let textKey1 = config.copyright_textKey1 || 'Copyright.fintup'
    let textKey2 = config.copyright_textKey2 || 'Copyright.cnp1'
    let textKey3 = config.copyright_textKey3 || 'Copyright.cnp2'

    return (
      <div className="copyRightNew newStyles footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 text-center">
              <div className="copyright">
                <div className="hidden-xs">
                  <FormattedMessage id={textKey1} values={{ year: moment().format('YYYY') }} /> | <FormattedMessage id={textKey2} />  | <FormattedMessage id={textKey3} />
                </div>
                <div className="show-xs">
                  <FormattedMessage id={textKey1} values={{ year: moment().format('YYYY') }} />
                </div>
                <div className="show-xs">
                  <FormattedMessage id={textKey2} />
                </div>
                <div className="show-xs">
                  <FormattedMessage id={textKey3} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default CopyRightNew
