import { browserHistory } from 'react-router'
import React from 'react'
import SessionController from '../core/SessionMobx'
import { eventPool } from '../utils/eventHelper'
import Async from 'react-code-splitting'
import Home from '../components/home/Home'
import HomeNew from '../components/home/HomeNew'
import SSOView from '../components/sso/SSOView'
import urls from '../utils/urls'
import _ from 'lodash'
import LoadingMobx from './LoadingMobx'
import MiniTestView from '../components/minitest/MiniTestView'
import ContractGeneralView from '../components/contract-funnel/ContractGeneralView'

const SuitabilityView = props => <Async load={import('../components/suitability/SuitabilityView')} componentProps={props} />
const SimulatorView = props => <Async load={import('../components/simulator/SimulatorView')} componentProps={props} />
const ReactivateView = props => <Async load={import('../components/reactivate/Reactivate')} componentProps={props} />
const ChangePasswordView = props => <Async load={import('../components/changePassword/ChangePassword')} componentProps={props} />
const HelpdeskView = props => <Async load={import('../components/helpdesk/HelpdeskView')} componentProps={props} />
const DocumentsView = props => <Async load={import('../components/documents/DocumentsView')} componentProps={props} />
const SimulatorWidgetView = props => <Async load={import('../components/simulatorWidget/SimulatorWidgetView')} componentProps={props} />
const GlobalPositionV2View = props => <Async load={import('../components/globalPositionV2/GlobalPositionV2View')} componentProps={props} />
const EmailValidateView = props => <Async load={import('../components/emailValidate/EmailValidateView')} componentProps={props} />
const ActivationView = props => <Async load={import('../components/activation/ActivationView')} componentProps={props} />
const TestView = props => <Async load={import('../components/test/TestView')} componentProps={props} />
const SingleSimulatorView = props => <Async load={import('../components/singleSimulator/SingleSimulatorView')} componentProps={props} />
const CardSimulatorView = props => <Async load={import('../components/cardSimulator/CardSimulatorView')} componentProps={props} />
const IddRequirementsView = props => <Async load={import('../components/idd/IddRequirementsView')} componentProps={props} />
const ContributionView = props => <Async load={import('../components/contribution/ContributionView')} componentProps={props} />
const OperationDetailView = props => <Async load={import('../components/operationDetail/OperationDetailView')} componentProps={props} />
const NotificationsView = props => <Async load={import('../components/notifications/NotificationsView')} componentProps={props} />
const NotificationsNewView = props => <Async load={import('../components/notifications/NotificationsNewView')} componentProps={props} />
const IconsView = props => <Async load={import('../components/common/IconsView')} componentProps={props} />
const ContractViewV2 = props => <Async load={import('../components/contractProduct/ContractViewV2')} componentProps={props} />
const ProfileView = props => <Async load={import('../components/profile/ProfileView')} componentProps={props} />
const ProfileViewNew = props => <Async load={import('../components/profile/ProfileViewNew')} componentProps={props} />
const RegisterView = props => <Async load={import('../components/register/RegisterView')} componentProps={props} />
const RegisterNewView = props => <Async load={import('../components/register/RegisterNewView')} componentProps={props} />
const ContractPensionPlanView = props => <Async load={import('../components/contractProduct/ContractPensionPlanView')} componentProps={props} />
const ToolsView = props => <Async load={import('../components/tools/ToolsView')} componentProps={props} />
const AcademyView = props => <Async load={import('../components/academy/AcademyView')} componentProps={props} />
const IddStepView = props => <Async load={import('../components/idd/IddStepView')} componentProps={props} />
const MemberGetMemberView = props => <Async load={import('../components/memberGetMember/MemberGetMemberView')} componentProps={props} />
const DetailsInviteView = props => <Async load={import('../components/memberGetMember/DetailsInviteView')} componentProps={props} />
const AcknowledgmentReceiptView = props => <Async load={import('../components/acknowledgment/AcknowledgmentReceiptView')} componentProps={props} />
const SignFormView = props => <Async load={import('../components/sign/SignFormView')} componentProps={props} />
const OneMillionView = props => <Async load={import('../components/oneMillion/OneMillionView')} componentProps={props} />
const CompoundInterestView = props => <Async load={import('../components/compoundInterest/CompoundInterestView')} componentProps={props} />
const ContractGeneralDataView = props => <Async load={import('../components/contract/ContractGeneralDataView')} componentProps={props} />
const MyExpertView = props => <Async load={import('../components/myExpert/MyExpertView')} componentProps={props} />
const TargetsView = props => <Async load={import('../components/targets/TargetsView')} componentProps={props} />
const PolicyActivationView = props => <Async load={import('../components/register/PolicyActivationView')} componentProps={props} />
const MiniTestPostChooseView = props => <Async load={import('../components/minitest/MiniTestPostChooseView')} componentProps={props} />

let routes = [
  /*{
    view: Home,
    path: '/',
    route: '/',
  },*/
  {
    view: HomeNew,
    path: '/',
    route: '/',
    themeNew: true,
    showFooter: true,
    showOperationBar: true,
    showRegisterButton: true,
    fullScreen: true
  },
  {
    view: AcademyView,
    path: '/academia',
    route: '/academia',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: GlobalPositionV2View,
    path: '/posicion-global',
    route: '/posicion-global',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: ChangePasswordView,
    path: '/change-password/:publicToken',
    route: '/change-password/:publicToken',
    nextUrl: urls.internal.login,
    nextLabel: 'Fintup.irALogin',
    themeNew: true,
    showOperationBar: true,
  },
  {
    view: ChangePasswordView,
    path: '/change-password',
    route: '/change-password',
    nextUrl: urls.internal.initUrl,
    nextLabel: 'Fintup.irAPosicionGlobal',
    themeNew: true,
    showOperationBar: true,
  },
  {
    view: ReactivateView,
    path: '/reactivate',
    route: '/reactivate',
    themeNew: true,
    showOperationBar: true,
  },
  {
    view: SuitabilityView,
    path: '/suitability'
  },
  {
    view: SuitabilityView,
    path: '/invertir-usuario',
    route: '/invertir-usuario',
    showFooter: false,
    showOperationBar: true,
    fullScreen: true
  },
  {
    view: ContractGeneralDataView,
    path: '/datos-generales-contratacion',
    route: '/datos-generales-contratacion',
    showFooter: false,
    showOperationBar: true,
    fullScreen: true
  },
  {
    view: ContractGeneralDataView,
    path: '/datos-generales-contratacion/:product',
    route: '/datos-generales-contratacion',
    showFooter: false,
    showOperationBar: true,
    fullScreen: true
  },
  {
    view: SuitabilityView,
    path: '/invertir-usuario/:investStep',
    route: '/invertir-usuario/:investStep',
    showFooter: false,
    showOperationBar: true,
    fullScreen: true
  },
  {
    view: SuitabilityView,
    path: '/invest'
  },
  {
    view: MiniTestView,
    path: '/test-de-inversion',
    route: '/test-de-inversion',
    //fullScreen: false
    showFooter: true,
    showOperationBar: false,
    fullScreen: true
  },
  {
    view: ContractGeneralView,
    path: '/contratacion',
    route: '/contratacion',
    showFooter: true,
    showOperationBar: false,
    fullScreen: true,
    themeNew: true,
  },
  {
    view: ContractGeneralView,
    path: '/contratacion/:product',
    route: '/contratacion',
    showFooter: true,
    showOperationBar: true,
    fullScreen: true,
    themeNew: true,
  },
  {
    view: HelpdeskView,
    path: '/helpdesk',
  },
  {
    view: SimulatorView,
    path: '/simulator',
    route: '/simulator',
    showFooter: true,
    showOperationBar: false,
    fullScreen: true
  },
  {
    view: SimulatorView,
    path: '/simulador-usuario',
    route: '/simulador-usuario',
    showFooter: true,
    showOperationBar: false,
    fullScreen: true
  },
  {
    view: SingleSimulatorView,
    path: '/single-simulador'
  },
  {
    view: CardSimulatorView,
    path: '/simulador'
  },
  {
    view: DocumentsView,
    path: '/documents'
  },
  {
    view: SimulatorWidgetView,
    path: '/simulator-widget'
  },
  {
    view: SSOView,
    path: '/sso/:partner/:token'
  },
  {
    view: EmailValidateView,
    path: '/verification-email/:token'
  },
  {
    view: ActivationView,
    path: '/activacion/:token'
  },
  {
    view: AcknowledgmentReceiptView,
    path: '/acuse-recibo/:token'
  },
  {
    view: SignFormView,
    path: '/solicitud-firma/:token',
    route: '/solicitud-firma/:token',
    showFooter: false,
    showOperationBar: false,
    themeNew: true,
    fullScreen: true
    //showOperationBar: false,
    //fullScreen: true
  },
  {
    view: NotificationsNewView,
    path: '/notificaciones',
    route: '/notificaciones',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: IddRequirementsView,
    path: '/forms/:formType'
  },
  {
    view: IddStepView,
    path: '/idoneidad',
    route: '/idoneidad',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: ContributionView,
    path: '/aportacion/:productId/:policyNumber'
  },
  {
    view: TestView,
    path: '/test-report'
  },
  {
    view: OperationDetailView,
    path: '/operation-detail/:operationId',
    route: '/operation-detail',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: NotificationsView,
    path: '/notifications'
  },
  {
    view: IconsView,
    path: '/iconos'
  },
  {
    view: ProfileViewNew,
    path: '/mi-perfil',
    route: '/mi-perfil',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: ProfileViewNew,
    path: '/mi-perfil/:section',
    route: '/mi-perfil',
    showFooter: false,
    showOperationBar: true
  },
  /*{
    view: ProfileViewNew,
    path: '/mi-perfil-nuevo',
    route: '/mi-perfil-nuevo',
    showFooter: false,
    showOperationBar: true
  },*/
  {
    getView: () => ContractViewV2,
    path: '/contratar',
    route: '/contratar',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: PolicyActivationView,
    path: '/activar-poliza',
    route: '/activar-poliza'
  },
  /*{
    view: RegisterView,
    path: '/registrar',
    route: '/registrar'
  },*/
  {
    view: RegisterNewView,
    path: '/invitacion/:invitationCode',
    route: '/invitacion/:invitationCode',
    themeNew: true,
    showFooter: true,
    showOperationBar: true,
    fullScreen: true
  },
  {
    view: RegisterNewView,
    path: '/registro-widget/:registerParam',
    route: '/registro-widget/:registerParam',
    fullScreen: true,
    showFooter: false,
    isWidget: true,
  },
  {
    view: RegisterNewView,
    path: '/registrar',
    route: '/registrar',
    //fullScreen: true,
    //showFooter: false,
    //isWidget: true
    themeNew: true,
    showFooter: true,
    showOperationBar: true,
    showLoginButton: true,
    fullScreen: true
  },
  {
    view: ContractPensionPlanView,
    path: '/contratar/planes-pensiones'
  },
  {
    view: ToolsView,
    path: '/herramientas'
  },
  {
    view: MyExpertView,
    path: urls.internal.expert,
    route: urls.internal.expert,
    showFooter: false,
    showOperationBar: true
  },
  {
    view: MyExpertView,
    path: urls.internal.expert + "/:investStep",
    route: urls.internal.expert + "/:investStep",
  },
  {
    view: TargetsView,
    path: urls.internal.targets,
    route: urls.internal.targets,
    showFooter: false,
    showOperationBar: true
  },
  {
    view: MemberGetMemberView,
    path: urls.internal.invite,
    route: urls.internal.invite,
    showFooter: false,
    showOperationBar: true
  },
  {
    view: DetailsInviteView,
    path: '/detalle-invitaciones',
    route: '/detalle-invitaciones',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: OneMillionView,
    path: '/un-millon',
    route: '/un-millon',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: OneMillionView,
    path: '/no-clientes/herramientas/un-millon',
    route: '/no-clientes/herramientas/un-millon',
    fullScreen: true,
    showFooter: false,
    isWidget: true
  },
  {
    view: CompoundInterestView,
    path: '/interes-compuesto',
    route: '/interes-compuesto',
    showFooter: false,
    showOperationBar: true
  },
  {
    view: CompoundInterestView,
    path: '/no-clientes/herramientas/interes-compuesto',
    route: '/no-clientes/herramientas/interes-compuesto',
    fullScreen: true,
    showFooter: false,
    isWidget: true
  },
  {
    view: MiniTestPostChooseView,
    path: "/elegir-opcion",
    route: "/elegir-opcion",
    //themeNew: true,
    showFooter: false,
    showOperationBar: true
  },
  {
    view: TestView,
    path: "/test-view",
    route: "/test-view",
    //themeNew: true,
    showFooter: false,
    showOperationBar: true
  },
  {
    view: GlobalPositionV2View,
    path: '*'
  }
]

let defaultConfig = {
  fullScreen: false,
  themeNew: false,
  showFooter: true,
  isWidget: false,
  showOperationBar: false,
  showRegisterButton: false,
  oldForm: false
}

let routesConfig = [
  {
    route: '/change-password',
    showFooter: true,
    showOperationBar: false,
    //oldForm: true,
    themeNew: true,
  },
  {
    route: '/herramientas',
    showFooter: false,
    showOperationBar: true
  },
  {
    route: '/aportacion',
    showFooter: false,
    showOperationBar: true
  },
  {
    route: '/trabajando',
    showFooter: false,
    showOperationBar: true
  },
  {
    route: '/suitability',
    fullScreen: true
  },
  {
    route: '/forms/',
    fullScreen: true
  },
  {
    route: '/invest',
    fullScreen: true
  },
  {
    route: '/simulator-widget',
    fullScreen: true,
    showFooter: false
  },
  {
    route: '/simulator',
    fullScreen: true
  },
  {
    route: '/single-simulador',
    fullScreen: true,
    showFooter: false
  },
  {
    route: '/simulador',
    fullScreen: true,
    showFooter: false,
    isWidget: true
  },
  {
    route: '/sso/',
    fullScreen: true
  },
  {
    route: '/emailValidate/',
    fullScreen: true
  }
]

class ConfigRoutesController {
  constructor() {
    this.routes = routes
    browserHistory.listen(location => {
      this.checkRoute(location)
      this.stopStreams()
      LoadingMobx.unsetLoading()
    })
  }

  stopStreams() {
    for (let index in eventPool) {
      let eventItem = eventPool[index]
      eventItem.close()
    }
  }

  getRouteData(pathname) {
    //console.log("getRouteData routes(array)", routes)
    //console.log("getRouteData pathname", pathname)
    let pathNameWithoutParams = pathname.slice(0, pathname.lastIndexOf('/')).length > 0 ? pathname.slice(0, pathname.lastIndexOf('/')) : pathname
    //console.log("getRouteData pathname (wpms) ", pathNameWithoutParams, "--")
    let config = _.find(routes, function (routeObj) {
      //console.log("mira: ", routeObj.route)
      if (routeObj.route) {
        return routeObj.route.indexOf(pathNameWithoutParams) === 0
      }
    })
    //console.log("getRouteData config(1)", config)

    if (!config) {
      config = _.find(routes, function (routeObj) {
        //console.log("mira: ", routeObj.route)
        return pathNameWithoutParams.indexOf(routeObj.route) === 0
      })
      //console.log("getRouteData config(2)", config)
    }

    if (!config) {
      config = _.find(routesConfig, function (routeObj) {
        return pathname.indexOf(routeObj.route) === 0
      })
    }

    return config
  }

  checkRoute(location) {

    if (!location) {
      location = browserHistory.getCurrentLocation()
    }
    var pathname = location.pathname
    //console.log("location: ", location)
    //console.log("PATH NAME: ", pathname)
    let configOfRoute = _.extend(_.cloneDeep(defaultConfig), this.getRouteData(pathname))
    //console.log("configOfRoute: ", configOfRoute)
    this.checkThemeNew(configOfRoute)
    this.checkRegisterButton(configOfRoute)
    this.checkLoginButton(configOfRoute)
    this.checkShowFooter(configOfRoute)
    this.checkFullScreen(configOfRoute)
    this.checkShowOperationBar(configOfRoute)
    this.checkWidget(configOfRoute)
    this.checkOldForm(configOfRoute)
  }

  checkWidget(config) {
    if (SessionController.isWidget !== config.isWidget) {
      SessionController.isWidget = config.isWidget
    }
  }

  checkOldForm(config) {
    if (SessionController.oldForm !== config.oldForm) {
      SessionController.oldForm = config.oldForm
    }
  }

  checkFullScreen(config) {
    if (SessionController.isFullScreen !== config.fullScreen) {
      SessionController.isFullScreen = config.fullScreen
    }
  }

  checkLoginButton(config) {
    if (SessionController.showLoginButton !== config.showLoginButton) {
      SessionController.showLoginButton = config.showLoginButton
    }
  }

  checkRegisterButton(config) {
    if (SessionController.showRegisterButton !== config.showRegisterButton) {
      SessionController.showRegisterButton = config.showRegisterButton
    }
  }

  checkShowFooter(config) {
    if (SessionController.showFooter !== config.showFooter) {
      SessionController.showFooter = config.showFooter
    }
  }

  checkThemeNew(config) {
    if (SessionController.themeNew !== config.themeNew) {
      SessionController.themeNew = config.themeNew
    }
  }

  checkShowOperationBar(config) {
    if (SessionController.showOperationBar !== config.showOperationBar) {
      SessionController.showOperationBar = config.showOperationBar
    }
  }
}

var ConfigRoutes = new ConfigRoutesController()
export default ConfigRoutes
