import React from 'react'
import FintupComponent from '../FintupComponent'
import './MiniTestStep0.scss'
import constans from '../../utils/constants'
import { FormattedMessage } from 'react-intl'
import FintupButtonIconSelectable from '../common/FintupButtonIconSelectable'

class MiniTestStep0 extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("PROPS MINITESTSTEP0", props)
    this.state = {
      actualStep: 1,
      objective: 2,
      objectiveText: 'miniTestSteps.key2'
    }
  }

  doStart (objective) {
    let step, objectiveText;
    //Actual page = 0
    step = 2;
    objectiveText = `miniTestSteps.key${objective}`

    this.setStatePromise({'objective': objective})
    this.props.updateModel({
      ...this,
      objective: objective,
      objectiveText: objectiveText
    })
    this.props.goToStep(step)
    //this.captcha.execute()
  }

  render(){
    return (
      <div className="miniTestStep0">
        <div className="content text-center">
          <div className="row">
            <h3><FormattedMessage id="miniTestStep0.title" /></h3>
            {/*<span className="subtitle-text"><FormattedMessage id="miniTestStep0.text1" /></span>*/}
          </div>
          <div className="row buttons">
            <div className="elements col-md-4 col-xs-6">
              <FintupButtonIconSelectable className="containerButtonIconSelectable" iconImage="/img/minitest/largo-plazo.svg" classNameIcon="iconButtonIconSelectable"
                textKey="miniTestStep0.button2" classNameText="textButtonIconSelectable"
                size={constans.BUTTON_SIZE.XXLARGE} action={() => this.doStart(5)} />
            </div>
            <div className="elements col-md-4 col-xs-6">
              <FintupButtonIconSelectable className="containerButtonIconSelectable" iconImage="/img/minitest/ahorro-proyecto.svg" classNameIcon="iconButtonIconSelectable"
                textKey="miniTestStep0.button3" classNameText="textButtonIconSelectable"
                size={constans.BUTTON_SIZE.XXLARGE} action={() => this.doStart(2)} />
            </div>
            <div className="elements col-md-4 col-xs-12">
              <FintupButtonIconSelectable className="containerButtonIconSelectable" iconImage="/img/minitest/preservar-patrimonio.svg" classNameIcon="iconButtonIconSelectable"
                textKey="miniTestStep0.button1" classNameText="textButtonIconSelectable"
                size={constans.BUTTON_SIZE.XXLARGE} action={() => this.doStart(3)} />
            </div> 
          </div>
          <div className="row estimated-time">
            <img src="/img/minitest/clock.svg" alt={(<FormattedMessage id="miniTestStep0.text2"/>)}></img>
            <span><FormattedMessage id="miniTestStep0.text2"/></span>
          </div>
        </div>
      </div>
    )

    //<FintupButton fintupIcon="flecha-seleccionable-derecha" iconRight={true} textKey="button.common.next" className="buttonContainer" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.XXLARGE} action={this.doStart.bind(this)} />
    //<Captcha ref={ref => (this.captcha = ref)} success={this.props.nextStep} />

  }
}

export default MiniTestStep0