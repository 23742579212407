import React, { Component } from 'react'
import transverser from '../../../utils/transverser'
import utils from '../../../utils/utils'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import DataTransfer from 'fbjs/lib/DataTransfer'
import classNames from 'classnames'
import './InputTextLabel.scss'
import FintupIcon from '../FintupIcon'
import _ from 'lodash'
/**
 * addonControl: Para mostrar los iconos de aceptar cancelar dentro del input
 * append Text para añadir al input como append text
 * disabled: Deshabilita el componente
 * innerInput: Indica que es un input de tipo inner, tiene dentro del input el nombre del campo como en la pagina de mi perfil
 * innerLabelWidth: Indica el ancho del label cuando es un input de tipo inner se pone el ancho en pixeles sin el px
 * labelSize: Se utiliza cuando es horizontal para definir el espacio del label
 * leftAction: Accion que se lanza al hacer click sobre el addonIzquierdo en caso de existir
 * maxLength: Longitud maxima del input
 * pasteTransform: Funcion que al pegar transforma el dato pegado
 * readOnly: Pone el input como readOnly
 * rightAddonAction: Accion que se lanza sobre el addon derecho en cas de existir
 * value: Valor que se le pasa al input
 * type: Indica el tipo del input, si se indica intNumber se crea un input number que no permite introducir decimales
 * className: Clases adicionales del componente, en caso de pasar underLineControl se muestra un input de tipo underLine (membergetmember)
 * theme: 'new'
 *  * 'new': Nuevo estilo de componentes similar al diseño minitest
 */
class InputTextLabel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props,
      value: transverser.get('value', '')(this.props)
    }
  }

  handlePaste (ev) {
    let data = new DataTransfer(ev.clipboardData)
    let text = data.getText()
    if (this.props.pasteTransform) {
      ev.preventDefault()
      text = this.props.pasteTransform(text)
      let newEvent = {
        target: {
          value: text,
          name: this.props.name
        }
      }
      this.handleChange(newEvent)
    }
  }

  handleChange (ev) {
    let fieldValue = ev.target.value
    //console.log("inputType: ", this.inputType)
    //console.log("max length: ", this.props.maxLength)
    if (this.inputType === 'number' && this.props.maxLength) {
      let valueString = fieldValue ? `${fieldValue}` : '0'
      if (valueString.length > parseInt(this.props.maxLength, 10)) {
        return
      }
    }

    this.setState({ value: fieldValue })
    if (this.props.callBackOnChange) {
      let data = {
        ev: ev,
        name: this.props.name,
        value: fieldValue
      }
      this.props.callBackOnChange(data)
    }
  }

  handleKeyPress (event) {
    if (this.props.type === 'intNumber') {
      if (event.charCode === 44 || event.charCode === 46) {
        event.stopPropagation()
        event.preventDefault()
        return false
      }
    }
    return true
  }

  get labelElementClasses () {
    return classNames(
      this.props.horizontal ? `col-sm-${this.props.labelSize} col-form-label ` : '',
      this.props.htmlLabel ? 'htmlLabel' : '',
      this.props.showDivider ? 'dividerLabel' : '',
      this.props.rightLabel ? 'rightLabel' : '',
      'newStyleGrid'
    )
  }

  get inputSizeClasses () {
    let labelSize = this.props.horizontal ? parseInt(this.props.labelSize, 10) : 0
    let inputSize = this.props.inputSize || 12 - labelSize
    return classNames(
      `col-sm-${inputSize}`,
      'newStyleGrid',
      {'paddingRight15': this.props.showDivider}
    )
  }

  get inputClasses () {
    return classNames('form-control', this.props.inputClassName, {'fLeft': this.props.append})
  }

  get containerClasses () {
    return classNames(
      'inputTextLabel',
      {[`theme-${this.props.theme}`]: this.props.theme},
      this.props.className,
      {'innerInput': this.props.innerInput},
      {'is-error': this.props.isError}
    )
  }

  get legend () {
    let legendClassContainer = classNames('full-width', this.props.leftLegend ? '' : 'text-right')
    let legend = this.props.legendTextKey ? (
      <div className={legendClassContainer}>
        <small className="form-text text-muted inputLegendLink" role="link" tabIndex="0" onClick={this.props.legendAction} onKeyPress={utils.noop}><FormattedMessage id={this.props.legendTextKey} /></small>
      </div>
    ) : ''
    return legend
  }

  get inputContainerClasses () {
    let showAddon = transverser.get('addonControl', false)(this.props)
    return classNames(
      'inputContainer',
      {'inner-addon right-addon': showAddon}
    )
  }

  get addonContent () {
    if (this.props.addonControl) {
      return (
        <span className="addonContent">
          <FintupIcon icon="check" color="okColor" onClick={this.leftAddonAction.bind(this)}/>
          <FintupIcon icon="cancel" color="cancelColor" onClick={this.rightAddonAction.bind(this)}/>
        </span>
      )
    } else {
      return ''
    }
  }

  leftAddonAction () {
    if (this.props.leftAction) {
      this.props.leftAction(this.state.value)
    }
  }

  rightAddonAction () {
    if (this.props.rightAction) {
      this.props.rightAction(this.state.value)
    }
  }

  get inputStyles () {
    let styles = {}
    if (this.props.innerLabelWidth) {
      styles = _.merge(styles, {paddingLeft: `${this.props.innerLabelWidth + 5}px`})
    }
    return styles
  }

  get labelStyles () {
    let styles = {}
    if (this.props.innerLabelWidth) {
      styles = _.merge(styles, {width: `${this.props.innerLabelWidth}px`})
    }
    return styles
  }

  get inputValue () {
    if (this.inputType === 'number') {
      return this.props.value || ''
    } else {
      return this.props.value
    }
  }

  get inputType () {
    if (this.props.type === 'intNumber') return 'number'
    return this.props.type || 'text'
  }

  inputElement () {
    let placeHolderValues = this.props.placeholderValues || {}
    let placeHolder = this.props.placeholder
      ? this.props.intl.formatMessage({id: this.props.placeholder}, placeHolderValues)
      : ''
    let inputElement = (
      <div className={this.inputContainerClasses}>
        {this.addonContent}
        <input
          style={this.inputStyles}
          disabled={this.props.disabled}
          type={this.inputType}
          onKeyDown={this.props.onkeydown}
          maxLength={this.props.maxLength}
          readOnly={this.props.readOnly}
          className={this.inputClasses}
          id={this.props.name}
          name={this.props.name}
          min={this.props.min}
          max={this.props.max}
          placeholder={placeHolder}
          value={this.inputValue}
          onPaste={this.handlePaste.bind(this)}
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
        />
        {this.appendContent}
        {this.errorContent}
      </div>
    )
    return inputElement
  }

  get appendContent () {
    return this.props.append
      ? (
        <span className="appendText">{this.props.append}</span>
      )
      : ''
  }

  get errorContent () {
    return this.props.isError
      ? <span className="errorText">
          {this.props.errorText ? this.props.errorText : <FormattedMessage id={this.props.errorTextKey} />}
        </span>
      : ''
  }

  requiredElement () {
    let requiredElement = this.props.required ? <span className="required-label">*</span> : ''
    return requiredElement
  }

  renderInnerInput () {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.labelElement}
          {this.inputElement()}
        </div>
      </div>
    )
  }

  horizontarRender () {
    return (
      <div className="form-group row">
        {this.labelElement}
        <div className={this.inputSizeClasses}>
          {this.inputElement()}
        </div>
      </div>
    )
  }

  horizontarRightRender () {
    return (
      <div className="form-group row">
        <div className={this.inputSizeClasses}>
          {this.inputElement()}
        </div>
        {this.labelElement}
      </div>
    )
  }

  verticalRender () {
    return (
      <div className="form-group">
        {this.labelElement}
        {this.requiredElement()}
        {this.inputElement()}
        {this.legend}
      </div>
    )
  }

  get labelElement () {
    let labelText = this.props.label
      ? this.props.htmlLabel
        ? <FormattedHTMLMessage id = { this.props.label } />
        : <FormattedMessage id={this.props.label} />
      : ''
    let labelElement = this.props.label ? <label style={this.labelStyles}className={this.labelElementClasses} htmlFor={this.props.name}>{labelText}</label> : ''
    return labelElement
  }

  get renderTemplate () {
    let horizontarlInput = this.props.horizontal
    return this.props.innerInput
      ? this.renderInnerInput()
      : horizontarlInput
        ? this.props.rightLabel
          ? this.horizontarRightRender()
          : this.horizontarRender()
        : this.verticalRender()
  }

  render () {
    return (
      <div className={this.containerClasses}>
        {this.renderTemplate}
      </div>
    )
  }
}

export default injectIntl(InputTextLabel)
