import React from 'react'
import FintupComponent from "../FintupComponent";
import { injectIntl, FormattedMessage } from "react-intl";
import FintupIcon from '../common/FintupIcon';
import './ContractTabs.scss'
import classNames from 'classnames'
import transverser from '../../utils/transverser';

/**
 * - blockNextItems: (true or false): Block the posibility of click to next items
 */
class ContractTabs extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      values: props.values.props,
      //props: props.values.props
      //values: props.values.props,
      //hrefInvest: urls.external.operationBar.date,
    }
  }

  /**
   * Check if we can access to the specific page
   * If the main field has value
   * @param {number} tab 
   */
  clickItem(tab){
    let valuePage
    let page

    console.log("state values: ", this.state.values)
    //console.log("page: ", page)
    switch(tab){
      case 1:
      default:
        page = 1
        valuePage = transverser.get('model.step0', '-')(this.state.values)
        break;
      case 2:
        page = 2
        valuePage = transverser.get('model.step3', '-')(this.state.values)
        break;
      case 3: 
        page = 5
        valuePage = transverser.get('model.step4', '-')(this.state.values)
        break;
      case 4: 
        page = 6
        valuePage = transverser.get('model.step5', '-')(this.state.values)
        break;
    }
    /*console.log("value page? ", valuePage)
    console.log("ACTUAL STEP: ", this.props.actualStep)
    console.log("PINCHO EN tab: ", tab)
    console.log("go to: ", page)
    console.log("ESTOY EN : ", transverser.get('model.state.actualStep', '-')(this.state.values))*/
    //Check if block items is active
    if((this.props.blockNextItems && page <= this.props.actualStep || !this.props.blockNextItems)
      && valuePage !== "-")
      this.state.values.goToStep(page)
  }

  breadcrumb(element, elementActive){
    let classNameBread = ''
    let icon = ''
    if(element == elementActive){
      classNameBread = 'active'
      icon = <FintupIcon size="10" icon="flecha-seleccionable-abajo"/>
    }
    else if(element > elementActive){
      classNameBread = 'next'
      icon = <FintupIcon size="12" icon="flecha-seleccionable-derecha"/>
    }
    else{ //less than
      classNameBread = 'previous'
      icon =  <span className="icon-wrapper"><i className="fa fa-check"></i></span>
    } 

    return (
      <div className={classNames("col-xs-3", "bread", classNameBread)} onClick={() => this.clickItem(element)}>
        <div className="crumb">
          <FormattedMessage id={`contractTabs.${element}`}/>
          {icon}
        </div>
      </div>
    )
  }

  get title(){
    return this.props.titleKey ? this.props.intl.formatMessage({ id: this.props.titleKey }) : this.props.title ? this.props.title : ''
  }

  render(){
    return (
      <div className="contractTabs">
        <div className="container">
          <div className="title">
            <h1>{this.title}</h1>
          </div>
          <div className="row breadcrumbBlock">
            {this.breadcrumb(1, this.props.elementActive)}
            {this.breadcrumb(2, this.props.elementActive)}
            {this.breadcrumb(3, this.props.elementActive)}
            {this.breadcrumb(4, this.props.elementActive)}
          </div>
        </div>
      </div>
    )
  }
}

export default injectIntl(ContractTabs)