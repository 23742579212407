import React from 'react'
import { FormattedMessage } from 'react-intl'
import FintupComponent from '../FintupComponent'
import ModalHeader from '../../components/common/modal/ModalHeader'
import ModalBody from '../../components/common/modal/ModalBody'
import Modal from '../../components/common/modal/Modal'
import Iframe from 'react-iframe'
import './Terms.scss'
import { observer } from 'mobx-react'
import TermsModel from './TermsModel'

class Terms extends FintupComponent {
  constructor (props) {
    super(props)
    TermsModel.reset()
    this.state = {
    }
  }

  close () {
    TermsModel.close()
  }

  get termsPage () {
    return this.props.termsPage || '/pages/terms.html'
  }

  render () {
    let modalHeader = <ModalHeader clickClose={this.close.bind(this)}>
      <FormattedMessage id="suitabilityStep5.terms" />
    </ModalHeader>
    return (
      <Modal className="terms" showModal={TermsModel.show} modalHeader={modalHeader}>
        <ModalBody>
          <Iframe url={this.termsPage} position="relative" height="450px" />
        </ModalBody>
      </Modal>
    )
  }
}

export default observer(Terms)
