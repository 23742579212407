import React, { Component } from 'react'
import Overlay from '../../../components/common/modal/Overlay'
import classNames from 'classnames'
import './Modal.scss'
import ModalVideo from 'react-modal-video'

/**
 * showModal (boolean)
 * modalHeader (ModalHeader component)
 * isAbsoluteModal (boolean)
 * isFullPage (boolean) - 80% screen page - Full page in mobile.
 * isOverlay (boolean)
 * clickOverlay (event)
 */
class Modal extends Component {
  constructor () {
    super()
    this.state = {}
  }

  fixedBody(){
    //Elements that isn't showing
    let elements = document.querySelectorAll('.in.modal-block, .modal-video');
    //If exists some modals in page, it's necessary to ensure that there is some active modal
    if(!this.props.showModal && elements.length > 0)
      document.querySelector('body').style.overflow = 'initial';
    //Disable scroll body
    else if(this.props.showModal)
      document.querySelector('body').style.overflow = 'hidden';
  }

  render () {
    let showModalClasses = this.props.showModal ? 'in modal-block' : ''
    let absoluteModalClasses = this.props.isAbsoluteModal ? 'absoluteModal' : ''
    let fullModalClasses = this.props.isFullPage ? 'full' : ''
    let isShowOverlay = this.props.isOverlay ? <Overlay clickOverlay={this.props.clickOverlay}/> : ''
    let modalClasNames = classNames('modal fade', showModalClasses, absoluteModalClasses, fullModalClasses, this.props.className)
    this.fixedBody()

    return this.props.isVideo ? 
      (
        <ModalVideo channel='youtube' isOpen={this.props.showModal} videoId={this.props.idVideo} onClose={this.props.clickCloseVideo} />
      )
    : (
      <div className={modalClasNames}>
        {isShowOverlay}
        <div className="modal-dialog">
          <div className="modal-content">
            {this.props.modalHeader}
            {this.props.children}
          </div>
        </div>
      </div>)
  }
}

export default Modal
