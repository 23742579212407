import React, { Component } from 'react'
import { FormattedHTMLMessage } from 'react-intl'
import FintupButton from '../common/forms/FintupButton'
import Cookies from 'js-cookie'
import constants from '../../utils/constants'
import urls from '../../utils/urls'
import './CookiesAdvise.scss'

class CookiesAdvise extends Component {
  componentWillMount () {
    let actualState = Cookies.get(constants.COOKIE.TERMS)
    this.setState({ hideCookies: actualState })
  }

  acceptTerms () {
    Cookies.set(constants.COOKIE.TERMS, true, { expires: constants.COOKIE.TERMS_EXPIRED_DAYS })
    this.setState({ hideCookies: true })
  }

  render () {
    let state = this.state.hideCookies
    let content = <div></div>
    if (!state) {
      content = (
        <div className="cookiesAdvise row">
          <div className="col-sm-10 col-xs-12">
            <FormattedHTMLMessage
              id="CookiesAdvise.text"
              values={{link: urls.external.headerAndFooter.legal.cookies}}/>
          </div>
          <div className="col-sm-2 col-xs-12 cookiesAdviseButton">
            <FintupButton textKey="CookiesAdvise.button" buttonClassName="btn-l btn-bold" action={this.acceptTerms.bind(this)} />
          </div>
        </div>
      )
    }
    return (
      <section>
        {content}
      </section>
    )
  }
}

export default CookiesAdvise
