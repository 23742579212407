import React, { Component } from 'react'
import { FormattedMessage, injectIntl } from 'react-intl'
import classNames from 'classnames'
import constants from '../../../utils/constants'
import FontAwesome from 'react-fontawesome'
import Spinner from 'react-spinkit'
import './FintupButton.scss'
import FintupIcon from '../FintupIcon'

/*
  Props:
    action: evento onclick del componente
    buttonClassName clases que se añaden al elemento boton
    className: clases que se añaden al componente
    icon: Icon fontAwesome a mostrar junto el boton
    fintupIcon: Icono fintup a mostrar junto el boton
    iconImage: El icono se debe representar como una imagen <img>
    iconRight: Situa el icono de fintup a la derecha en lugar de a la izquierda
    isLoading: Pone un loading en el boton y deshabilita la accion sobre el mismo
    size: Tamaño del boton
      Si se especifica ONLY_ICON el boton no tendrá texto

*/
class FintupButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      props: props
    }
  }

  doClick(ev) {
    if (!this.props.isLoading && this.props.action) {
      this.props.action(ev)
    }
  }

  get sizeClass() {
    switch (this.props.size) {
      case constants.BUTTON_SIZE.LARGE:
        return 'btn-l'
      case constants.BUTTON_SIZE.DOUBLE_LARGE:
        return 'btn-2l'
      case constants.BUTTON_SIZE.XLARGE:
        return 'btn-xl'
      case constants.BUTTON_SIZE.XXLARGE:
        return 'btn-xxl'
      case constants.BUTTON_SIZE.XXXLARGE:
        return 'btn-xxxl'
      case constants.BUTTON_SIZE.FULL:
        return 'btn-full'
      case constants.BUTTON_SIZE.SMALL:
        return 'btn-small'
      case constants.BUTTON_SIZE.DOUBLE_SMALL:
        return 'btn-2s'
      case constants.BUTTON_SIZE.HALF_DOUBLE_SMALL:
        return 'btn-2halfs'
      case constants.BUTTON_SIZE.FREE:
        return ''
      case constants.BUTTON_SIZE.ONLY_ICON:
        return 'btn-only-icon'
      default:
        return 'btn-m'
    }
  }

  get loadingButtonClass() {
    return this.props.isLoading ? 'btn-loading' : ''
  }

  get buttonType() {
    return this.props.type || 'button'
  }

  get disabledState() {
    return this.props.disabled ? 'disabled' : ''
  }

  get buttonContent() {
    let content
    if (this.props.icon) {
      content = (<div><FontAwesome name={this.props.icon} className="marginRight10" /><FormattedMessage id={this.props.textKey} /></div>)
    } else if (this.props.size === constants.BUTTON_SIZE.ONLY_ICON) {
      if (this.props.iconImage) {
        content = (<img src={this.props.iconImage} className={this.props.classNameIcon} />)
      }
      else {
        content = <div><FintupIcon icon={this.props.fintupIcon} /></div>
      }
    } else if (this.props.iconImage) {
      content = (<div><img src={this.props.iconImage} className={this.props.classNameIcon} alt={(<FormattedMessage id={this.props.textKey} />)} /><p className={this.props.classNameText}><FormattedMessage id={this.props.textKey} /></p></div>)
    } else if (this.props.iconImageWithWrapper) {
      content = (<div><div className="wrapperIcon"><img src={this.props.iconImageWithWrapper} className={this.props.classNameIcon} alt={(<FormattedMessage id={this.props.textKey} />)} /></div><p className={this.props.classNameText}><FormattedMessage id={this.props.textKey} /></p></div>)
    }
    else if (this.props.fintupIcon && this.props.iconRight) {
      content = (<div><FormattedMessage id={this.props.textKey} /><FintupIcon icon={this.props.fintupIcon} className="marginLeft15" /></div>)
    } else if (this.props.fintupIcon) {
      content = (<div><FintupIcon icon={this.props.fintupIcon} className="marginRight10" /><FormattedMessage id={this.props.textKey} /></div>)
    } else {
      content = this.props.textKey
        ? <FormattedMessage id={this.props.textKey} />
        : this.props.text
    }
    return content
  }

  render() {
    let containerClass = classNames('fintupButton', this.props.className, this.loadingButtonClass)
    let buttonClasses = classNames('btn btn-fintup', this.props.buttonClassName, this.sizeClass)
    let buttonContent = this.props.isLoading ? <Spinner name='circle' fadeIn='none' /> : this.buttonContent
    return (
      <div className={containerClass}>
        <button type={this.buttonType} className={buttonClasses} disabled={this.disabledState} onClick={this.doClick.bind(this)}>
          {buttonContent}
        </button>
      </div>
    )
  }
}

export default injectIntl(FintupButton)
