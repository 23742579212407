import React from 'react'
import { Helmet } from 'react-helmet'
import FintupComponent from '../FintupComponent'
import config from '../../config/config'
import { injectIntl } from 'react-intl'

/**
 * PROPS: 
 *  - title: Title of page
 *  - mainColor: Main color
 *  - children: Posibility added specific extra meta tags
 */
class HeaderTags extends FintupComponent {

  /**
   * Example: Name of the page
   */
  get titleSimple(){
    return this.props.title ? this.props.intl.formatMessage({'id': this.props.title}) : ''
  }

  /**
   * Concat name app + name of the page
   * Example: App Fintup: Name of the page
   * Example if name is empty: App Fintup
   */
  get title(){
    return config.nameApp ? (config.nameApp + (this.titleSimple != '' ? ': '+ this.titleSimple : '')) : 'Fintup'
  }

  get titleArticleTag(){
    return this.props.titleArticleTag ? this.props.intl.formatMessage({'id': this.props.titleArticleTag}) : this.titleSimple
  }

  get authorName(){
    return config.companyName || 'Fintup'
  }

  get description () {
    return this.props.intl.formatMessage({'id': this.props.description ? this.props.description : 'headerTags.description'})
  }

  get mainColor () {
    return this.props.mainColor || config.mainColor || '#00748C'
  }

  get canonicalUrl(){
    return config.homeAppUrl + window.location.pathname
  }

  get noIndex(){
    return this.props.noIndex ? <meta name="robots" content="noindex" /> : ''
  }

  render () {
    return (
      <Helmet htmlAttributes={{ lang: 'es' }}>
        <meta name="theme-color" content={this.mainColor} />
        <title>{this.title}</title>
        {this.noIndex}
        <meta name="Description" content={this.description} />
        <meta name="Author" content={this.authorName} />
        <meta name="lang" content="es" />
        <link rel="canonical" href={this.canonicalUrl}/>
        <meta property="og:title" content={this.title}/>
        <meta property="og:type" content="article"/>
        <meta property="og:description" content={this.description}/>
        <meta property="og:url" content={this.canonicalUrl}/>
        <meta property="og:site_name" content={config.metaSitename}/>
        <meta property="og:image" content={config.metaImage}/>
        <meta property="article:publisher" content={config.metaFacebookSite} />
        <meta property="article:section" content={this.authorName} />
        <meta property="article:tag" content={this.titleArticleTag} />
        <meta property="article:tag" content="App" />
        <meta property="article:tag" content="Dinero" />
        <meta property="article:tag" content="Inversión" />
        <meta property="article:tag" content="Planes de pensiones" />
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:site" content={config.metaTwitterSite}/>
        {this.props.children}
      </Helmet>
    )
  }
}

export default injectIntl(HeaderTags)