import React from 'react'
import './MiniTestProductLanding.scss'
import FintupComponent from '../FintupComponent'
import transverser, { tGet } from '../../utils/transverser'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'
import { FormattedMessage, injectIntl, FormattedHTMLMessage } from 'react-intl'
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext, DotGroup } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import constans from '../../utils/constants'
import utils from '../../utils/utils'
import navUtils from '../../core/navUtils'
import urls from '../../utils/urls'
import Modal from '../../components/common/modal/Modal'
import ModalBody from '../common/modal/ModalBody'
import ModalHeader from '../common/modal/ModalHeader'
import config from '../../config/config'
import OpenerModal from '../common/modal/OpenerModal'
import ManagerImage from '../suitability/ManagerImage'
import _ from 'lodash'
import MiniTestTabs from './MiniTestTabs'
import { getPortfolioRecommendableDataByProductAndRiskType, getListInsuranceCosts, getInsuranceCostByAge } from '../../utils/fintup-api'
import LoadingApp from '../common/LoadingApp'
import InputTextLabel from '../common/forms/InputTextLabel'
import RadioButton from '../common/forms/RadioButton'
import Alert from '../common/Alert'
import RSVP from 'rsvp'
import { postMinitestReport } from '../../utils/fintup-api'

class MiniTestProductLanding extends FintupComponent {
  constructor(props) {
    super(props)
    console.log("PROPS MiniTestProductLanding", props)

    this.state = {
      actualStep: 6,
      isLoaded: true,
      isMobileDevice: utils.isMobileDevice(),
      values: props,//{model: {typeInvestor: 2}},//
      portfolio: {},
      listInsuranceCosts: [],
      insuranceCostDefaultAge: 34,
      insuranceCostDefault: {},
      advantages: [
        {
          title: "miniTestProductLanding.advantages.title1",
          checks: [
            {
              text: "miniTestProductLanding.advantages.text1",
              textOpenModal: "miniTestProductLanding.advantages.text1.1",
              modal: true,
              active: true,
              refModal: 'showModalRiskProfile'
            },
            {
              text: "miniTestProductLanding.advantages.text2",
              textOpenModal: "miniTestProductLanding.advantages.text2.1",
              modal: true,
              active: true,
              refModal: 'showModalPlanificationPersonal'
            },
            {
              text: "miniTestProductLanding.advantages.text3",
              textOpenModal: "miniTestProductLanding.advantages.text3.1",
              modal: true,
              active: true,
              refModal: 'showModalExpert'
            },
            {
              text: "miniTestProductLanding.advantages.text4",
              textOpenModal: "miniTestProductLanding.advantages.text4.1",
              modal: true,
              active: true,
              refModal: 'showModalLowPrices'
            },
            {
              text: "",
              textOpenModal: "miniTestProductLanding.advantages.text5.1",
              modal: true,
              active: true,
              refModal: 'showModalCredibilityTrust'
            },
          ]
        },
      ],
      comparator: [
        {
          title: "miniTestProductLanding.comparator.title1",
          checks: [
            {
              text: "miniTestProductLanding.comparator.text1",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text2",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text3",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text4",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text5",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text6",
              active: true
            },
          ],
          active: true
        },
        {
          title: "miniTestProductLanding.comparator.title2",
          checks: [
            {
              text: "miniTestProductLanding.comparator.text1",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text2",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text3",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text4",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text5",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text6",
              active: true
            },
          ],
          active: false
        },
        {
          title: "miniTestProductLanding.comparator.title3",
          checks: [
            {
              text: "miniTestProductLanding.comparator.text1",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text2",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text3",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text4",
              active: true
            },
            {
              text: "miniTestProductLanding.comparator.text5",
              active: false
            },
            {
              text: "miniTestProductLanding.comparator.text6",
              active: true
            },
          ],
          active: false
        }
      ],
      dataPage: {},
      heightSlider: 'auto',
      hrefDate: urls.external.operationBar.date,
      hrefInvest: urls.external.operationBar.invest,
      hrefGoogleReviews: urls.external.operationBar.reviews,
      reportNameErrorText: '',
      reportEmailErrorText: '',
      //Slides
      showModalRentability: false,
      showModalDistribution: false,
      //Advantages
      showModalRiskProfile: false,
      showModalPlanificationPersonal: false,
      showModalExpert: false,
      showModalLowPrices: false,
      showModalCredibilityTrust: false,
      showModalCostsList: false,
      showVideoWidget: false,
      showModalVideoWidget: false,
      showModalReport: false,
      dataGeneral: {}, //data for reporting (for send to backend)
      dataRentability: {}, //calculated data for reporting (for send to backend)
    }
    setTimeout(() => {
      this.setStatePromise({ showVideoWidget: true })
    }, 5000)
  }

  componentDidMount() {
    //GET PORTFOLIO by product (gets from step 2) and risk type (selected step 5)
    console.log("DATOS RECIBIDOS MINI TEST PRODUCT LANDING", this.state.values.model)
    let productId = transverser.get('model.productData.id')(this.state.values)
    let typeInvestor = transverser.get('model.typeInvestor')(this.state.values)
    getPortfolioRecommendableDataByProductAndRiskType(productId, typeInvestor)
      .then(response => {
        console.log("RECOMMENDED DATA PORTFOLIO: ", response.data.portfolio)
        //First, is to neccesary get data porfolio before load dataPage and isLoaded flag
        return this.setStatePromise({ portfolio: response.data.portfolio })
      })
      .then(() => {
        return getListInsuranceCosts()
      }).then((response) => {
        console.log("RESPONSE LIST INSURANCE COSTS", response)
        return this.setStatePromise({ listInsuranceCosts: response.data })
      }).then(() => {
        return getInsuranceCostByAge(this.state.insuranceCostDefaultAge)
      })
      .then((response) => {
        console.log("INSURANCE COST DEFAULT: ", response)
        return this.setStatePromise({ insuranceCostDefault: response.data })
      })
      .then(() => {
        return this.setStatePromise({ dataPage: this.getDataPageByDataMiniTest(), isLoaded: false })
      }).then(() => {
        //Dot group slider add event listener on click (limitation API pure-react-carousel)
        var dotGroupElement = document.querySelector(".carousel__dot-group");
        dotGroupElement.addEventListener("click", this.onEventSlider.bind(this))
        this.changeHeightContainerSlider()
        window.addEventListener("resize", this.resize.bind(this))
      })

  }

  resize() {
    this.setStatePromise({ 'isMobileDevice': utils.isMobileDevice() })
  }

  invest() {
    console.log("invest")
    //navUtils.openWindow(this.state.hrefDate)

    this.state.values.goToStep(7)
  }

  date() {
    console.log("date")
    navUtils.openWindow(this.state.hrefDate)
  }

  hideVideoWidget(ev) {
    ev.preventDefault()
    ev.stopPropagation()
    this.setStatePromise({ showVideoWidget: false })
  }

  hideModals() {
    this.setStatePromise({
      showModalRentability: false,
      showModalDistribution: false,
      showModalRiskProfile: false,
      showModalPlanificationPersonal: false,
      showModalExpert: false,
      showModalLowPrices: false,
      showModalCredibilityTrust: false,
      showModalCostsList: false,
      showModalVideoWidget: false,
      showModalReport: false
    })
  }

  modalGetHeader(value) {
    return (
      <ModalHeader clickClose={this.hideModals.bind(this)}>
        <FormattedMessage id={value} />
      </ModalHeader>
    )
  }

  showModalEv(ev, value) {
    ev.preventDefault()
    var obj = {}
    obj[value] = true
    this.setStatePromise(obj)
  }

  calculateData(years, cInitial, cMonthly, typeInvestor) {
    let percentageRentability = 0
    percentageRentability = this.state.portfolio.profitabilityPercentage
    let result = (cInitial * Math.pow(1 + percentageRentability, years)) +
      ((12 * cMonthly) * (Math.pow(1 + percentageRentability, years) - 1) / percentageRentability)
    let arrResultByYears = this.resultByYears(cInitial, cMonthly, years, percentageRentability)
    let savedMoneyNet = cInitial + cMonthly * 12 * years;
    let rentabilityMoneyNet = result - savedMoneyNet
    let rentabilityAccumulatedNetPercent = rentabilityMoneyNet / savedMoneyNet
    let rentabiltyPerEuro = result / savedMoneyNet
    let moneyDuplicateTimes = Math.pow(1 + percentageRentability, years)//years / (Math.log(2) / Math.log(1+percentageRentability))
    let productCommissionManagement = transverser.get('model.productData.commissionManagement')(this.state.values)
    let portfolioCommissionUnderlyingCalculated = this.state.portfolio.commissionUnderlyingCalculated;
    let sumCommissions = productCommissionManagement + portfolioCommissionUnderlyingCalculated

    console.log("ARR RESULT BY YEARS: ", arrResultByYears)
    let dataRentability = {
      totalContributions: years * 12,
      rentabilityPercent: percentageRentability,
      rentabilityPercentFormat: utils.formatPorcentage100(percentageRentability, 2),
      savedMoneyTotal: result,
      savedMoneyNet: savedMoneyNet,
      rentabilityMoneyNet: rentabilityMoneyNet,
      rentabilityAccumulatedNetPercent: rentabilityAccumulatedNetPercent,
      rentabilityAccumulatedNetPercentFormat: utils.formatPorcentage100(rentabilityAccumulatedNetPercent, 2),
      rentabilityPerEuro: rentabiltyPerEuro,
      moneyDuplicateTimes: moneyDuplicateTimes,
      productCommissionManagement: utils.formatPorcentage100(productCommissionManagement, 2),
      portfolioCommissionUnderlyingCalculated: utils.formatPorcentage100(portfolioCommissionUnderlyingCalculated, 2),
      sumCommissions: utils.formatPorcentage100(sumCommissions, 2),
      rentability: arrResultByYears
    }
    this.setStatePromise({ dataRentability: dataRentability })
    return dataRentability;
  }

  resultByYears(cInitial, cMonthly, years, percentageRentability) {
    let arrResponse = []

    for (let i = 1; i <= years; i++) {
      let result = (cInitial * Math.pow(1 + percentageRentability, i)) +
        ((12 * cMonthly) * (Math.pow(1 + percentageRentability, i) - 1) / percentageRentability)
      let savedMoneyNet = cInitial + cMonthly * 12 * i
      let rentabilityMoneyNet = result - savedMoneyNet
      let rentabilityAccumulatedNetPercent = rentabilityMoneyNet / savedMoneyNet
      let rentabiltyPerEuro = result / savedMoneyNet
      let moneyDuplicateTimes = Math.pow(1 + percentageRentability, i)
      let object = {
        result: result,
        year: i,
        savedMoneyNet: savedMoneyNet,
        rentabilityMoneyNet: rentabilityMoneyNet,
        rentabilityAccumulatedNetPercent: rentabilityAccumulatedNetPercent,
        rentabiltyPerEuro: rentabiltyPerEuro,
        moneyDuplicateTimes: moneyDuplicateTimes
      }
      arrResponse.push(object)
    }
    return arrResponse;
  }

  getDataPageByDataMiniTest() {
    let obj, title, idVideo;
    //Currently only typeInvestor property is used
    let objectiveText = transverser.get('model.objectiveText')(this.state.values)
    let years = transverser.get('model.years')(this.state.values)
    let contributionInitial = transverser.get('model.contributionInitial')(this.state.values)
    let contributionMonthly = transverser.get('model.contributionMonthly')(this.state.values)
    let typeInvestor = transverser.get('model.typeInvestor')(this.state.values)
    let typeInvestorIcon = transverser.get('model.typeInvestorIcon')(this.state.values)
    let typeInvestorText = transverser.get('model.typeInvestorText')(this.state.values)

    let dataGeneral = {
      productId: transverser.get('model.productData.id')(this.state.values),
      portfolioId: this.state.portfolio.id,
      objectiveText: this.props.intl.formatMessage({ id: transverser.get('model.objectiveText')(this.state.values) }),
      initialContribution: transverser.get('model.contributionInitial')(this.state.values),
      monthlyContribution: transverser.get('model.contributionMonthly')(this.state.values),
      years: transverser.get('model.years')(this.state.values),
      typeInvestor: transverser.get('model.typeInvestor')(this.state.values),
      typeInvestorText: this.props.intl.formatMessage({ id: transverser.get('model.typeInvestorText')(this.state.values) }),
    }
    this.setStatePromise({ dataGeneral: dataGeneral })

    title = this.state.portfolio.name;//this.props.intl.formatMessage({ 'id': `miniTestProductLanding.product${typeInvestor}` })
    idVideo = tGet(`minitest.landing.idVideo${typeInvestor}`, '9ZLa4QwtZYM')(config)

    let savings = this.calculateData(years, contributionInitial,
      contributionMonthly, typeInvestor)
    console.log("SAVINGS: ", savings)
    switch (typeInvestor) {
      case 1:
      default:
        obj = {
          title: title,
          profitabilityHistoric: utils.formatPorcentage(10.0),
          profitabilityAnnualized: utils.formatPorcentage(5.75),
          urlVideo: idVideo,
          textVideo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend faucibus nulla, id porta turpis iaculis non. Maecenas vel elit eget diam convallis porttitor vitae eleifend ex. Ut porttitor venenatis est vel dictum. Suspendisse imperdiet, nulla vitae mollis posuere, augue eros iaculis eros, vel lobortis velit leo vitae nibh. Maecenas vehicula velit ut ipsum tristique eleifend. Vivamus hendrerit diam et tortor imperdiet fringilla.",
        }
        break;
      case 2:
        obj = {
          title: title,
          profitabilityHistoric: utils.formatPorcentage(10.0),
          profitabilityAnnualized: utils.formatPorcentage(5.75),
          urlVideo: idVideo,
          textVideo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend faucibus nulla, id porta turpis iaculis non. Maecenas vel elit eget diam convallis porttitor vitae eleifend ex. Ut porttitor venenatis est vel dictum. Suspendisse imperdiet, nulla vitae mollis posuere, augue eros iaculis eros, vel lobortis velit leo vitae nibh. Maecenas vehicula velit ut ipsum tristique eleifend. Vivamus hendrerit diam et tortor imperdiet fringilla.",
        }
        break;
      case 3:
        obj = {
          title: title,
          profitabilityHistoric: utils.formatPorcentage(10.0),
          profitabilityAnnualized: utils.formatPorcentage(5.75),
          urlVideo: idVideo,
          textVideo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend faucibus nulla, id porta turpis iaculis non. Maecenas vel elit eget diam convallis porttitor vitae eleifend ex. Ut porttitor venenatis est vel dictum. Suspendisse imperdiet, nulla vitae mollis posuere, augue eros iaculis eros, vel lobortis velit leo vitae nibh. Maecenas vehicula velit ut ipsum tristique eleifend. Vivamus hendrerit diam et tortor imperdiet fringilla.",
        }
        break;
      case 4:
        obj = {
          title: title,
          profitabilityHistoric: utils.formatPorcentage(10.0),
          profitabilityAnnualized: utils.formatPorcentage(5.75),
          urlVideo: idVideo,
          textVideo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend faucibus nulla, id porta turpis iaculis non. Maecenas vel elit eget diam convallis porttitor vitae eleifend ex. Ut porttitor venenatis est vel dictum. Suspendisse imperdiet, nulla vitae mollis posuere, augue eros iaculis eros, vel lobortis velit leo vitae nibh. Maecenas vehicula velit ut ipsum tristique eleifend. Vivamus hendrerit diam et tortor imperdiet fringilla.",
        }
        break;
      case 5:
        obj = {
          title: title,
          profitabilityHistoric: utils.formatPorcentage(10.0),
          profitabilityAnnualized: utils.formatPorcentage(5.75),
          urlVideo: idVideo,
          textVideo: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce eleifend faucibus nulla, id porta turpis iaculis non. Maecenas vel elit eget diam convallis porttitor vitae eleifend ex. Ut porttitor venenatis est vel dictum. Suspendisse imperdiet, nulla vitae mollis posuere, augue eros iaculis eros, vel lobortis velit leo vitae nibh. Maecenas vehicula velit ut ipsum tristique eleifend. Vivamus hendrerit diam et tortor imperdiet fringilla.",
        }
        break;
    }

    console.log("insurance COSOOOOOTOTOOSOSOS: ", this.state.insuranceCostDefault)

    //Calculate content information (slider sections (6))
    obj.information = [
      {
        title: "miniTestProductLanding.information.tab1",
        subtitle: this.props.intl.formatMessage({ 'id': 'miniTestProductLanding.information.subtitle1' }, { title: title }),
        content: (
          <div>
            <div className="elements">
              <div>
                <img src="/img/minitest/landings/fintup-aportado.svg" />
                <p><FormattedMessage id="miniTestProductLanding.information.tab1.value1" /></p>
                <p><span className='marked'>{utils.formatCurrency(savings.savedMoneyNet)}</span></p>
              </div>
              <div>
                <img src="/img/minitest/landings/fintup-ganado.svg" />
                <p><FormattedMessage id="miniTestProductLanding.information.tab1.value2" /></p>
                <p><span className='marked'>{utils.formatCurrency(savings.rentabilityMoneyNet)}</span></p>
              </div>
              <div>
                <img src="/img/minitest/landings/fintup-mediaanual.svg" />
                <p><FormattedMessage id="miniTestProductLanding.information.tab1.value3" /></p>
                <p><span className='marked'>{utils.formatPorcentage100(savings.rentabilityPercent, 2)}</span></p>
              </div>
              <div>
                <img src="/img/minitest/landings/fintup-mediatotal.svg" />
                <p><FormattedMessage id="miniTestProductLanding.information.tab1.value4" /></p>
                <p><span className='marked'>{utils.formatPorcentage100(savings.rentabilityAccumulatedNetPercent, 2)}</span></p>
              </div>
            </div>
            <p className="text">
              Con tu inversión inicial de  <span className='marked'>{utils.formatCurrency(contributionInitial)}</span> y una aportación mensual de <span className='marked'>{utils.formatCurrency(contributionMonthly)}</span> habrías conseguido acumular durante los pasados <span className='marked'>{years}</span> años un total de <span className='marked'>{utils.formatCurrency(savings.savedMoneyTotal)}</span>.
            </p>
            {/*<p className="text">
              Esto quiere decir que para tu inversión de <span className='marked'>{years}</span> años, tu inversión inicial de <span className='marked'>{utils.formatCurrency(contributionInitial)}</span> se habría multiplicado <span className='marked'>{utils.formatNumber(savings.moneyDuplicateTimes)}</span> veces en este tiempo.
            </p>*/}
            <br />
            {/*<p className="text">
              Rentabilidad media: {utils.formatPorcentage100(savings.rentabilityPercent, 2)}<br></br>
              Cuanto conseguirás ahorrar: {utils.formatCurrency(savings.savedMoneyTotal)}<br></br>
              Capital ahorrado: {utils.formatCurrency(savings.savedMoneyNet)}<br></br>
              Rentabilidad: {utils.formatCurrency(savings.rentabilityMoneyNet)}<br></br>
              Rentabilidad acumulada: {utils.formatPorcentage100(savings.rentabilityAccumulatedNetPercent, 2)}<br></br>
            </p>*/}
            <p className="text">
              Rentabilidades pasadas no garantizan rentabilidades futuras.
            </p>
            <p className="text textFooterSlider">
              <FormattedMessage id="miniTestProductLanding.information.bottomText1" />
              <OpenerModal text="miniTestProductLanding.information.tab1" click={(ev) => this.showModalEv(ev, 'showModalRentability')}></OpenerModal>
            </p>
          </div>
        )
      },
      {
        title: this.props.intl.formatMessage({ 'id': 'miniTestProductLanding.information.tab6' }, { title: title }),
        subtitle: "miniTestProductLanding.information.bottomText6",
        content: (
          <div>
            <img src={"/img/minitest/landings/" + typeInvestor + "/6.svg"} className="full" alt="" />
            <p className="text textFooterSlider">
              <FormattedMessage id="miniTestProductLanding.information.bottomText6" />
            </p>
          </div>
        )
      },
      {
        title: "miniTestProductLanding.information.tab2",
        subtitle: "miniTestProductLanding.information.subtitle2",
        content: (
          <div>
            <img src={"/img/minitest/landings/" + typeInvestor + "/4.svg"} className="full" alt="" />
            <p className="text textFooterSlider">
              <FormattedMessage id="miniTestProductLanding.information.bottomText2" />
            </p>
          </div>
        )
      },
      {
        title: "miniTestProductLanding.information.tab3",
        subtitle: "miniTestProductLanding.information.subtitle3",
        content: (
          <div>
            <img src={"/img/minitest/landings/" + typeInvestor + "/2a.svg"} className="full" alt="" />
            {/*  <img src={"/img/minitest/landings/"+typeInvestor+"/2.svg"} alt=""/> */}
            <p className="text textFooterSlider">
              <FormattedMessage id="miniTestProductLanding.information.bottomText3" />
              <OpenerModal text="miniTestProductLanding.information.bottomText3.1" click={(ev) => this.showModalEv(ev, 'showModalDistribution')}></OpenerModal>
            </p>
          </div>
        )
      },
      {
        title: "miniTestProductLanding.information.tab4",
        subtitle: "miniTestProductLanding.information.subtitle4",
        content: (
          <div>
            <img src={"/img/minitest/landings/" + typeInvestor + "/3.svg"} className="full" alt="" />
            <p className="text textFooterSlider">
              <FormattedMessage id="miniTestProductLanding.information.bottomText4" />
            </p>
          </div>
        )
      },
      {
        title: "miniTestProductLanding.information.tab5",
        subtitle: "miniTestProductLanding.information.subtitle5",
        content: (
          <div>
            <p className="text">
              <FormattedHTMLMessage id="miniTestProductLanding.information.text5" values={{ val1: savings.sumCommissions, val2: savings.portfolioCommissionUnderlyingCalculated, val3: savings.productCommissionManagement, val4: this.state.insuranceCostDefaultAge, val5: this.state.insuranceCostDefault.annualCost }}></FormattedHTMLMessage>
            </p>
            <p className="text textFooterSlider">
              <OpenerModal text="miniTestProductLanding.information.bottomText5.1" click={(ev) => this.showModalEv(ev, 'showModalCostsList')}></OpenerModal>
            </p>
          </div>
        )
      }
    ]
    return obj;
  }

  get recommendation() {
    let investTypeContainer = (
      <div className="col-xs-12 col-sm-12 col-md-12 investTypeContainer">
        <h2><FormattedMessage id="miniTestProductLanding.texthelp4" />{this.state.dataPage.title}</h2>
        {/*<p><FormattedMessage id="miniTestProductLanding.texthelp1"/></p>*/}
        {/*<FintupButton icon="play" textKey="miniTestProductLanding.button1" className="buttonContainer" buttonClassName="buttonClass" size={constants.BUTTON_SIZE.XLARGE} action={this.invest.bind(this)} />*/}
      </div>
    )
    let profitabilityContainer = (
      {/*<div className="col-xs-12 col-sm-7 col-md-6 profitabilityContainer">
        <div>
          <h3>{this.state.dataPage.profitabilityHistoric}</h3>
          <p><FormattedMessage id="miniTestProductLanding.texthelp2"/></p>
        </div>
        <div>
          <h3>{this.state.dataPage.profitabilityAnnualized}</h3>
          <p><FormattedMessage id="miniTestProductLanding.texthelp3"/></p>
        </div>
        <FintupButton icon="play" textKey="miniTestProductLanding.button1" className="buttonContainer" buttonClassName="buttonClass" size={constants.BUTTON_SIZE.XLARGE} action={this.invest.bind(this)} />
      </div>*/}
    )
    if (this.state.isMobileDevice) {
      return (
        <div className="limitSize">
          {/*profitabilityContainer*/}
          {investTypeContainer}
        </div>
      )
    }
    else {
      return (
        <div className="limitSize">
          {investTypeContainer}
          {/*profitabilityContainer*/}
        </div>
      )
    }
  }

  get advantages() {
    let items = this.state.advantages
    let elements = []
    for (let item of items) {
      let element = (
        <div key={item.title}>
          <h4><FormattedMessage id={item.title} /></h4>
          {item.checks.map((i, idx) => {
            let icon = `fa ${(i.active) ? 'fa-check' : 'fa-times'}`
            let text = i.text != "" ? this.props.intl.formatMessage({ 'id': i.text }) : ""
            let textOpenModal = i.modal ? <OpenerModal text={i.textOpenModal} click={(ev) => this.showModalEv(ev, i.refModal)}></OpenerModal> : ""
            return (
              <p key={idx}>
                <span className="icon-wrapper"><i className={icon}></i></span>
                <span className="text">
                  {text}
                  {textOpenModal}
                </span>
              </p>
            )
          })}
        </div>
      )
      elements.push(element)
    }
    return elements
  }

  get comparator() {
    let items = this.state.comparator
    let elements = []
    for (let item of items) {
      let itemActiveClass = (item.active) ? 'active' : ''
      let element = (
        <div key={item.title} className={'col-md-4 col-xs-12 comparatorElement ' + itemActiveClass}>
          <h4><FormattedMessage id={item.title} /></h4>
          {item.checks.map((i, idx) => {
            let icon = `fa ${(i.active) ? 'fa-check' : 'fa-times'}`
            return (
              <p key={idx}>
                <span className="icon-wrapper"><i className={icon}></i></span>
                <span className="text"><FormattedMessage id={i.text} /></span>
              </p>
            )
          })}
        </div>
      )
      elements.push(element)
    }
    return elements
  }

  get prevButtonSlider() {
    return (
      <ButtonBack className="buttonSlideContainer" onClick={this.onEventSlider.bind(this)}>
        <FintupButton fintupIcon="flecha-seleccionable-izquierda" textKey="button.common.back" className="buttonContainer" buttonClassName="buttonSlide" size={constans.BUTTON_SIZE.ONLY_ICON} />
      </ButtonBack>
    )
  }

  get nextButtonSlider() {
    return (
      <ButtonNext className="buttonSlideContainer" onClick={this.onEventSlider.bind(this)}>
        <FintupButton fintupIcon="flecha-seleccionable-derecha" textKey="button.common.next" className="buttonContainer" buttonClassName="buttonSlide" size={constans.BUTTON_SIZE.ONLY_ICON} />
      </ButtonNext>
    )
  }

  get dotsSlider() {
    return (
      <DotGroup className="dotReferencesSlide" onClick={this.onEventSlider.bind(this)} />
    )
  }

  /**
   * Limitation plugin "pure-react-carousel" does not allow adaptive height
   * Calculate height content of slider to adapt the height container
   * @param {*} contentHeight 
   */
  changeHeightContainerSlider(contentHeight = 0) {
    if (contentHeight === 0) {
      contentHeight = document.querySelector('li[aria-selected="true"] > .containerSlide')
      contentHeight = contentHeight ? contentHeight.clientHeight : 0
      //console.log("CONTENT HEIGHT: ", contentHeight)
    }
    let navigationHeight = document.querySelector('.navigationSlider').clientHeight;
    let dotReferencesSlide = document.querySelector('.dotReferencesSlide')
    let dotReferencesSlideStyles = getComputedStyle(dotReferencesSlide)
    let dotReferencesSlideHeight = dotReferencesSlide.offsetHeight + parseInt(dotReferencesSlideStyles.marginTop) + parseInt(dotReferencesSlideStyles.marginBottom);
    //console.log("NAVIGATION HEIGHT: ", navigationHeight)
    //console.log("Dot references slide height: ", dotReferencesSlideHeight)
    //this.setStatePromise({heightSlider: )  
    document.querySelector('.contentGeneralSlider').style.height = (contentHeight + navigationHeight + dotReferencesSlideHeight) + 'px'
    document.querySelector('.contentSlider').style.height = (contentHeight) + 'px'
    document.querySelector('.contentGeneralSlider').style.height = (contentHeight + navigationHeight + dotReferencesSlideHeight) + 'px'
    document.querySelector('.contentSlider').style.height = (contentHeight + dotReferencesSlideHeight) + 'px'
  }

  onEventSlider(ev) {
    let elementClicked = ev.target
    if (elementClicked.classList.contains("containerSlide")) {
      this.changeHeightContainerSlider(elementClicked.clientHeight)
    }
    else {
      setTimeout(() => {
        this.changeHeightContainerSlider()
      }, 10)
    }
  }

  get slider() {
    let information = this.state.dataPage.information
    return (
      <CarouselProvider className="contentGeneralSlider" isIntrinsicHeight={true} naturalSlideWidth={100} totalSlides={information.length} infinite={true}>
        <div className="navigationSlider">
          {this.prevButtonSlider}
          <Slider trayProps={{
            onDrag: this.onEventSlider.bind(this),
            onMouseDown: this.onEventSlider.bind(this),
            onMouseOut: this.onEventSlider.bind(this),
            onMouseOver: this.onEventSlider.bind(this),
            onMouseUp: this.onEventSlider.bind(this),
            onTouchCancel: this.onEventSlider.bind(this),
            onTouchEnd: this.onEventSlider.bind(this),
            onTouchStart: this.onEventSlider.bind(this)
          }}>
            {
              information.map((item, idx) => {
                return (
                  <Slide index={idx} key={idx}>
                    <h4 className="titleSlide">{this.props.intl.formatMessage({ 'id': item.title })}</h4>
                  </Slide>
                )
              })
            }
          </Slider>
          {this.nextButtonSlider}
        </div>
        {this.dotsSlider}
        <Slider className="contentSlider" trayProps={{
          onDrag: this.onEventSlider.bind(this),
          onMouseDown: this.onEventSlider.bind(this),
          onMouseOut: this.onEventSlider.bind(this),
          onMouseOver: this.onEventSlider.bind(this),
          onMouseUp: this.onEventSlider.bind(this),
          onTouchCancel: this.onEventSlider.bind(this),
          onTouchEnd: this.onEventSlider.bind(this),
          onTouchStart: this.onEventSlider.bind(this)
        }}>
          {
            information.map((item, idx) => {
              //let subtitle = typeof item.subtitle === 'string' ? <FormattedMessage id={item.subtitle}/> : item.subtitle
              return (
                <Slide index={idx} key={idx} innerClassName="containerSlide">
                  {/*<h4 className="titleSlide">{this.props.intl.formatMessage({'id': item.title})}</h4>*/}
                  {/*<h5 className="subtitleSlide">{subtitle}</h5>*/}
                  <div className="contentSlide">
                    {item.content}
                  </div>
                </Slide>
              )
            })
          }
        </Slider>
      </CarouselProvider>
    )
  }

  validateReportData() {
    let reportName = (transverser.get('state.reportName', '')(this)).trim()
    let reportEmail = (transverser.get('state.reportEmail', '')(this)).trim()
    let aceptoReport = transverser.get('state.aceptoReport', '')(this)

    let isError = false;

    this.setStatePromise({ reportNameErrorText: '' })
    if (reportName === '') {
      this.setStatePromise({ reportNameErrorText: 'SimulatorResult.sendReport.name.validation' })
      isError = true
    }

    this.setStatePromise({ reportEmailErrorText: '' })
    if (!utils.validateEmail(reportEmail)) {
      this.setStatePromise({ reportEmailErrorText: 'SimulatorResult.sendReport.email.validation' })
      isError = true
    }

    if (aceptoReport !== 'true') {
      this.handleNotification({ titleKey: 'regsterView.validation.error', textKey: 'suitabilityStep5.conditions', type: constants.NOTIFICATION_TYPE.ERROR })
      isError = true
    }

    if (isError) {
      return RSVP.reject('validation')
    }

    return RSVP.resolve()
  }

  doSendReport() {
    console.log("do send report")
    this.validateReportData()
      .then(() => {
        let reportName = (transverser.get('state.reportName', '')(this)).trim()
        let reportEmail = (transverser.get('state.reportEmail', '')(this)).trim()
        let contactData = {
          reportContact: {
            name: reportName,
            email: reportEmail
          }
        }
        let questions = this.state.dataGeneral
        let dataRentability = {
          dataRentability: this.state.dataRentability
        }
        console.log("send report: ", _.extend({}, questions, dataRentability, contactData))
        return RSVP.resolve(_.extend({}, questions, dataRentability, contactData))
      })
      .then(postData => postMinitestReport(postData))
      .then(this.hideModals.bind(this))
      .then(() => {
        this.handleNotification({ titleKey: 'contractAccount.validation.oktitle', textKey: 'SimulatorResult.reportSuccess', type: constants.NOTIFICATION_TYPE.SUCCESS })
      })
      .catch((error) => {
        console.log(error)
        if (error !== 'validation') {
          this.handleNotification({ titleKey: 'regsterView.validation.error', textKey: 'SimulatorQuestions.simulationError', type: constants.NOTIFICATION_TYPE.ERROR })
        }
      })
  }

  openTerms(ev) {
    ev.stopPropagation()
    ev.preventDefault()
    navUtils.openWindow(urls.external.headerAndFooter.legal.notice)
  }

  get modalBodyReport() {
    let radioSubText = <a href={utils.fixLinter.noAnchor()} onClick={this.openTerms.bind(this)} role="button" tabIndex="0" onKeyPress={utils.noop}><FormattedMessage id="suitabilityStep5.acceptLink" /></a>
    let radioText = <FormattedMessage id="suitabilityStep5.accept" values={{ tag1: radioSubText }} />

    return (
      <ModalBody className="modalBodyReport">
        <InputTextLabel
          placeholder="SimulatorResult.sendReport.name.placeholder"
          name="reportName" theme="new"
          isError={this.state.reportNameErrorText != ''}
          errorTextKey={this.state.reportNameErrorText}
          callBackOnChange={this.commonHandleChange.bind(this)} />
        <InputTextLabel
          placeholder="SimulatorResult.sendReport.email.placeholder"
          name="reportEmail"
          isError={this.state.reportEmailErrorText != ''}
          errorTextKey={this.state.reportEmailErrorText}
          theme="new"
          callBackOnChange={this.commonHandleChange.bind(this)} />
        <RadioButton theme="new"
          radioId="aceptoReport"
          name="aceptoReport"
          text={radioText}
          value="true"
          selectedOption={this.state.aceptoReport}
          onChangeRadio={this.commonHandleChange.bind(this)} />
        <FintupButton size={constants.BUTTON_SIZE.XXLARGE} textKey="SimulatorResult.sendReport.button" action={this.doSendReport.bind(this)} />
      </ModalBody>
    )
  }

  tableCosts(posInitial, posEnd) {
    return (
      <table className="tableModal">
        <tr>
          <th><FormattedHTMLMessage id="miniTestProductLanding.modal8.text1" /></th>
          <th><FormattedHTMLMessage id="miniTestProductLanding.modal8.text2" /></th>
          <th><FormattedHTMLMessage id="miniTestProductLanding.modal8.text3" /></th>
        </tr>
        {
          this.state.listInsuranceCosts.slice(posInitial, posEnd).map(item => {
            return (
              <tr>
                <td>{utils.formatYear(item.age)} <FormattedMessage id="years" /></td>
                <td>{utils.formatCurrency(item.monthlyCost, 2)}</td>
                <td>{utils.formatCurrency(item.annualCost, 2)}</td>
              </tr>
            )
          })
        }
      </table>
    )
  }

  render() {
    let doubtText = this.state.isMobileDevice ? "miniTestProductLanding.doubts.text3" : "miniTestProductLanding.doubts.text2"
    return this.state.isLoaded ?
      (<LoadingApp />) :
      (
        <div className="miniTestStep6">
          <div className="content contentFull text-center">
            <MiniTestTabs values={this} withButtonInvest={true} actualStep={this.state.actualStep} clickButton={this.invest.bind(this)}></MiniTestTabs>
            <div className="miniTestProductLanding">
              <div className="row recommendation">
                {this.recommendation}
              </div>
              {/*<div className="row videoText limitSize">
          <div className="videoContainer">
            <YoutubePlayer
              videoId={this.state.dataPage.urlVideo}
              playbackState='unstarted'
              configuration={{showinfo: 0, controls: 1}}
            />
          </div>
          <div className="text">
            {this.state.dataPage.textVideo}
          </div>
        </div>*/}
              <div className="row information limitSize">
                {/*<h3 className="subtitle"><FormattedMessage id="miniTestProductLanding.subtitle1"/></h3>*/}
                <div className="col-sm-7 col-xs-12 informationContainer">
                  {this.slider}
                </div>
                <div className="col-sm-5 col-xs-12 advantagesContainer">
                  {this.advantages}
                  <a className="hrefReviews" href={this.state.hrefGoogleReviews} target="_blank">
                    <img src="/img/minitest/landings/google.svg" alt="" />
                  </a>
                </div>
              </div>
              <Modal showModal={this.state.showModalRentability}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal1.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal1.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalDistribution}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal2.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal2.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalRiskProfile}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal3.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal3.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalPlanificationPersonal}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal4.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal4.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalExpert}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal5.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal5.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalLowPrices}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal6.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal6.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalCredibilityTrust}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal7.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal7.text" />
                  </p>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalCostsList}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal8.title")}>
                <ModalBody>
                  <p className="text">
                    <FormattedHTMLMessage id="miniTestProductLanding.modal8.text" />
                  </p>
                  <div className="row text">
                    <div className="col-sm-6 col-xs-12 col-list-table">
                      {this.tableCosts(0, this.state.listInsuranceCosts.length / 2)}
                    </div>
                    <div className="col-sm-6 col-xs-12 col-list-table">
                      {this.tableCosts(this.state.listInsuranceCosts.length / 2, this.state.listInsuranceCosts.length)}
                    </div>
                  </div>
                </ModalBody>
              </Modal>
              <Modal showModal={this.state.showModalVideoWidget}
                isVideo={true}
                idVideo={this.state.dataPage.urlVideo}
                clickCloseVideo={this.hideModals.bind(this)}>
              </Modal>
              <Modal
                showModal={this.state.showModalReport}
                isOverlay={true}
                isFullPage={true}
                clickOverlay={this.hideModals.bind(this)}
                modalHeader={this.modalGetHeader("miniTestProductLanding.modal9.title")}>
                {this.modalBodyReport}
              </Modal>
              <div className="row external">
                <FintupButton icon="play" textKey="miniTestProductLanding.button1" className="buttonContainer" buttonClassName="buttonClass" size={constants.BUTTON_SIZE.XLARGE} action={this.invest.bind(this)} />
                {/*<FintupButton textKey="SimulatorResult.button" buttonClassName="btn-l btn-bold" action={(ev) => this.showModalEv(ev, 'showModalReport')} />*/}
                {/*<a href={this.state.hrefDate} target="_blank"><FormattedMessage id="miniTestProductLanding.href1"/></a>*/}
                <div className="text text-center">
                  <FormattedMessage id="miniTestProductLanding.href1" />
                </div>
              </div>
              <div className="row comparator limitSize">
                <h3 className="subtitle"><FormattedMessage id="miniTestProductLanding.subtitle2" /></h3>
                <div className="text">
                  <FormattedMessage id="miniTestProductLanding.comparator.textFooter" />
                </div>
                <div className="comparatorContainer">
                  {this.comparator}
                </div>
                {/*<div className="text">
            <FormattedMessage id="miniTestProductLanding.comparator.textFooter"/>
          </div>*/}
              </div>
              {/*<div className="row doubts">
          <div>
            <p><FormattedMessage id="miniTestProductLanding.doubts.text1"/></p>
            <p><FormattedMessage id={doubtText}/></p>
          </div>
          <FintupButton icon="align-justify" textKey="miniTestProductLanding.button2" className="buttonContainer" buttonClassName="buttonClass" size={constants.BUTTON_SIZE.XXLARGE} action={this.date.bind(this)} />
        </div>*/}
              {/*<ModalVideo channel='youtube' isOpen={this.state.showModalVideoWidget} videoId={this.state.dataPage.urlVideo} onClose={this.hideModals.bind(this)} />*/}

              {/*videowidget finally<div className={this.state.showVideoWidget ? 'videoWidget' : 'hidden'} onClick={(ev) => this.showModalEv(ev, 'showModalVideoWidget')}>
              <div className="tooltip">
                <p className="text"><FormattedMessage id="miniTestProductLanding.videoWidget.text" /></p>
              </div>
              <div className="fabAvatar">
                <ManagerImage />
              </div>
              <button type="button" className="dismissVideoWidget" onClick={(ev) => this.hideVideoWidget(ev)}>&times;</button>
            </div>*/}
            </div>
          </div>
        </div>
      )
  }
}

export default injectIntl(MiniTestProductLanding)
