let config = {
  hotConfigUrl: '/json/externalConfig_pre.json',
  needLogin: true,
  apiBaseUrl: 'https://spreapi.fintup.com',
  cdnBaseUrl: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com',
  cdnEnviroment: 'pre',
  googleAnalytics: 'UA-115030746-2',
  enableTagManager: false,
  pensionPlanTransfer: true,
  enableRegister: true,
  newTools: true,
  newAcademy: false
}

export default config
