import React from 'react'
import './MiniTestStep3.scss'
import FintupComponent from '../FintupComponent'
import { FormattedMessage } from 'react-intl'
import FintupSlider from '../common/forms/FintupSlider'
import FintupButton from '../common/forms/FintupButton'
import constans from '../../utils/constants'
import transverser from '../../utils/transverser'
import MiniTestTabs from './MiniTestTabs'
import utils from '../../utils/utils'
import { contratableInvestProduct } from '../../utils/fintup-api'

class MiniTestStep3 extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("PROPS MINITESTSTEP3", props)
    this.state = {
      actualStep: 3,
      minSlideInitial: 0,
      maxSlideInitial: 300000,
      valueSlideInitial: transverser.get('model.contributionInitial', 10000)(this.props), //initial or props value
      stepSlideInitial: 500,
      minSlideMontlhy: 0,
      maxSlideMonthly: 1000,
      valueSlideMonthly: transverser.get('model.contributionMonthly', 500)(this.props), //initial or props value, //initial
      stepSlideMonthly: 50,
      errorText: ''
    }
  }

  componentDidMount () {
    contratableInvestProduct()
      .then(response => this.setStatePromise({productData: response.data}))
  }

  /**
   * Validation fields and check
   * if we can go to the next step
   */
  next(){
    this.props.updateModel({
      ...this,
      contributionInitial: this.state.valueSlideInitial,
      contributionMonthly: this.state.valueSlideMonthly,
      productData: this.state.productData
    })
    this.props.nextStep()
  }

  /**
   * Validation fields and check
   * if we can go to the previous step and which page
   * Check state
   */
  previous(){
    this.props.goToStep(2)
  }

  changeValueSliderInitial(data){
    let newValueSlider = data.ev.target.value
    this.setStatePromise({valueSlideInitial: newValueSlider})
  }

  changeValueSliderMonthly(data){
    let newValueSlider = data.ev.target.value
    this.setStatePromise({valueSlideMonthly: newValueSlider})
  }

  validateValues(){
    let response
    //Si es vacío inicial, debe haber minimumPeriodicContribution
    //Si es vacío mensual, debe haber mínimoContribution
    if(this.state.productData){
      let minimumContribution = this.state.productData.minimumContribution
      let minimumContributionWithPeriodicContribution = this.state.productData.minimumContributionWithPeriodicContribution
      let minimumPeriodicContribution = this.state.productData.minimumPeriodicContribution
      let allowZeroInitialContribution = this.state.productData.allowZeroInitialContribution
      let amount = this.state.valueSlideInitial
      let amountMonthly = this.state.valueSlideMonthly
      /*console.log("---------------") 
      console.log("MINIMUM CONTRIBUTION INITIAL: ", minimumContribution) 
      console.log("MINIMUM CONTRIBUTION PERIODIC: ", minimumPeriodicContribution) 
      console.log("ALLOW ZERO: ", allowZeroInitialContribution)   

      console.log("VALUE INITIAL: ", this.state.valueSlideInitial)   
      console.log("VALUE PERIODIC: ", this.state.valueSlideMonthly)  */

      //No se permite 0 y es 0
      if(!allowZeroInitialContribution && amount == 0){
        //Alerta error 0
        return (<FormattedMessage id="miniTestStep3.textError3" />)
      }
      
      //Se permite 0, Es 0 y monthly no llega al minimo
      if(allowZeroInitialContribution && amount == 0 && !utils.validatePositiveAmount(amountMonthly, {min: minimumPeriodicContribution})){
        //error, hay que tener aportaciones periódicas si la inversión inicial es 0
        return (<FormattedMessage id="miniTestStep3.textError1" values={{minimum: minimumPeriodicContribution}} />)
      } 
          
      //Es distinto 0 y no llega al mínimo (y la aportación periódica no llega al mínimo)
      if(!utils.validatePositiveAmount(amount, {min: minimumContribution}) && 
          !utils.validatePositiveAmount(amountMonthly, {min: minimumPeriodicContribution})){
        //error no llega al mínimo
        return (<FormattedMessage id="miniTestStep3.textError2" values={{minimum: minimumContribution}} />)
      }

      //Se permite 0, Es 0 y monthly no llega al minimo
      if(amount != 0 && !utils.validatePositiveAmount(amount, {min: minimumContributionWithPeriodicContribution})){
        //error, hay que tener aportaciones periódicas si la inversión inicial es 0
        return (<FormattedMessage id="miniTestStep3.textError4" values={{minimum: minimumContributionWithPeriodicContribution}} />)
      } 
    }
    return ""
  }

  render () {
    let errorText = this.validateValues()
    let isDisabledNext = errorText != ""
    return (
      <div className="miniTestStep3">
        <div className="content text-center">
          <MiniTestTabs values={this} actualStep={this.state.actualStep} blockNextItems={isDisabledNext}></MiniTestTabs>
          <div className="row">
            <h3><FormattedMessage id="miniTestStep3.title" /></h3>
            <span className="subtitle-text-error">{errorText}</span>
          </div>
          <div className="row m-auto">
            <div className="col-xs-12">
              <h4 className="typeContribution"><FormattedMessage id="miniTestStep3.text2" /></h4>
            </div>
            <div className="col-sm-9 col-xs-7 slider">
              <FintupSlider step={this.state.stepSlideInitial} showTooltip={false} min={this.state.minSlideInitial} max={this.state.maxSlideInitial} start={this.state.valueSlideInitial} callBackOnChange={this.changeValueSliderInitial.bind(this)}/>
            </div>
            <div className="col-sm-3 col-xs-5">
              <h4 className="inputNumber">{utils.formatCurrencyNoDecimals(this.state.valueSlideInitial)}</h4>
            </div>
          </div>
          <div className="row m-auto">
            <div className="col-xs-12">
              <h4 className="typeContribution"><FormattedMessage id="miniTestStep3.text3" /></h4>
            </div>
            <div className="col-sm-9 col-xs-7 slider">
              <FintupSlider step={this.state.stepSlideMonthly}  showTooltip={false} min={this.state.minSlideMontlhy} max={this.state.maxSlideMonthly} start={this.state.valueSlideMonthly} callBackOnChange={this.changeValueSliderMonthly.bind(this)}/>
            </div>
            <div className="col-sm-3 col-xs-5">
              <h4 className="inputNumber">{utils.formatCurrencyNoDecimals(this.state.valueSlideMonthly)}</h4>
            </div>
          </div>
        </div>
        <div className="miniTestStepButtonContainer">
          <FintupButton fintupIcon="flecha-seleccionable-izquierda" iconRight={true} textKey="button.common.next" className="buttonContainer buttonPrevMiniTest" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.ONLY_ICON} action={() => this.previous()} />
          <FintupButton disabled={isDisabledNext} fintupIcon="flecha-seleccionable-derecha" iconRight={true} textKey="button.common.next" className="buttonContainer" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.XLARGE} action={this.next.bind(this)} />
        </div>
      </div>
    )
  }

}

export default MiniTestStep3