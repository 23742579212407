import React from 'react'
import FintupComponent from '../../components/FintupComponent'
import LoadingApp from '../../components/common/LoadingApp'
import transverser from '../../utils/transverser'
import SessionController from '../../core/SessionMobx'
import './SSOView.css'

class SSOView extends FintupComponent {
  constructor (props) {
    super(props)
    let partnerType = transverser.get('routeParams.partner')(props)
    let ssoToken = transverser.get('routeParams.token')(props)
    this.state = {
      partnerType: partnerType,
      ssoToken: ssoToken
    }
  }

  componentDidMount () {
    SessionController.setSession({
      user: this.state.partnerType,
      name: this.state.partnerType,
      token: this.state.ssoToken,
      oneTimePassword: false,
      emulateSession: false,
      partnerSession: true,
      partnerType: this.state.partnerType
    })
    SessionController.redirectToApp()
  }

  render () {
    return (
      <div>
        <LoadingApp />
      </div>
    )
  }
}

export default SSOView
