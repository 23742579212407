import React from 'react'
import { injectIntl, FormattedMessage } from "react-intl";
import FintupComponent from "../FintupComponent";
import './ContractStep4.scss';
import FintupButton from '../common/forms/FintupButton';
import ContractTabs from './ContractTabs';
import constants from '../../utils/constants';
import TitleLabel from '../common/forms/TitleLabel';
import Checkbox from '../common/forms/Checkbox';
import FintupTooltip from '../common/FintupTooltip';
import FileUpload from '../documents/FileUpload';
import LoadingMobx from '../../core/LoadingMobx';
import RSVP from 'rsvp';
import CheckboxNew from '../common/forms/CheckboxNew';
import { getContractUserDocuments } from '../../utils/fintup-api';
import Document from '../documents/Document';
import { observer } from 'mobx-react';
import ContractExpertHelp from './ContractExpertHelp';

class ContractStep4 extends FintupComponent
{
  constructor(props){
    super(props)

    this.state = {
      actualStep: 5,
      fileUploaded: {
        DOCUMENT_FRONT: false,
        DOCUMENT_BACK: false
      },
      uploadLater: false,
      DOCUMENT_FRONT_FILE_DATA: {
        icon: 'dni-anverso',
        documentType: 'DOCUMENT_FRONT'
      },
      DOCUMENT_BACK_FILE_DATA: {
        icon: 'dni-reverso',
        documentType: 'DOCUMENT_BACK'
      }
    }
  }

  componentDidMount () {
    LoadingMobx.setLoading()
      .then(getContractUserDocuments)
      .then(response => {
        let fileUploaded = this.state.fileUploaded
        response.data.userDocumentData.forEach(document => {
          if (document.uploaded) {
            fileUploaded[document.documentKey] = document.uploaded
            let objectFileData = this.state[`${document.documentKey}_FILE_DATA`]
            objectFileData.documentStateType = constants.FILE_STATE.UPLOADED
            objectFileData.fileName = document.documentKey
            objectFileData.id = document.documentKey
            objectFileData.uriDownload = document.urlDownload
          }
        })
        return this.setStatePromise({fileUploaded: fileUploaded})
      })
      .catch(error => console.log(error))
      .finally(() => LoadingMobx.unsetLoading())
  }


  /**
  * Validation fields and check
  * if we can go to the next step
  */
  next(){
    let isError = false;
    if (!this.state.uploadLater) {
      if (!this.state.fileUploaded.DOCUMENT_FRONT) {
        this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractUserDocument.validation.front', type: constants.NOTIFICATION_TYPE.ERROR})
        isError = true
      }

      if (!this.state.fileUploaded.DOCUMENT_BACK) {
        this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractUserDocument.validation.back', type: constants.NOTIFICATION_TYPE.ERROR})
        isError = true
      }
    }

    if(isError){
      return
    }

    LoadingMobx.setLoading()
      .then(() => {
        this.props.nextStep()
      })
      .catch(this.handleServiceError.bind(this))
      .finally(() => LoadingMobx.unsetLoading())
      
    this.props.updateModel({
      ...this,
      step4: this.state,
    })

    this.props.nextStep()
  } 

  postUploadDocument (fileData) {
    if (fileData.documentStateType === constants.FILE_STATE.UPLOADED) {
      this.state.fileUploaded[fileData.id] = true
      return this.setStatePromise({fileUploaded: this.state.fileUploaded})
    }
    return RSVP.resolve()
  }


  render(){
    return(
      <div className="contractStep4">
        <div className="content text-center">
          <ContractTabs values={this} blockNextItems={true} actualStep={this.state.actualStep} titleKey="contractGeneralView.title1" elementActive="3"></ContractTabs>
          <div className="container contentData">
            <div className="row">
              <TitleLabel 
                title="1"
                descriptionKey="contractStep.title35"
                theme="new">
              </TitleLabel>
              <FileUpload
                fileData={this.state.DOCUMENT_FRONT_FILE_DATA}
                postUpload={this.postUploadDocument.bind(this)}
                showUploadedType={true}
                allowImage={true}
                allowPdf={true}
                newStyle={true}
                theme="new"
                isLoading={LoadingMobx.isLoading}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="2"
                descriptionKey="contractStep.title36"
                theme="new">
              </TitleLabel>
              <FileUpload
                  fileData={this.state.DOCUMENT_BACK_FILE_DATA}
                  postUpload={this.postUploadDocument.bind(this)}
                  showUploadedType={true}
                  allowImage={true}
                  allowPdf={true}
                  newStyle={true}
                  theme="new"
                  isLoading={LoadingMobx.isLoading}/>
            </div>
            <div className="row">
              <CheckboxNew theme="new" 
               isBoldChecked={true}
               tooltipId="uploadLaterTooltip"
               name="uploadLater" 
               value={this.state.uploadLater} 
               textKey="contractUserDocument.uploadLater" 
               onChangeCheckbox={this.commonCheckboxChange.bind(this)}/>
              <FintupTooltip tooltipId="uploadLaterTooltip">
                <p><FormattedMessage id="contractUserDocument.uploadLater.tooltip"/></p>
              </FintupTooltip>
            </div>
          </div>
          <div className="buttonsContainer">
            <FintupButton fintupIcon="flecha-seleccionable-izquierda"
            iconRight={true} textKey="button.common.next" 
            className="buttonContainer buttonPrevMiniTest"
            buttonClassName="btn-bold" size={constants.BUTTON_SIZE.ONLY_ICON} action={this.props.prevStep} />
            <FintupButton
              fintupIcon="flecha-seleccionable-derecha" 
              iconRight={true} textKey="button.common.next" 
              className="buttonContainer buttonAttention"
              buttonClassName="btn-bold"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={() => this.next()}/>
          </div>
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        <ContractExpertHelp></ContractExpertHelp>
      </div>
    )
  }
}
export default injectIntl(observer(ContractStep4))
