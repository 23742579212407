import { browserHistory } from 'react-router'
import SessionController from './SessionMobx'
import RSVP from 'rsvp'
import urls from '../utils/urls'
import _ from 'lodash'
import config from '../config/config'

const $ = window.$

function goToInternal (url, params) {
  $('.navbar-toggle[aria-expanded="true"]').click()
  let parameters = params || []
  parameters.forEach(parameter => {
    url = url.replace('{param}', parameter)
  })
  browserHistory.push(url)
}

function goToInitUrl () {
  let token = SessionController.token
  let logoUrl = token && token !== '' ? urls.internal.initUrl : urls.internal.login
  goToInternal(logoUrl)
}

/** Opciones de abrir en nueva ventana:
 * opts:
 *  promiseResult: al abrir la ventana devolvera una promesa resuelta o rechazada
 *  newWindow: Inidica si se abrira o no el enlace en una nueva ventana (por defecto no)
*/
function goToExternal (url, paramOpts = {}) {
  if (_.isFunction(url)) {
    url = url()
  }
  let defaultOpts = {
    promiseResult: true,
    newWindow: false
  }

  let opts = _.merge(defaultOpts, paramOpts)
  if (opts.newWindow) {
    var win = window.open(url, '_blank')
    win.focus()
  } else {
    window.location.href = url
  }
  return opts.promiseResult ? RSVP.resolve() : RSVP.reject()
}

function closeMenu () {
  $('.navbar-toggle[aria-expanded="true"]').click()
}

function logoutRedirect (emulatedParam) {
  let finalUrl = urls.internal.login
  if (emulatedParam) {
    finalUrl = urls.internal.helpdesk
  }
  goToInternal(finalUrl)
}

function openWindow (url) {
  window.open(url, '_blank')
}

function openResource (resourceName) {
  let sessionToken = SessionController.token
  let url = `${config.apiBaseUrl}/api/file/download/resource/${resourceName}`
  openPrivateWindow(url, sessionToken)
}

function openPrivateWindow (url, token) {
  if (url.indexOf('?') >= 0) {
    url = `${url}&token=${token}`
  } else {
    url = `${url}?token=${token}`
  }
  openWindow(url)
}

function goToLogoUrl () {
  let token = SessionController.token
  if (token && token !== '') {
    goToInternal(urls.internal.initUrl)
  } else {
    goToExternal(urls.external.headerAndFooter.fintup.home)
  }
}

let navUtils = {
  goToInternal: goToInternal,
  closeMenu: closeMenu,
  goToInitUrl: goToInitUrl,
  goToExternal: goToExternal,
  logoutRedirect: logoutRedirect,
  openWindow: openWindow,
  openPrivateWindow: openPrivateWindow,
  openResource: openResource,
  goToLogoUrl: goToLogoUrl
}

export default navUtils
