import { Component, React } from 'react'
import componentUtils from '../utils/componentUtils'
import constants from '../utils/constants'
import { FormattedMessage } from 'react-intl'
import transverser from '../utils/transverser'
import RSVP from 'rsvp'
import { store } from 'react-notifications-component';

class FintupComponent extends Component {
  constructor (props) {
    super(props)
    this.setStatePromise = componentUtils.setStatePromise.bind(this)
  }

  _getState (key, defaultValue) {
    return transverser.get(key, defaultValue)(this.state) || defaultValue
  }

  commonHandleChange (data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    let firstDotPosition = fieldName.indexOf('.')
    if (firstDotPosition === -1) {
      return this.setStatePromise({ [fieldName]: fieldValue })
    } else {
      let baseStateObjectName = fieldName.substring(0, firstDotPosition)
      let finalObjectName = fieldName.substring(firstDotPosition + 1)
      let baseObject = this.state[baseStateObjectName]
      transverser.set(finalObjectName, fieldValue)(baseObject)
      return this.setStatePromise({ [baseStateObjectName]: baseObject })
    }
  }

  commonCheckboxChange (data) {
    //console.log("DATA: ", data)
    let fieldName = data.name
    let fieldValue = data.value
    let firstDotPosition = fieldName.indexOf('.')
    if (firstDotPosition === -1) {
      return this.setStatePromise({ [fieldName]: fieldValue }).then(() => RSVP.resolve(fieldValue))
    } else {
      let baseStateObjectName = fieldName.substring(0, firstDotPosition)
      let finalObjectName = fieldName.substring(firstDotPosition + 1)
      let baseObject = this.state[baseStateObjectName]
      transverser.set(finalObjectName, fieldValue)(baseObject)
      return this.setStatePromise({ [baseStateObjectName]: baseObject }).then(() => RSVP.resolve(fieldValue))
    }
  }

  handleServiceError (errorResponse) {
    let alertData = {
      alertText: errorResponse.message || <FormattedMessage id="general.error.unknown" />,
      text: errorResponse.message || <FormattedMessage id="general.error.unknown" />,
      alertType: constants.alertType.ERROR
    }
    console.log(errorResponse)
    if (errorResponse.jsAlert) {
      alert(alertData.alertText)
      return RSVP.reject()
    } 
    else {
      return this.setStatePromise({ alert: alertData }, false)
    }
  }

  handleNotification(object){
    console.log("OBJECT notification: ", object)
    store.addNotification({
      title: object.titleKey ? this.props.intl.formatMessage({ id: object.titleKey }) : object.title ? object.title : '',
      message: object.textKey ? this.props.intl.formatMessage({ id: object.textKey }) : object.text ? object.text : '',
      type: object.type || 'default',
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        //onScreen: true
        pauseOnHover: true,
        showIcon: true,
        touch: false,
        click: false,
      }
    });
  }
}
export default FintupComponent
