/* global Calendly */
import React from 'react'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from 'react-intl'
import classNames from 'classnames'
import FintupComponent from '../../FintupComponent'
import _ from 'lodash'
import uniqid from 'uniqid'
import './FintupFabExpert.scss'
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import ManagerImage from '../../suitability/ManagerImage'
import urls from '../../../utils/urls'
import constants from '../../../utils/constants'
import config from '../../../config/config'
import AdviserModel from '../AdviserModel'
import SessionController from '../../../core/SessionMobx'
import PhrasesModel from '../../../components/globalPosition/PhrasesModel'
import RSVP from 'rsvp'
import { tGet } from '../../../utils/transverser'
import utils from '../../../utils/utils'
import { registerAdviserQuestion, getContractInvestData, contratableInvestProduct, profileGet, getPortfolioRecommendableDataByProductAndRiskType, doSuitabilityContract } from '../../../utils/fintup-api'
import NavPrivateModel from '../../header/NavPrivateModel'
import FintupCalendly from '../FintupCalendly'

/**
* show = true|false
* conditionExecOption = true|false
* execOption = 1,2,3
 */
class FintupFabExpert extends FintupComponent {
  constructor(props, context) {
    super(props, context)
    this.state = {
      renderContent: false,
      option: 0,
      origin: 'MYEXPERT',
      calendlyFilesLoaded: false,
      isMobileDevice: utils.isMobileDevice(),
      initialModel: {
      },
      isOpen: false,
    }
  }

  componentDidMount() {
    PhrasesModel.updatePhrase()

    if (config.calendlyEnable) {
      console.log("OK CALENDLY ENABLE")
      if (SessionController.scriptsLoaded.calendly) {
        console.log("OK CALENDLY files loaded")
        this.setStatePromise({ calendlyFilesLoaded: true })
      } else {
        let isPrivateSession = SessionController.isLogged()
        let promisesHash = {
          loadCss: utils.loadCss('https://assets.calendly.com/assets/external/widget.css'),
          loadScript: utils.loadScript('https://assets.calendly.com/assets/external/widget.js')
        }

        if (isPrivateSession) {
          promisesHash.adviser = AdviserModel.loadAdviser()
        }

        //NECCESARY CHECK TEST OR NOT param url
        let urlParam = tGet('routeParams.investStep', '')(this.props)
        if (urlParam == 'cita') {
          RSVP.hash(promisesHash)
            .then(() => {
              console.log("OK CALENDLY sript loaded from else")
              //Check params
              return AdviserModel.loadAdviser()
                .then(() => {
                  SessionController.scriptsLoaded.calendly = true
                  return this.setStatePromise({ calendlyFilesLoaded: true, renderContent: true })
                })
                .then(() => {
                  //Open option dropdown
                  this.send(4)
                })
                .catch(error => console.error(error))
            })
            .catch(error => console.log(error))
        }
        else {
          RSVP.hash(promisesHash)
            .then(() => {
              console.log("OK CALENDLY sript loaded from else")
              SessionController.scriptsLoaded.calendly = true
              return this.setStatePromise({ calendlyFilesLoaded: true })
            })
        }
      }
    }

    AdviserModel.loadAdviser()
      .then(() => {
        this.setStatePromise({ 'renderContent': true })
      })
  }

  clickSelect(value, event) {
    console.log("CLICK SELECT: ", value)
  }

  /**
 * Register click in the system
 * @param {*} registerType 
 */
  registerClick(registerType) {
    let requestRegister = {
      type: registerType,
      origin: this.state.origin,

    }
    return registerAdviserQuestion(requestRegister)
  }

  send(option) {
    let mailTo, mailSubject, mailBody, questionMailLink, questionWhatsApp
    switch (option) {
      //Whatsapp
      case 1:
        //mailTo = AdviserModel.adviser.email
        //mailTo = 'diana@fintup.es'
        //mailSubject = 'Duda sobre Fintup'
        //mailBody = `Hola ${AdviserModel.adviserName}, `
        //questionMailLink = `mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`
        questionWhatsApp = `https://api.whatsapp.com/send?phone=+${constants.phones.PHONE2_CALL}`
        this.registerClick('MAIL')
        window.open(questionWhatsApp)
        break;
      case 2:
        this.registerClick('MAIL')
        Calendly.showPopupWidget(AdviserModel.adviser.callLink)
        break;
      case 3:
        this.registerClick('MAIL')
        Calendly.showPopupWidget(AdviserModel.adviser.adviserLink)
        break;
    }
  }

  get componentClasses() {
    return classNames(
      'fintupFabExpert',
      this.props.className,
      this.props.background ? 'background' : 'only-text',
      this.props.value >= this.props.cutIndicatorMedium ? 'ok' :
        this.props.value >= this.props.cutIndicatorLow ? 'ok-medium' : 'no-ok',
      this.props.arrow ? 'arrow' : 'no-arrow',
      this.props.hideIfZero && this.props.value == 0 ? 'visibility-hidden' : ''
    )
  }

  handleChange(data) {
    let name = data.name
    let value = data.value
    let newState = {
      [name]: value
    }
    this.setStatePromise(newState)
  }

  handleVisible() {
    this.setStatePromise({ isOpen: true })
  }

  handleHide() {
    this.setStatePromise({ isOpen: false })
  }

  handleClick() {
    this.setStatePromise({ isOpen: !this.state.isOpen })
  }

  render() {
    if (this.props.conditionExecOption)
      this.send(this.props.execOption)
    if (this.state.renderContent && this.state.calendlyFilesLoaded) {
      if (this.props.show == false)
        return ''
      else
        return this.renderContent()
    } else {
      return ''
    }
  }

  renderContent() {
    let expertOptions = [
      {
        icon: "/img/whatsapp.svg",
        label: 'myExpertView.tools.1',
        action: this.send.bind(this, 1)
      },
      {
        icon: "/img/smartphone.svg",
        label: 'myExpertView.tools.3',
        action: this.send.bind(this, 2)
      },
      {
        icon: "/img/smartphone.svg",
        label: 'myExpertView.tools.4',
        action: this.send.bind(this, 3)
      },
    ]
    return (
      <Fab
        className={`rtf ${this.state.isOpen ? 'open' : 'closed'}`}


        mainButtonStyles={{
          backgroundColor: "transparent",
          boxShadow: "none",
          transition: "none", transform: "none"
        }}
        style={
          { bottom: 55, right: 28, transition: "none", transform: "none" }
        }
        icon={<ManagerImage theme="new" />}
        event={"hover"}
        alwaysShowTitle={true}
        onClick={this.handleClick.bind(this)}
      >
        <div className={this.componentClasses} onMouseEnter={this.handleVisible.bind(this)} onMouseLeave={this.handleHide.bind(this)}>
          <div className="container-options">
            {/*<p className="expertAssigned"><FormattedMessage id="myExpertView.myExpertAssigned" /></p>*/}
            <div className="header-options">
              <ManagerImage theme="new" />
              <h3>{AdviserModel.adviserName}</h3>
            </div>
            <p className="phrase"><FormattedHTMLMessage id="myExpertView.phrase" /></p>
            <p className="separation"></p>
            <h3><FormattedMessage id="operationBar.phones" /></h3>
            <table>
              <tbody>
                <tr>
                  <td><a href={"tel: " + constants.phones.PHONE1_CALL} target="_blank">{constants.phones.PHONE1}</a> - <a href={"tel: " + constants.phones.PHONE2_CALL} target="_blank">{constants.phones.PHONE2}</a></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <h3><FormattedMessage id="myExpertView.help" /></h3>
            <table>
              <tbody>
                {
                  expertOptions.map(item => {
                    return <tr key={uniqid()}>
                      <td><img src={item.icon} /></td>
                      <td><a onClick={item.action} target="_blank" className="element"><FormattedMessage id={item.label} /></a></td>
                    </tr>
                  })
                }
              </tbody>

            </table>
          </div>
        </div>



      </Fab>
    )
  }
}

export default injectIntl(FintupFabExpert)
