
import React from 'react'
import './DynamicFormGroup.scss'
import { injectIntl } from 'react-intl'
import FintupComponent from '../../FintupComponent'
import FintupButton from './FintupButton'
import uniqid from 'uniqid'
import constants from '../../../utils/constants'
import classNames from 'classnames'

class DynamicFormGroup extends FintupComponent {
  constructor (props) {
    super(props)
    let idGroup = uniqid()
    this.state = {
      groups: [{
        id: idGroup,
        elements: this.childrenWithProps(idGroup)
      }],
    }
    this.props.onChange(this.state.groups)
  }

  appendGroup(){
    let idGroup =  uniqid()//this.nextId()
    let newGroup = this.childrenWithProps(idGroup)
    let groups = Array.from(this.state.groups)
    groups.push({id: idGroup, elements: newGroup})
    //console.log("ANTERIOR GROUP: ", this.state.groups)
    //console.log("NEW GROUPs: ", groups)
    this.setState({groups: groups})
    this.props.onChange(groups)
  }

  deleteGroup(idGroup){
    if(this.state.groups && this.state.groups.length > 1){
      //console.log("A borrrar; ", idGroup)
      //console.log("Elementos: ", this.state.groups)
      const groups = [].concat(this.state.groups)
      let positionElement = groups.findIndex(elem => elem.id === idGroup)
      //console.log("POSITION ELEMENT: ", positionElement)
      groups.splice(positionElement, 1)
      //console.log("ARR: ", groups)
      this.setState({groups: groups})
      this.props.onRemove(idGroup)
      this.props.onChange(groups)
    }
    
  }

  childrenWithProps(idGroup){
    console.log("THIS PROPS CHILDREN: ", this.props.children)
    let childrenWithProps = this.props.children.map((child, index) => {
      return React.cloneElement(child,
        {
          name:`${idGroup}-${child.props.name}`,
          key: `${idGroup}-${index}`,
        })
    })
    return childrenWithProps
  }

  render(){
    let containerClass = classNames('dynamicFormGroup', this.props.className)
    return (
    <div className={containerClass}>
     
      {this.state.groups.map((elem, index) => {
        return (
          <div key={elem.id}>
            {
              this.state.groups.length == 1 ? 
              '' :
              (
                <FintupButton 
                  iconImage="/img/trash.svg" 
                  className="btn-trash"
                  size={constants.BUTTON_SIZE.ONLY_ICON} 
                  action={this.deleteGroup.bind(this, elem.id)}/>
              )
            }
            {elem.elements}
          </div>
        )
      })}
      <FintupButton fintupIcon="plus" 
        textKey={this.props.textKeyButton ? this.props.textKeyButton : 'add'} 
        size={constants.BUTTON_SIZE.XLARGE} 
        action={this.appendGroup.bind(this)}/>
    </div>)
  }
}

export default injectIntl(DynamicFormGroup)
