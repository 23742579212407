import React from 'react'
import './MiniTestStep1.scss'
import FintupComponent from '../FintupComponent'
import { FormattedMessage } from 'react-intl'
import FintupSlider from '../common/forms/FintupSlider'
import FintupButton from '../common/forms/FintupButton'
import constans from '../../utils/constants'
import transverser from '../../utils/transverser'
import MiniTestTabs from './MiniTestTabs'

class MiniTestStep1 extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("PROPS MINITESTSTEP1", props)
    this.state = {
      actualStep: 2,
      minSlide: 3,
      maxSlide: 50,
      valueSlide: transverser.get('model.years', 15)(this.props) //initial or props value
    }
  }

  changeValueSlider(data){
    let newValueSlider = data.ev.target.value
    this.setStatePromise({valueSlide: newValueSlider})
  }

  /**
   * Validation fields and check
   * if we can go to the next step
   */
  next(){
    this.props.updateModel({
      ...this,
      years: this.state.valueSlide
    })
    this.props.goToStep(3)
  }

  /**
   * Validation fields and check
   * if we can go to the previous step and which page
   * Check state
   */
  previous(){
    this.props.prevStep()
  }

  render () {
    return (
      <div className="miniTestStep1">
        <div className="content text-center">
          <MiniTestTabs values={this} actualStep={this.state.actualStep}></MiniTestTabs>
          <div className="row">
            <h3><FormattedMessage id="miniTestStep1.title" /></h3>
            <span className="subtitle-text"><FormattedMessage id="miniTestStep1.text1" /></span>
          </div>
          <div className="row">
            <h4 className="investYears m-auto">{this.state.valueSlide}</h4>
          </div>
          <div className="row slider m-auto">
            <FintupSlider showTooltip={false} min={this.state.minSlide} max={this.state.maxSlide} start={this.state.valueSlide} callBackOnChange={this.changeValueSlider.bind(this)}/>
          </div>
        </div>
        <div className="miniTestStepButtonContainer">
          <FintupButton fintupIcon="flecha-seleccionable-izquierda" iconRight={true} textKey="button.common.next" className="buttonContainer buttonPrevMiniTest" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.ONLY_ICON} action={() => this.previous()} />
          <FintupButton fintupIcon="flecha-seleccionable-derecha" iconRight={true} textKey="button.common.next" className="buttonContainer" buttonClassName="btn-bold" size={constans.BUTTON_SIZE.XLARGE} action={this.next.bind(this)} />
        </div>
      </div>
    )
  }

}

export default MiniTestStep1