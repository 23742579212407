import React from 'react'
import './MiniTestStep6.scss'
import FintupComponent from '../FintupComponent'
import MiniTestTabs from './MiniTestTabs'
import MiniTestProductLanding from './MiniTestProductLanding'

class MiniTestStep6 extends FintupComponent {
  
  constructor (props) {
    super(props)
    console.log("PROPS MINITESTSTEP6", props)

    this.state = {
      actualStep: 6,
    }
  }

  render(){
    return (
      <div className="miniTestStep6">
        <div className="content contentFull text-center">
            <MiniTestTabs values={this} withButtonInvest={true} actualStep={this.state.actualStep}></MiniTestTabs>
            <MiniTestProductLanding values={this}></MiniTestProductLanding>
        </div>
      </div>
    )
  }
}

export default MiniTestStep6