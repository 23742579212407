import { extendObservable } from 'mobx'
import _ from 'lodash'
import { observer } from 'mobx-react'
import ProductsController from '../../core/ProductsMobx'
import urls from '../../utils/urls'
import SessionController from '../../core/SessionMobx'
import navUtils from '../../core/navUtils'
import { getBooleanConfig } from '../../config/config'
import constants from '../../utils/constants'
import RSVP from 'rsvp'

class OperationBarMobx {
  constructor () {
    extendObservable(this, {
      items: [],
      noProductOperationName: 'contratar'
    })
  }

  loadOperationBarItems () {
    const inviteObject = {
      icon: 'inviteplus',
      text: 'operationBar.invite',
      url: urls.internal.invite,
      noProductShow: true,
      noProductOrder: 1
    }

    const toolsObject = {
      icon: 'herramientas',
      text: 'operationBar.tools',
      url: getBooleanConfig('newTools') ? urls.internal.tools : urls.internal.simulatePrivate,
      noProductShow: true,
      noProductOrder: 1
    }

    this.items = [
      {
        icon: 'miperfil',
        text: 'operationBar.profile',
        url: urls.internal.profile,
        noProductShow: false
      },
      {
        icon: 'contratar',
        text: 'operationBar.contract',
        url: urls.internal.contract,
        noProductShow: true,
        noProductOrder: 2,
        noProductBigIcon: true
      },
      {
        icon: 'midinero',
        text: 'operationBar.money',
        bigIcon: true,
        url: urls.internal.initUrl,
        noProductShow: false
      },
      getBooleanConfig('inviteView')
        ? inviteObject
        : toolsObject,
      {
        icon: 'academia',
        text: 'operationBar.academy',
        url: getBooleanConfig('newAcademy') ? urls.internal.academy : urls.external.operationBar.academy,
        external: !getBooleanConfig('newAcademy'),
        noProductShow: true,
        noProductOrder: 3,
        action: () => { SessionController.academyCategoryView = constants.ACADEMY_VIEW.CATEGORIES }
      }
    ]
    return RSVP.resolve()
  }

  get calculatedItems () {
    if (ProductsController.productsLoaded) {
      return ProductsController.getProducts.length === 0
        ? this.items
          .filter(item => item.noProductShow)
          .map(item => {
            let mapItem = {...item}
            mapItem.bigIcon = mapItem.noProductBigIcon
            return mapItem
          })
          .sort((itemA, itemB) => itemA.noProductOrder - itemB.noProductOrder)
        : this.items
    } else {
      return []
    }
  }

  getOperationByName (name) {
    let operation = _.find(this.items, {icon: name})
    return operation
  }

  goToOperation (paramItem) {
    let item = _.isObject(paramItem) ? paramItem : this.getOperationByName(paramItem)
    SessionController.activeOperation = item.icon
    if (item.action) {
      item.action()
    }
    if (item.external) {
      navUtils.goToExternal(item.url, {newWindow: true})
    } else {
      navUtils.goToInternal(item.url)
    }
  }
}
var OperationBarModel = new OperationBarMobx()
export default observer(OperationBarModel)
