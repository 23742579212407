import React from 'react'
import FintupButton from '../common/forms/FintupButton'
import { FormattedMessage, injectIntl } from 'react-intl'
import { doUploadDoc } from '../../utils/fintup-api'
import FintupComponent from '../FintupComponent'
import Dropzone from 'react-dropzone'
import constants from '../../utils/constants'
import SessionController from '../../core/SessionMobx'
import classNames from 'classnames'
import transverser from '../../utils/transverser'
import navUtils from '../../core/navUtils'
import './FileUpload.scss'
import RSVP from 'rsvp'
import FintupIcon from '../common/FintupIcon'
import { observer } from 'mobx-react'
import Spinner from 'react-spinkit'
import _ from 'lodash'

class FileUpload extends FintupComponent {
  /**
   * fileData: Datos del fichero que llega desde el servicio api/files GET
   * canUpdate: Indica si desde el componente se puede subir / actualizar el documento
   * uploadDisableMessageKey: Mensaje que se muestra cuando la subida esta deshabilitada por defecto document.cantUpdate.peding.message -> Pendiente de subir por Fintup
   * allowPdf: Permite subir pdfs
   * allowImage: Permite subir imagenes png y jpeg
   * aditionalParams: Para pasar paametros a los literales
   * isLoading: Para mostrar un loading en el caso de nuevo estilo cuando estan cargando cosas
   *
   */
  constructor (props) {
    super(props)

    let imageTypes = [
      {
        type: 'image/jpeg',
        extension: 'jpg'
      },
      {
        type: 'image/png',
        extension: 'png'
      }]
    let pdfTypes = [
      {
        type: 'pdf',
        extension: 'pdf'
      },
      {
        type: 'application/pdf',
        extension: 'pdf'
      }]

    let resultTypes = []
    if (this.props.allowImage) {
      resultTypes = resultTypes.concat(imageTypes)
    }

    if (this.props.allowPdf) {
      resultTypes = resultTypes.concat(pdfTypes)
    }

    this.state = {
      fileData: props.fileData,
      fileTypes: resultTypes.map(resultType => resultType.type).join(','),
      allowedExtenstions: _.uniq(resultTypes.map(resultType => resultType.extension)).join(','),
      isLoading: false,
      errorText: ""
    }
  }

  // El que viene de las propiedades afecta en general a todo el del estado afecta solo al componente
  get showLoading () {
    return this.props.isLoading || this.state.isLoading
  }

  downloadDocument () {
    let sessionToken = SessionController.token
    navUtils.openPrivateWindow(this.state.fileData.uriDownload, sessionToken)
  }
  get postUploadAction () {
    return this.props.postUpload ? this.props.postUpload : () => RSVP.resolve()
  }

  get showDocumentName () {
    return transverser.get('showDocumentName', false)(this.props)
  }

  get canUpdate () {
    return transverser.get('canUpdate', true)(this.props)
  }

  onDrop (accepted, rejected) {
    console.log('soltamos el documento')
    this.setStatePromise({errorText: ''})
    if (accepted.length > 1) {
      this.setStatePromise({errorText: 'Solo es posible subir un documento'})
      return false
    }
    if (rejected.length > 0) {
      this.setStatePromise({errorText: `Ha ocurrido un error subiendo el fichero, solo es posible subir ficheros con las extensiones ${this.state.allowedExtenstions}`})
      return false
    }

    let formData = new FormData()
    formData.append('file', accepted[0])
    formData.append('fileId', this.state.fileData.id || this.state.fileData.documentType)
    if (this.state.fileData.operationId !== null && !_.isUndefined(this.state.fileData.operationId)) {
      formData.append('operationId', this.state.fileData.operationId)
    }
    this.setStatePromise({isLoading: true})
      .then(() => doUploadDoc(formData))
      /*.then(() => {
        //doUploadDoc(formData))
        //MOCK
        let a = {data: {"result":{"code":"OK","message":null},
        "uploadFileResponse":{"fileName":"DOCUMENT_FRONT","fileType":"image/jpeg","size":92507,"fileId":"DOCUMENT_FRONT",
        "uriDownload":"http://spreapi.fintup.com/api/file/downloadFile/DOCUMENT_FRONT"},"additionalData":null}}
        return a
      })*/
      .then((response) => {
        console.log("RESPONSE: ", response)
        let fileData = this.state.fileData
        fileData.fileName = response.data.uploadFileResponse.fileName
        fileData.id = response.data.uploadFileResponse.fileId
        fileData.documentStateType = constants.FILE_STATE.UPLOADED
        fileData.uriDownload = response.data.uploadFileResponse.uriDownload
        return this.setStatePromise({fileData: fileData})
      })
      .then(() => this.postUploadAction(this.state.fileData))
      .catch(error => this.handleServiceError(_.merge(error, {jsAlert: true})))
      .finally(() => this.setStatePromise({isLoading: false}))
  }

  renderUploadedDocument () {
    return this.renderUploadedDocumentNewStyle()
  }

  get uploadButtonClasses () {
    return classNames(
      'col-xs-12',
      'col-sm-6',
      'documentButtons'
    )
  }

  sendAction () {
    if (this.props.sendAction) {
      this.props.sendAction(this.state.fileData)
    }
  }
  
  //Check if is valid or not
  get resultText(){
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    let documentText = this.props.intl.formatMessage({ id: documentTypeTextKey })
    return this.state.errorText ? 
    (
      <div>
        <span className="icon-wrapper"><i className="fa fa-times"></i></span>
        <span className="result">
          {this.state.errorText}
        </span>
      </div>
    ) : 
    (
      <div>
        <span className="icon-wrapper"><i className="fa fa-check"></i></span>
        <span className="result">
          <FormattedMessage id="document.uploadedOk" values={{document: documentText}}/>
        </span>
      </div>
    )
  }

  renderUploadedDocumentNewStyle () {
    
    return (
      <div className="row">
        <div>
          <Dropzone className="documentUploadContainer" onDrop={this.onDrop.bind(this)} disabled={this.showLoading} accept={this.state.fileTypes}>
            <div className="documentContainer">
              <div className="documentInner">
                {this.showLoading ? (
                  <div className="content">
                    <Spinner name='circle' fadeIn='none' />
                  </div>
                ) : (
                  <div className="content">
                    {this.resultText}
                  </div>
                )}
              </div>
            </div>
          </Dropzone>
          <div className="documentFooter">
            <FintupIcon icon={this.state.fileData.icon}/>
            <a href={`${this.state.fileData.uriDownload}?token=${SessionController.token}`} target="_blank" rel="noopener noreferrer"><FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`}/></a>
          </div>
        </div>
      </div>
    )
  }

  renderNewStylePendingDocument () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    let documentText = this.props.intl.formatMessage({ id: documentTypeTextKey })
    let fileIcon = this.props.allowImage && this.props.allowPdf
      ? (
        <div className="iconsFileUpload">
          <FintupIcon size="40" icon="jpg-icon" color="newColor"/>
          <FintupIcon size="50" icon="pdf-icon" color="newColor"/>
          <FintupIcon size="40" icon="png-icon" color="newColor"/>
        </div>
      )
      : ''
    return (
      <div>
        <div className="documentContainer">
          <div className="documentInner">
            {this.showLoading ? (
              <div className="content">
                <Spinner name='circle' fadeIn='none' />
              </div>
            ) : (
              <div className="content">
                {fileIcon}
                <p><FormattedMessage id="document.dropDocument" values={{document: documentText}}/></p>
                <p className="or"><FormattedMessage id="document.dropDocumentOr"/></p>
                <FintupButton fintupIcon="cloud" textKey="document.button.findFile" size={constants.BUTTON_SIZE.XLARGE} />
              </div>
            )}
          </div>
        </div>
        <div className="documentFooter">
          <FintupIcon icon={this.state.fileData.icon}/>
          <FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`}/>
        </div>
      </div>
    )
  }

  renderPendingDocument () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    return (
      <div className="row">
        { this.canUpdate ? (
          <Dropzone className="documentUploadContainer" onDrop={this.onDrop.bind(this)} disabled={this.showLoading} accept={this.state.fileTypes}>
            {this.props.newStyle ? this.renderNewStylePendingDocument()
              : (
                <div>
                  <div className="col-xs-12 col-sm-6 documentText">
                    <p><FormattedMessage id={documentTypeTextKey} values={this.props.aditionalParams}/></p>
                    { this.renderFileDescription }
                  </div>
                  <div>
                    <FintupButton className="fLeft" buttonClassName="alt" textKey="document.button.upload" size={constants.BUTTON_SIZE.HALF_DOUBLE_SMALL} />
                  </div>
                </div>
              )
            }
          </Dropzone>
        ) : (
          <div>
            <div className="col-xs-12 col-sm-6 documentText">
              <p><FormattedMessage id={documentTypeTextKey} values={this.props.aditionalParams}/></p>
              { this.renderFileDescription }
            </div>
            <div className={`${this.uploadButtonClasses} documentButtonsText`}>
              <FormattedMessage className="pending" id={this.props.uploadDisableMessageKey || 'document.cantUpdate.peding.message'} />
            </div>
          </div>
        ) }
      </div>
    )
  }

  get renderFileDescription () {
    if (this.props.showFileDescription) {
      return <p className="fileDescription"><FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`} /></p>
    } else {
      return ''
    }
  }

  render () {
    let componentClases = classNames(
      'document',
      {'newStyle': true},
      {[`theme-${this.props.theme}`]: this.props.theme},
    )
    return (
      <div className={componentClases}>
        {this.state.fileData.documentStateType === constants.FILE_STATE.UPLOADED
          ? this.renderUploadedDocument()
          : this.renderPendingDocument()}
      </div>
    )
  }
}

export default injectIntl(observer(FileUpload))
