import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker from './registerServiceWorker'
import FintupInt from './components/FintupInt'
import TagManager from 'react-gtm-module'
import config from './config/config'
import hotconfig from './config/hotConfig'
import RSVP from 'rsvp'
import OperationBarModel from './components/common/OperationBarModel'

hotconfig.init()
  .then(loadTagManager)
  .then(() => OperationBarModel.loadOperationBarItems())
  .then(initApp)
  .then(initServiceWorker)
  .catch(error => console.log(error))
  .finally(() => console.log('carga inicial finalizada'))

function loadTagManager () {
  if (config.enableTagManager) {
    const tagManagerArgs = {
      gtmId: config.googleTagManager
    }
    console.log('cargamos el tag manager')
    TagManager.initialize(tagManagerArgs)
  }
  return RSVP.resolve()
}

function initApp () {
  console.log('Init app')
  ReactDOM.render(<FintupInt />, document.getElementById('root'))
  return RSVP.resolve()
}

function initServiceWorker () {
  // registerServiceWorker()
  return RSVP.resolve()
}
