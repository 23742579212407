import uniqid from 'uniqid'
import { NativeEventSource, EventSourcePolyfill } from 'event-source-polyfill'
import SessionController from '../core/SessionMobx'
import _ from 'lodash'

const EventSource = NativeEventSource || EventSourcePolyfill

export const eventPool = []

function formatMessage (message, opts) {
  let messageFormated = message
  if (opts.messageFormat === MESSAGE_FORMAT.JSON) {
    messageFormated = JSON.parse(message)
  }
  return messageFormated
}

/**
 *
 * opts:
 *  messageFormat: Formato del mensaje por se obtiene de la constante MESSAGE_FORMAT
 */
const eventHelper = function (url, onMessageFunction, onFinishFunction, opts) {
  let finalUrl = SessionController.isEmulatedSession
    ? `${url}&emulatedToken=${SessionController.token}`
    : url
  let evtSource = new EventSource(finalUrl)
  let options = opts || {}
  let idEvent = uniqid
  let eventSourceObject = {
    idEvent: idEvent,
    close: function () {
      evtSource.close()
      _.remove(eventPool, item => {
        return item.idEvent === idEvent
      })
    }
  }

  evtSource.onmessage = function (e) {
    console.log('Recibido mensaje del stream')
    let message = formatMessage(e.data, options)
    if (onMessageFunction) {
      onMessageFunction(message, eventSourceObject)
    } else {
      console.log(message)
    }
  }

  evtSource.onerror = function () {
    console.log('Fin del stream')
    evtSource.close()
    if (onFinishFunction) {
      onFinishFunction()
    }
  }

  eventPool.push(eventSourceObject)
  return eventSourceObject
}

export const MESSAGE_FORMAT = {
  JSON: 'JSON'
}

export default eventHelper
