import React from 'react'
import './DatePickerLabel.scss'
import { injectIntl } from 'react-intl'
import DatePicker from 'react-date-picker'
import classNames from 'classnames'
import FintupComponent from '../../FintupComponent'

class DatePickerLabel extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value
    }
  }

  handleChange (data) {
    //console.log("HANDLE CHANGE COMPONENT: ", data)
    let fieldValue = data
    this.setStatePromise({value: fieldValue})
    if (this.props.callBackOnChange) {
      let data = {
        ev: {
          target: {
            name: this.props.name,
            value: fieldValue
          }
        },
        name: this.props.name,
        value: fieldValue,
      }
      this.props.callBackOnChange(data)
    }
  }

  render () {
    let classContainer = classNames('datePickerLabel', this.props.className)
    return (
      <DatePicker 
        className={classContainer}
        showLeadingZeros={true}
        value={this.props.value}
        disableCalendar={true}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
        dayPlaceholder={"DD"}
        monthPlaceholder={"MM"}
        yearPlaceholder={"AAAA"}
        name={this.props.name}
        onChange={this.handleChange.bind(this)}
        clearIcon={null}/>
    )
  }
}

export default injectIntl(DatePickerLabel)