import React, { Component } from 'react'
import './FintupForm.css'

class FintupForm extends Component {
  onSubmitForm (ev) {
    ev.preventDefault()
  }

  render () {
    return (
      <form className={this.props.className} onSubmit={this.onSubmitForm.bind(this)}>
        {this.props.children}
      </form>
    )
  }
}

export default FintupForm
