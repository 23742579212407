import React from 'react'
import FintupView from "../FintupView"
import MultipleFormContainer from "../common/MultipleFormContainer"
import SessionController from "../../core/SessionMobx"
import AdviserModel from '../common/AdviserModel'
import MiniTestStep0 from './MiniTestStep0'
import MiniTestStep1 from './MiniTestStep1'
import './MiniTestView.scss'
import MiniTestStep3 from './MiniTestStep3'
import MiniTestStep4 from './MiniTestStep4'
import MiniTestStep5 from './MiniTestStep5'
import MiniTestStep6 from './MiniTestStep6'
import RegisterView from '../register/RegisterView'
import MiniTestRegister from './MiniTestRegister'
import MiniTestProductLanding from './MiniTestProductLanding'
import { profileGet } from '../../utils/fintup-api'
import _ from 'lodash'
import RSVP from 'rsvp'
import transverser, { tGet } from '../../utils/transverser'
import config from '../../config/config'
import constants from '../../utils/constants'
import HeaderTags from '../common/HeaderTags'
import RegisterNew from '../register/RegisterNew'
import ContractGeneralView from '../contract-funnel/ContractGeneralView'
import navUtils from '../../core/navUtils'
import urls from '../../utils/urls'
import FintupFabExpert from '../common/forms/FintupFabExpert'


class MiniTestView extends FintupView {
  constructor(props) {
    super(props)
    this.state = {
      actualStep: 1,
      generalStep: 1,
      renderContent: false,
      isPrivateSession: SessionController.isLogged(),
      initialModel: {
      }
    }
  }

  componentDidMount() {
    if (this.state.isPrivateSession) {
      console.log("ComponentDidMount is private session")
    } else {
      console.log("ComponentDidMount is public session")
      AdviserModel.loadAdviser()
        .then(() => this.setStatePromise({ renderContent: true }))
    }
  }

  setGeneralStep(generalStepIndex) {
    return this.setStatePromise({ generalStep: generalStepIndex })
  }

  render() {
    if (this.state.renderContent) {
      return this.renderForm()
    } else {
      return ''
    }
  }

  renderForm() {
    let stepList = [
      { //Step 1: (Financial Freedom or I Have a goal) 
        stepView: <MiniTestStep0 name="MiniTestStep0" />,
        generalStep: 1
      },
      { //Step 2 (Years do you want to invest) 
        stepView: <MiniTestStep1 name="MiniTestStep1" />,
        generalStep: 2
      },
      /*{ //Step 1.a:  (Choose type I Have a goal) -> next is Step 2
        stepView: <MiniTestStep2 name="MiniTestStep2"/>,
        generalStep: 3
      },*/
      { //Step 3: (Amount do you want to invest)
        stepView: <MiniTestStep3 name="MiniTestStep3" />,
        generalStep: 3
      },
      { //Step 4: (Type of investor)
        stepView: <MiniTestStep4 name="MiniTestStep4" />,
        generalStep: 4
      }, { //Step 5: (Summary)
        stepView: <MiniTestStep5 name="MiniTestStep5" />,
        generalStep: 5
      },
      {
        stepView: <MiniTestProductLanding name="MiniTestStep6" />,
        generalStep: 6
      },
      /*{
        stepView: <RegisterNew name="MiniTestStep7" minitest={true} showBreadcrumbs={true}/>,
        generalStep: 7
      },*/
      {
        stepView: <MiniTestRegister name="MiniTestStep7" showBreadcrumbs={true} />,
        generalStep: 7
      }]
    return (
      <div className="miniTestView">
        <HeaderTags title="headerTags.minitest.title" description="headerTags.minitest.description"></HeaderTags>
        <MultipleFormContainer
          initialModel={this.state.initialModel}
          stepsMarker={this.stepsMarker}
          updateStepMarker={this.setGeneralStep.bind(this)}
          name={this.getPath()}
          actualStep={this.state.actualStep}
          steps={stepList}
          webMode={true}
          ref={ref => (this.multipleForm = ref)} />
        <FintupFabExpert></FintupFabExpert>
      </div>
    )
  }
}

export default MiniTestView