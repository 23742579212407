/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import './FintupTooltip.scss'
import ReactTooltip from 'react-tooltip'
import FintupComponent from '../FintupComponent'
import classNames from 'classnames'

/*
  PROPS:
    tooltipId: id que hará que se abra el tooltip
    className: clases que se añadiran al tooltip ademas de fintupTooltip
*/
class FintupTooltip extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  get componentClasses () {
    return classNames(
      'fintupTooltip',
      this.props.className
    )
  }

  render () {
    return (
      <ReactTooltip className='fintupTooltip' id={this.props.tooltipId} aria-haspopup="true" role='example'>
        {this.props.children}
      </ReactTooltip>
    )
  }
}

export default FintupTooltip
