import chartColors from './chartColors'

let constants = {
  phones: {
    PHONE1_CALL: '+34910758375',
    PHONE1: '910758375',
    PHONE2_CALL: '+34622912177',
    PHONE2: '622912177',
  },
  alertType: {
    SUCCESS: 'SUCCESS',
    ERROR: 'ERROR'
  },
  NOTIFICATION_TYPE: {
    SUCCESS: "success",
    ERROR: "danger",
    INFO: "info",
    "DEFAULT": "default",
    "WARNING": "warning"
  },
  CONMUTADOR_TYPE: {
    ROUNDED: 'ROUNDED'
  },
  BUTTON_SIZE: {
    FREE: 'FREE',
    SMALL: 'SMALL',
    DOUBLE_SMALL: 'DOUBLE_SMALL',
    HALF_DOUBLE_SMALL: 'HALF_DOUBLE_SMALL',
    LARGE: 'LARGE',
    DOUBLE_LARGE: 'DOUBLE_LARGE',
    XLARGE: 'XLARGE',
    XXLARGE: 'XXLARGE',
    XXXLARGE: 'XXXLARGE',
    FULL: 'FULL',
    ONLY_ICON: 'ONLY_ICON'
  },
  CHART_COLORS: chartColors,
  CONTRACT: {
    ACCOUNT: {
      HEIRS: {
        HEIRS: 'H',
        DESIGNATION: 'D'
      },
      BENEFICIARIES: {
        MAX_BENEFICIARIES: 5
      }
    }
  },
  COOKIE: {
    LANGUAGE: 'fintupLanguage',
    TERMS: 'fintupAppCookies',
    TERMS_EXPIRED_DAYS: 60
  },
  GLOBAL_POSITION: {
    RENDER_TYPE: {
      DATA: 'DATA',
      EXTENDED: 'EXTENDED_DATA'
    }
  },
  MANAGERIMAGE: {
    DIRECTION: {
      BOTTOM: 'arrowBottom',
      RIGHT: 'arrowRight'
    }
  },
  MAP: {
    THEME: {
      DARK: 'grayScaleDarkMap',
      LIGHT: 'garyScaleLightMap'
    }
  },
  CHARS: {
    SLASH: '/',
    EMPTY: '',
    BLANK: ' ',
    COMMA: ','
  },
  FILE_STATE: {
    UPLOADED: 'UPLOAD_OK',
    PENDING: 'PENDING'
  },
  PRODUCT_STATE: {
    DOCUMENT_PENDING: 'PENDIENTE_DOCUMENTACION'
  },
  ACCORDION: {
    SIDE_LEFT: 1,
    SIDE_RIGHT: 2
  },
  EMAIL_VALIDATE: {
    TOKEN_SPLIT: '::'
  },
  RESULT: {
    OK: 'OK'
  },
  SLIDERFORMATTER: {
    AMOUNT: 1,
    YEAR: 2
  },
  FORMS: {
    exigencias: 's8ldr60pop7kv4knvdf97tkmn0',
    adecuacion: 'b54eh5797gg58r9ke8sg9fuooh',
    idoneidad: 'ai7k83550g2mf191h3jc3dh7dm'
  },
  NOTIFICATIONS: {
    READ: 'READ',
    UNREAD: 'UNREAD',
    TYPES: {
      OPERATIVE: 'OPERATIVE',
      GENERAL: 'GENERAL',
      BASIC: 'BASIC'
    }
  },
  OPERATION_STATE: {
    COMPLETE: 'COMPLETE',
    ERROR: 'ERROR'
  },
  ACADEMY_VIEW: {
    CATEGORIES: 0,
    OPTIONS: 1,
    PLAYER: 2
  },
  REGISTER: {
    ORIGIN: {
      SUITABILITY: 'FINTUPSUITABILITY',
      MINITEST: 'FINTUPMINITEST'
    }
  },
  DOUBLE_CONTAINER_SIZE: {
    SMALL: 'small',
    BIG: 'big'
  },
  SELECT_OPTIONS: {
    PERIODIC_TYPE: [
      {
        label: 'requestDataPensionPlan.periodicType.monthly',
        value: '1'
      },
      {
        label: 'requestDataPensionPlan.periodicType.quarterly',
        value: '3'
      },
      {
        label: 'requestDataPensionPlan.periodicType.biannual',
        value: '6'
      },
      {
        label: 'requestDataPensionPlan.periodicType.annual',
        value: '12'
      }
    ]
  },
  PERIODICITY: [
    {
      label: 'requestDataPensionPlan.periodicType.monthly',
      value: 'MONTHLY'
    },
    {
      label: 'requestDataPensionPlan.periodicType.quarterly',
      value: 'QUARTERLY'
    },
    {
      label: 'requestDataPensionPlan.periodicType.biannual',
      value: 'BIANNUAL'
    },
    {
      label: 'requestDataPensionPlan.periodicType.annual',
      value: 'ANNUAL'
    }
  ]
}

export default constants
