import config from '../config/config'

let urls = {
  image: {
    logo: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/logo-web-fintup.png',
    logoV2: '/img/logo-fintup-horizontal.svg',
    logoV2Mobile: '/img/fintup-logo-white.svg',
    logoV2MobileSmall: '/img/fintup-logo-solo-icono.svg',
    logoBlancoTransparente: '/img/fintup-logo-white.svg'
  },
  external: {
    terms: {
      dataProcessingTerms: 'https://fintup.com/aviso-legal/#tratamiento-datos',
      communicationTerms: 'https://fintup.com/aviso-legal/#tratamiento-datos',
      profilesTerms: 'https://fintup.com/aviso-legal/#tratamiento-datos'
    },
    operationBar: {
      academy: 'https://fintup.com/blog/',
      date: 'https://bit.ly/2yMQ0VO',
      invest: 'https://app.fintup.com/invest',
      reviews: 'https://www.google.com/search?q=fintup&oq=fintup&aqs=chrome..69i57j0l3j69i60l4.1257j0j7&sourceid=chrome&ie=UTF-8#lrd=0xd4229611a6c26b1:0xaf1dfea19bcbf9cd,1',
      million: 'https://fintup.com/herramientas/calculadora-ahorro-jubilacion',
      compoundInterest: 'https://fintup.com/herramientas/calculadora-interes-compuesto',
      minitest: 'https://app.fintup.com/test-de-inversion',
    },
    headerAndFooter: {
      fintup: {
        home: () => {
          return config.homeUrl || 'https://fintup.com/'
        },
        fintup: 'https://fintup.com/filosofia-de-inversion/',
        rates: 'https://fintup.com/tarifas/',
        blog: 'https://fintup.com/blog/',
        contact: 'https://fintup.com/contacto/',
        faq: 'https://fintup.com/faq/',
        invest: 'https://fintup.com/invierte-ahora/',
        clients: 'https://fintup.com/clientes/',
      },
      legal: {
        notice: 'https://fintup.com/aviso-legal/',
        privacyPolicy: 'https://fintup.com/politica-de-privacidad/',
        cookies: 'https://fintup.com/cookies/'
      },
      social: {
        twitter: 'https://twitter.com/fintup',
        linkedin: 'https://www.linkedin.com/company/fintup',
        instagram: 'https://www.instagram.com/fintup/'
      },
      img: {
        appleStore: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/apple-store.png',
        googlePlay: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/google-play.png',
        logoFooter: 'https://s3-eu-west-1.amazonaws.com/cdn.fintup.com/images/xlogoweb-footer.png'
      },
    }
  },
  internal: {
    login: '/',
    profile: '/mi-perfil',
    mainContractData: '/datos-generales-contratacion/{param}',
    mainFunnelContractData: '/contratacion/{param}', //new funnel
    contract: '/contratar',
    tools: '/herramientas',
    invite: '/invitar-usuario',
    initUrl: '/posicion-global',
    expert: '/mi-experto',
    expertDate: '/mi-experto/cita',
    targets: '/mis-objetivos',
    documents: '/posicion-global/documents',
    changePassword: '/change-password',
    //notifications: '/notifications',
    reactivate: '/reactivate',
    convenience: '/convenience',
    invest: '/suitability',
    investPrivate: '/invertir-usuario',
    minitest: '/test-de-inversion',
    simulatePrivate: '/simulador-usuario',
    helpdesk: '/helpdesk',
    contribution: '/aportacion/{param}/{param}',
    reimburstment: '/trabajando',
    register: () => config.registerUrl || '/registrar',
    academy: '/academia',
    contractSaving: '/contratar/ahorro',
    contractUl: '/contratacion/inversion', //new funnel
    contractPensionPlan: '/contratar/planes-pensiones',
    activationCode: '/codigo-activacion',
    signGateway: '/solicitud-firma',
    notifications: '/notificaciones'
  }
}

export default urls
