import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import urls from '../../utils/urls'
import navUtils from '../../core/navUtils'

class NavPrivateModelMobx {
  constructor () {
    extendObservable(this, {
      myInvest: {
        active: true,
        url: urls.internal.initUrl
      },
      myExpert: {
        active: false,
        url: urls.internal.expert
      },
      invite: {
        active: false,
        url: urls.internal.invite
      },
      profile: {
        active: false,
        url: urls.internal.profile
      }
    })
  }

  disableItems () {
    this.myInvest.active = false
    this.myExpert.active = false
    this.invite.active = false
    this.profile.active = false
  }

  getNavItem (itemName) {
    let item = this[itemName]
    return item
  }

  activate (activeItem) {
    this.disableItems()
    let navItem = this.getNavItem(activeItem)
    navItem.active = true
  }

  changeMenu (activeItem) {
    this.activate(activeItem)
    let navItem = this.getNavItem(activeItem)
    navUtils.goToInternal(navItem.url)
  }
}
var NavPrivateModel = new NavPrivateModelMobx()
export default observer(NavPrivateModel)
