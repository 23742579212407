/* global Calendly */
import React from 'react'
import FintupComponent from '../FintupComponent'
import utils from '../../utils/utils'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'
import config from '../../config/config'
import SessionController from '../../core/SessionMobx'
import './FintupCalendly.scss'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import RSVP from 'rsvp'
import AdviserModel from './AdviserModel'
import { registerAdviserQuestion } from '../../utils/fintup-api'
import ManagerImage from '../suitability/ManagerImage'

/**
 * Props:
 *  containerClases: Clases que se le pasan al contenedor del componente
 *  model: Tipo de solicitud calendly que se realiza
 *    Valor 3: Solicitud de llamada con el asesor y correo de dudas
 *    Valor 4: Solicitud de llamada con Diana para resolver dudas
 *  origin: Origen donde se muestra el componente para poder registra el origen de los clicks
*/
class FintupCalendly extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      calendlyFilesLoaded: false
    }
  }
  componentDidMount () {
    if (config.calendlyEnable) {
      if (SessionController.scriptsLoaded.calendly) {
        this.setStatePromise({ calendlyFilesLoaded: true })
      } else {
        let isPrivateSession = SessionController.isLogged()
        let promisesHash = {
          loadCss: utils.loadCss('https://assets.calendly.com/assets/external/widget.css'),
          loadScript: utils.loadScript('https://assets.calendly.com/assets/external/widget.js')
        }

        if (isPrivateSession) {
          promisesHash.adviser = AdviserModel.loadAdviser()
        }

        RSVP.hash(promisesHash)
          .then(() => {
            SessionController.scriptsLoaded.calendly = true
            return this.setStatePromise({ calendlyFilesLoaded: true })
          })
          .catch(error => console.log(error))
      }
    }
  }

  get renderDefaultModel () {
    let containerClases = classNames(this.props.containerClases)
    return <FintupButton className={containerClases} textKey="Calendly.button" size={constants.BUTTON_SIZE.XXLARGE} action={() => { Calendly.showPopupWidget(config.calendlyUrl) }} />
  }

  get renderModel2 () {
    let containerClases = classNames(this.props.containerClases, 'fintupCalendly2')
    return (
      <div className={containerClases}>
        <h1><FormattedMessage id="Calendly.title"/></h1>
        <FintupButton fintupIcon="phone" textKey="Calendly.model2.button" size={constants.BUTTON_SIZE.XXLARGE} action={() => { Calendly.showPopupWidget(config.calendlyUrl) }} />
      </div>
    )
  }

  registerClick (registerType) {
    let requestRegister = {
      type: registerType,
      origin: this.props.origin
    }
    return registerAdviserQuestion(requestRegister)
  }

  get renderModel3 () {
    let containerClases = classNames(this.props.containerClases, 'fintupCalendly3')
    let mailTo = AdviserModel.adviser.email
    let mailSubject = 'Duda sobre Fintup'
    let mailBody = `Hola ${AdviserModel.adviserName}, `
    let questionMailLink = `mailto:${mailTo}?subject=${mailSubject}&body=${mailBody}`
    return (
      <div className={containerClases}>
        <FintupButton
          size={constants.BUTTON_SIZE.XLARGE}
          fintupIcon="phone2"
          textKey="myExpertView.button.call"
          action={() => {
            this.registerClick('CALL')
            Calendly.showPopupWidget(AdviserModel.adviser.callLink)
          }}/>
        <FintupButton
          size={constants.BUTTON_SIZE.XLARGE}
          fintupIcon="mail"
          textKey="myExpertView.button.mail"
          action={() => {
            this.registerClick('MAIL')
            window.open(questionMailLink)
          }}/>
      </div>
    )
  }

  get renderModel4(){
    let containerClases = classNames(this.props.containerClases, 'fintupCalendly4')
    return (
      <div className={containerClases} onClick={() => { Calendly.showPopupWidget(config.calendlyUrl) }}>
        <ManagerImage className="text-center"/>
        <FormattedMessage id="miniTestProductLanding.doubts.text1"/>
      </div>
    )
  }

  get calendyRender () {
    return this.state.calendlyFilesLoaded
      ? this.props.model === 2
        ? this.renderModel2
        : this.props.model === 3
          ? this.renderModel3
          : this.props.model === 4 
            ? this.renderModel4
            : this.renderDefaultModel
      : ''
  }

  render () {
    return (
      <div className="FintupCalendly">
        {this.calendyRender}
      </div>
    )
  }
}

export default FintupCalendly
