import React from 'react'
import { injectIntl } from 'react-intl'
import FintupComponent from '../../FintupComponent'
import FontAwesome from 'react-fontawesome'
import classNames from 'classnames'
import './Checkbox.scss'
/*
  Props:
    className: Clases que se pondran en la raiz del componente
    disabled: Deshabilita el campo
    tooltipId: Para poder mostrar un tooltip contenido en otro componente
    theme: 'new'
    * 'new': Nuevo estilo de componentes similar al diseño minitest
*/
class Checkbox extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
  }

  handleChange (ev) {
    if (this.props.disabled) {
      return false
    }
    let data = {
      ev: ev,
      name: this.props.name,
      value: !this.props.value,
      extraData: this.props.extraData
    }
    this.props.onChangeCheckbox(data)
  }

  get labelText () {
    let text = this.props.textKey ? this.props.intl.formatMessage({ id: this.props.textKey }) : this.props.text
    return text
  }

  get componentClasses () {
    return classNames(
      'fintupCheckbox',
      'checkbox',
      this.props.titleCheckbox ? 'titleCheckbox' : '',
      {[`theme-${this.props.theme}`]: this.props.theme},
      this.props.className
    )
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <label htmlFor={this.props.name}>
          <input disabled={this.props.disabled} type="checkbox" id={this.props.name} name={this.props.name} defaultChecked={this.props.value} onClick={this.handleChange.bind(this)}/>
          <span className="cr"><FontAwesome name="check" className="cr-icon"/></span>
          <span data-tip data-for={this.props.tooltipId}>{this.labelText}</span>
        </label>
      </div>
    )
  }
}

export default injectIntl(Checkbox)
