import { doGetPhrases } from '../../utils/fintup-api'
import { extendObservable } from 'mobx'
import utils from '../../utils/utils'
import RSVP from 'rsvp'
class PhrasesModel {
  constructor () {
    extendObservable(this, {
      phrases: null,
      randomPhrase: '',
      randomAuthor: ''
    })
  }

  loadPhrases () {
    if (this.phrases == null) {
      return doGetPhrases().then((response) => {
        this.phrases = response.data.phrases
        return RSVP.resolve()
      })
    } else {
      return RSVP.resolve()
    }
  }

  updatePhrase () {
    this.loadPhrases()
      .then(() => {
        let randomPhraseObj = this.phrases[utils.randomNumber(this.phrases.length)]
        this.randomPhrase = randomPhraseObj.phrase
        this.randomAuthor = randomPhraseObj.author
      })
  }
}

var PhrasesController = new PhrasesModel()
export default PhrasesController
