import { extendObservable } from 'mobx'
import { getGlobalPositionData } from '../utils/fintup-api'
import RSVP from 'rsvp'
import _ from 'lodash'

/**
 * Crea una sesion con los productos del usuario
 * updateRefresh: Realiza un 'conteo' con las actualizaciones que se realizan sobre los productos del usuario
 * updateProductAlias: Realiza la actualizacion de un alias de producto
 * setGlobalPositionData: Establece la posicion global del usuario
 * initProducts: Inicializa los productos de la posicion global
 * getProductsByProductId: Obtiene el listado de productos filtrados por un productId concreto
 * existsProductsByProductId: Indican si existen productos de una categoria concreta
 * destroyProducts: Destruye la sesion de productos
*/
class ProductsMobx {
  constructor() {
    extendObservable(this, {
      refreshField: 0,
      productsLoaded: false,
      productsData: extendObservable({})
    })
  }

  get getProducts() {
    return this.productsData.products || []
  }

  findProductByPolicyNumberAndContractId(data) {
    let findedProduct = this.getProducts.find(product => {
      return product.policyNumber === data.policyNumber && product.productId === parseInt(data.productId)
    })
    return findedProduct
  }

  getProductsByProductId(productId) {
    let filterProducts = this.getProducts.filter(product => product.productId === productId)
    return filterProducts
  }

  existsProductsByProductId(productId) {
    let product = _.find(this.getProducts, { productId: productId })
    return !_.isUndefined(product)
  }

  existsProducts() {
    return this.getProducts.length > 0
  }

  updateProductAlias(newAliasData) {
    let product = _.find(this.productsData.products, { productId: newAliasData.productId, policyNumber: newAliasData.policyNumber })
    product.alias = newAliasData.alias
    this.updateRefresh()
    return RSVP.resolve()
  }

  updateRefresh() {
    this.refreshField = this.refreshField + 1
  }

  setGlobalPositionData(newData) {
    if (_.isUndefined(this.productsData.products)) {
      this.productsData = extendObservable(newData)
      let calculatedProducts = this.productsData.products
        .map(this.generateProductExtendData)
        .sort((productA, productB) => productA.productOrder - productB.productOrder)
      this.productsData.products = extendObservable(calculatedProducts)
      this.updateRefresh()
    }
  }

  openCloseProduct(product) {
    product.extendData.isOpen = !product.extendData.isOpen
    this.updateRefresh()
  }

  generateProductExtendData(product) {
    let extendProduct = {
      ...product,
      extendData: {
        isOpen: false,
        loaded: false,
        activeTab: 0
      }
    }
    return extendObservable(extendProduct)
  }

  changeActiveTab(product, newTab) {
    product.extendData.activeTab = newTab
  }

  initProducts() {
    if (this.productsLoaded) {
      return RSVP.resolve()
    }
    return getGlobalPositionData().then((response) => {
      console.log("GLOBAL POSITION DATA: ", response)
      this.setGlobalPositionData(response.data)
      this.productsLoaded = true
      return RSVP.resolve()
    })
  }

  destroyProducts() {
    this.productsLoaded = false
    this.productsData = extendObservable({})
    return RSVP.resolve()
  }

  reloadProducts() {
    return this.destroyProducts()
      .then(() => this.initProducts())
  }
}
var ProductsController = new ProductsMobx()
export default ProductsController
