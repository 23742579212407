import React from 'react'
import urls from "../../utils/urls"
import navUtils from "../../core/navUtils"
import './NavPublicNew.scss'
import utils from '../../utils/utils'
import classNames from 'classnames'
import FintupComponent from '../FintupComponent'
import config, { getBooleanConfig } from '../../config/config'
import { observer } from 'mobx-react'
import SessionController from '../../core/SessionMobx'
import FintupButton from '../common/forms/FintupButton'
import constants from '../../utils/constants'


class NavPublicNew extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  get componentClasses () {
    return classNames(
      {hidden: SessionController.isFullScreen},
      'fintupHeader'
    )
  }


  render () {
    return (
      <div className="navPublicNew">
        <div className="navPublicDesktop">
          <div className="fLeft">
            <img className="default-logo-new" src={config.privUrlLogo || urls.image.logoV2Mobile} alt="Fintup" role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} />
          </div>
          {
            getBooleanConfig('enableRegister') && SessionController.showRegisterButton ? 
            (<div className="ctaButton">
              <FintupButton type="submit" 
                size={constants.BUTTON_SIZE.LARGE} 
                textKey="regsterView.title" 
                action={() => navUtils.goToInternal(getBooleanConfig('enableRegister') ? urls.internal.register() : urls.internal.minitest)} />
            </div>) : ''
          }

          {
            SessionController.showLoginButton ? 
            (<div className="ctaButton2">
              <FintupButton type="submit" 
                size={constants.BUTTON_SIZE.LARGE} 
                textKey="Reactivate.init" 
                action={() => navUtils.goToInternal(urls.internal.login)} />
            </div>) : ''
          }
          
        </div>
      </div>
    )
  }
}

export default observer(NavPublicNew)
