import React from 'react'
import FintupComponent from "../FintupComponent";
import './MiniTestTabs.scss'
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import transverser from '../../utils/transverser'
import utils from '../../utils/utils'
import FintupButton from '../common/forms/FintupButton';
import constants from '../../utils/constants';
import navUtils from '../../core/navUtils';
import urls from '../../utils/urls';

/**
 * props: 
 * - values (props of the parent component)
 * - actualStep
 * - className: class CSS of the tabs container element 
 * - withButtonInvest: button Invest visible or not
 * - clickButton: event click buttonInvest
 * - blockNextItems: (true or false): Block the posibility of click to next items
 */
class MiniTestTabs extends FintupComponent {
  constructor (props) {
    super(props)
    //console.log("COMPONENT MINITESTTABS: ", props)
    this.state = {
      //props: props.values.props
      values: props.values.props,
      hrefInvest: urls.external.operationBar.date,
    }
  }

  /**
   * Check if we can access to the specific page
   * If the main field has value
   * @param {number} page 
   */
  clickItem(page){
    let valuePage
    switch(page){
      case 1:
      default:
        valuePage = transverser.get('model.objective', '-')(this.state.values)
        break;
      case 2:
        valuePage = transverser.get('model.years', '-')(this.state.values)
          break;
      case 3: 
        valuePage = transverser.get('model.contributionInitial', '-')(this.state.values)
        break;
      case 4: 
        valuePage = transverser.get('model.typeInvestor', '-')(this.state.values)
        break;
    }
    //console.log("ACTUAL STEP: ", this.props.actualStep)
    //console.log("PINCHO EN : ", page)
    //console.log("ESTOY EN : ", transverser.get('model.state.actualStep', '-')(this.state.values))
    //Check if block items is active
    if((this.props.blockNextItems && page < this.props.actualStep || !this.props.blockNextItems)
      && valuePage !== "-")
      this.state.values.goToStep(page)
  }

  getValueContribution(value){
    if(value === '-'){
      return value
    }
    else{
      return utils.formatCurrencyNoDecimals(value)
    }
  }

  render () {
    let containerClass = classNames('miniTestTabs', this.props.className, this.loadingButtonClass)
    let valueYears = transverser.get('model.years', '-')(this.state.values)
    let yearsText = ''
    if(valueYears !== '-')
      yearsText = <span className="titleMiniSmall"><FormattedMessage id="miniTestTab.title3a"/></span>
    let button = this.props.withButtonInvest ? 
            <FintupButton icon="play" textKey="miniTestProductLanding.button1" className="buttonContainer" buttonClassName="buttonClass" size={constants.BUTTON_SIZE.XLARGE} action={this.props.clickButton} /> : ""
    //console.log("PROPOS INVEST: ", this.props.withButtonInvest)
    return (
      <div className="contentTabs">
        <div className={containerClass}>
          <div className="miniTestTabsElement" onClick={() => this.clickItem(1)}>
            <p><FormattedMessage id={transverser.get('model.objectiveText', '-')(this.state.values)}/></p>
            <p><FormattedMessage id="miniTestTab.title1"/></p>
          </div>
          <div className="miniTestTabsElement" onClick={() => this.clickItem(2)}>
            <p>{transverser.get('model.years', '-')(this.state.values)}{yearsText}</p>
            <p><FormattedMessage id="miniTestTab.title3"/></p>
          </div>
          <div className="miniTestTabsElement" onClick={() => this.clickItem(3)}>
            <p>{this.getValueContribution(transverser.get('model.contributionInitial', '-')(this.state.values))}</p>
            <p><FormattedMessage id="miniTestTab.title4"/></p>
          </div>
          <div className="miniTestTabsElement" onClick={() => this.clickItem(3)}>
            <p>{this.getValueContribution(transverser.get('model.contributionMonthly', '-')(this.state.values))}</p>
            <p><FormattedMessage id="miniTestTab.title5"/></p>
          </div>
          <div className="miniTestTabsElement" onClick={() => this.clickItem(4)}>
            <p><FormattedMessage id={transverser.get('model.typeInvestorText', '-')(this.state.values)}/></p>
            <p><FormattedMessage id="miniTestTab.title2"/></p>
          </div>
        </div>
        {button}
      </div>
    )
  }
}

export default injectIntl(MiniTestTabs)
