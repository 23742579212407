let config = {
  hotConfigUrl: '/json/externalConfig_local.json',
  needLogin: true,
  apiBaseUrl: 'https://spreapi.fintup.com',
  cdnBaseUrl: 'http://localhost:3000',
  cdnEnviroment: 'pre',
  captchaKey: '6LfXyEIUAAAAAEMi3oYt092DjfoU1THcEbCQmsHM',
  captchaEnable: false,
  // showMap: false,
  googleAnalyticsEnabled: false,
  googleAnalytics: 'UA-115030746-2',
  googleAnalyticsOptions: {
    cookieDomain: 'none'
  },
  checkEmailInterval: 20000,
  enableTagManager: false,
  pensionPlanTransfer: true,
  enableRegister: true,
  newTools: true,
  newAcademy: true
}

export default config
