import React from 'react'
import urls from '../../utils/urls'
import utils from '../../utils/utils'
import navUtils from '../../core/navUtils'
import _ from 'lodash'
import ReactGA from 'react-ga'
import config from '../../config/config'
import FintupComponent from '../FintupComponent'
import transverser, { tGet } from '../../utils/transverser'
import constants from '../../utils/constants'
import RSVP from 'rsvp'
import classNames from 'classnames'
import Alert from '../common/Alert'
import './MultipleFormContainer.scss'
import LoadingMobx from '../../core/LoadingMobx'

/**
 * PROPS:
 *  webMode: Redirect to URL Web
 *  alertScroll: Si queremos hacer scroll arriba al mostrar el elemento general de errores
 */
class MultipleFormContainer extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      actualStep: this.props.actualStep,
      model: this.props.initialModel || {},
      alert: {}
    }
  }

  componentDidMount () {
    this.recordGaNavigation()
    let loadContainerPromise = this.props.initForm ? this.props.initForm() : RSVP.resolve({})
    loadContainerPromise
      .then(result => this.updateModel({initData: result.data}))
      .then(() => this.setStatePromise({loading: false}))
  }

  recordGaNavigation () {
    let stepName = transverser.get(`steps[${this.state.actualStep - 1}].stepView.props.name`)(this.props)
    let name = transverser.get('name', 'Navigation')(this.props)
    if (name.indexOf(constants.CHARS.SLASH) === 0) {
      name = name.replace(constants.CHARS.SLASH, constants.CHARS.EMPTY)
    }
    if (config.googleAnalyticsEnabled && stepName) {
      ReactGA.event({category: name, action: stepName})
    }
  }

  changeGeneralStep () {
    let generalStep = this.props.steps[this.state.actualStep - 1].generalStep
    if (generalStep) {
      return this.alert({})
        .then(() => this.props.updateStepMarker(generalStep))
    } else {
      return this.alert({})
    }
  }

  nextStep () {
    let actual = this.state.actualStep
    this.alert({})
      .then(() => this.changeStep(actual + 1))
      .then(this.changeGeneralStep.bind(this))
      .then(this.recordGaNavigation.bind(this))
  }

  prevStep () {
    let actual = this.state.actualStep
    this.alert({})
      .then(() => this.changeStep(actual - 1))
      .then(this.changeGeneralStep.bind(this))
      .then(this.recordGaNavigation.bind(this))
  }

  changeStep (newStep) {
    utils.scrollToTop()

    return LoadingMobx.unsetLoading()
      .then(() => this.setStatePromise({ actualStep: newStep }))
  }

  goToStep (stepNumber) {
    this.alert({})
      .then(() => this.changeStep(stepNumber))
      .then(this.changeGeneralStep.bind(this))
      .then(this.recordGaNavigation.bind(this))
  }

  goToStepName (stepName) {
    let stepIndex = _.findIndex(this.props.steps, step => {
      let name = tGet('stepView.props.name')(step)
      return name === stepName
    })
    if (stepIndex !== -1) {
      return this.alert({})
        .then(() => this.changeStep(stepIndex + 1))
        .then(this.changeGeneralStep.bind(this))
        .then(this.recordGaNavigation.bind(this))
    } else {
      return RSVP.reject()
    }
  }

  updateModel (newData) {
    let model = _.cloneDeep(this.state.model)
    let newModel = _.extend(model, newData)
    return this.setStatePromise({model: newModel})
  }

  getModel () {
    return this.state.model
  }

  get renderSteps () {
    return this.props.stepsMarker ? (
      <div className="fRight mainContainer__steps">
        {this.props.stepsMarker}
      </div>
    ) : ''
  }

  get title () {
    if(this.props.webMode)
      return  <h2 className="fLeft multipleFormContainerTitle"><img src={urls.image.logoBlancoTransparente} alt="Fintup"
            role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} /></h2>
    else if(this.props.widgetMode)
      return <h2 className="fLeft multipleFormContainerTitle"><img src={urls.image.logoBlancoTransparente} alt="Fintup"
            role="presentation" onKeyPress={utils.noop} /></h2>
    else
      return <h2 className="fLeft multipleFormContainerTitle"><img src={urls.image.logoBlancoTransparente} alt="Fintup" 
            role="presentation" onClick={navUtils.goToInitUrl.bind(this)} onKeyPress={utils.noop} /></h2>
  }

  get stepViewComponent () {
    if (this.state.loading) {
      return ''
    } else {
      let actualStep = this.state.actualStep
      let stepViewComponent = 'Step Not Found'
      if (!_.isUndefined(actualStep) && this.props.steps && this.props.steps.length >= actualStep) {
        stepViewComponent = React.cloneElement(
          this.props.steps[this.state.actualStep - 1].stepView,
          {
            nextStep: this.nextStep.bind(this),
            prevStep: this.prevStep.bind(this),
            goToStep: this.goToStep.bind(this),
            goToStepName: this.goToStepName.bind(this),
            updateModel: this.updateModel.bind(this),
            model: this.state.model,
            alert: this.alert.bind(this)
          }
        )
      }
      return stepViewComponent
    }
  }

  get showAlert () {
    let alert = this.state.alert
    return !_.isUndefined(alert.text) && alert.text !== null && alert.text !== ''
  }

  alert (data) {
    let alert = {
      text: data.text,
      type: constants.alertType.ERROR
    }
    return this.setStatePromise({alert: alert})
      .then(() => {
        if (data.text) { // Existe Error asi que hacemos scroll arriba
          utils.scrollToTop()
        }
        return RSVP.resolve()
      })
  }

  render () {
    return (
      <div className={classNames(this.props.widgetMode ? 'multipleFormContainerWidget' : 'multipleFormContainer')}>
        <div className="mainContainer">
          {this.title}
          {this.renderSteps}
          <div className="clearAll"/>
          <div className="step-container">
            <Alert scroll={this.props.alertScroll} text={this.state.alert.text} show={this.showAlert} type={this.state.alert.type} />
            {this.stepViewComponent}
          </div>
        </div>
      </div>
    )
  }
}

export default MultipleFormContainer
