import FintupComponent from "../FintupComponent";
import FintupCalendly from "../common/FintupCalendly";
import ManagerImage from "../suitability/ManagerImage";
import { FormattedMessage } from "react-intl";
import React from 'react'
import './ContractExpertHelp.scss';
import classNames from 'classnames'

class ContractExpertHelp extends FintupComponent {

  /**
   * isMobile: true or false
   */
  constructor(props){
    super(props)
    this.state = {

    }
  }

  render(){
    let classnamesContainer = classNames('contractExpertHelp', this.props.isMobile ? 'isMobile': '')
    return (
    <div className={classnamesContainer}>
      <FintupCalendly model={4}/>
    </div>)
  }
}

export default ContractExpertHelp
