import { extendObservable } from 'mobx'
import _ from 'lodash'
import { observer } from 'mobx-react'
import RSVP from 'rsvp'
import { adviserGet } from '../../utils/fintup-api'
import { tGet } from '../../utils/transverser'

class AdviserModelMobx {
  constructor () {
    extendObservable(this, {
    })
  }

  get adviserName () {
    return tGet('adviser.name', 'Jorge')(this)
  }

  get adviserPhoto () {
    return tGet('adviser.photo', '/img/foto_gestor_1.png')(this)
  }

  loadAdviser () {
    return adviserGet()
      .then(response => {
        _.merge(this, {adviser: response.data})
        return RSVP.resolve()
      })
  }
}
var AdviserModel = new AdviserModelMobx()
export default observer(AdviserModel)
