import React from 'react'
import { injectIntl, FormattedMessage, FormattedHTMLMessage } from "react-intl";
import FintupComponent from "../FintupComponent";
import './ContractStep1.scss';
import ContractTabs from './ContractTabs';
import FintupButton from '../common/forms/FintupButton';
import constants from '../../utils/constants';
import TitleLabel from '../common/forms/TitleLabel';
import InputTextLabel from '../common/forms/InputTextLabel';
import SelectLabel from '../common/forms/SelectLabel';
import SessionController from '../../core/SessionMobx';
import DatePickerLabel from '../common/forms/DatePickerLabel';
import { getContractPersonalData } from '../../utils/fintup-api';
import transverser from '../../utils/transverser';
import utils from '../../utils/utils';
import moment from 'moment'
import urls from '../../utils/urls';
import navUtils from '../../core/navUtils';
import ContractExpertHelp from './ContractExpertHelp';

class ContractStep1 extends FintupComponent
{
  constructor(props){
    super(props)
    this.formatPersonalDataDate = 'DD/MM/YYYY'
    this.state = {
      actualStep: 2,
      email: SessionController.email,

      //Error fields text
      firstNameErrorText: '',
      lastNameErrorText: '',
      emailErrorText: '',
      phoneErrorText: '',
      countryBirdErrorText: '',
      birthDateErrorText: '',
      birthPlaceErrorText: '',
    }
    this.momentMaxBirthDate = moment().subtract(18, 'year')
    this.formatMaxBirthDate = this.momentMaxBirthDate.format(this.formatPersonalDataDate)
    this.momentMinBirthDate = moment().subtract(90, 'year')
    this.formatMinBirthDate = this.momentMinBirthDate.format(this.formatPersonalDataDate)
  }

  hideErrorsText(){
    return this.setStatePromise({
      firstNameErrorText: '',
      lastNameErrorText: '',
      emailErrorText: '',
      phoneErrorText: '',
      countryBirdErrorText: '',
      birthDateErrorText: '',
      birthPlaceErrorText: '',
    })
  }

  componentDidMount(){
    //Check if exists values fillables
    let state = transverser.get('model.step1')(this.props)
    let existsStep = transverser.get('model.step1', false)(this.props) ? true : false


    if(!existsStep){
      getContractPersonalData()
      .then(response => {
          let defaultPhone = transverser.get('personalData.phone', '')(this.props.model)
          let hasDefaultProfile = false
  
          if (defaultPhone && defaultPhone !== '') {
            hasDefaultProfile = true
          }
  
          let peronalDataModel = {
            firstName: SessionController.userData.name,
            lastName: SessionController.userData.surname,
            phone: transverser.get('phone', defaultPhone)(response.data) || defaultPhone,
            countryBird: transverser.get('countryBird', '')(response.data),
            birthdate: transverser.get('birthdate', '')(response.data),
            birthPlace: transverser.get('birthPlace', '')(response.data),
            hasDefaultProfile: hasDefaultProfile
          }
          peronalDataModel.birthdate = peronalDataModel.birthdate != '' && peronalDataModel.birthdate != null ? moment(peronalDataModel.birthdate, this.formatPersonalDataDate).toDate() : ''
          
          console.log("peronalDataModel contractStep0: ", peronalDataModel)
          return this.setStatePromise(peronalDataModel)
      })
    }else{
      this.setStatePromise(state)
    }
  }

  /**
  * Validation fields and check
  * if we can go to the next step
  */
  next(){
    let firstName = this._getState('firstName', '')
    let lastName = this._getState('lastName', '')
    let email = this._getState('email', '')
    let phone = this._getState('phone', '')
    let birthdate = this._getState('birthdate', '')
    let momentBirthdate = utils.parseDate({ date: birthdate, format: this.formatPersonalDataDate })
    let birthPlace = this._getState('birthPlace', '').trim()
    let countryBird = this._getState('countryBird', '').trim()

    let isError = false;

    //1 - firstName
    this.setStatePromise({firstNameErrorText: ''})
    if (firstName === '') {
      this.setStatePromise({firstNameErrorText: 'ContractPersonalData.validation.name'})
      isError = true
      utils.scrollToTop()
    }
    
    //2 - lastName
    this.setStatePromise({lastNameErrorText: ''})
    if (lastName === '') {
      this.setStatePromise({lastNameErrorText: 'ContractPersonalData.validation.surname'})
      isError = true
      utils.scrollToTop()
    }

    //3 - email
    this.setStatePromise({emailErrorText: ''})
    if (email === '') {
      this.setStatePromise({emailErrorText: 'ContractPersonalData.validation.email'})
      isError = true
      utils.scrollToTop()
    }

    //4 - phone
    this.setStatePromise({phoneErrorText: ''})
    if (!utils.isPhone(phone)) {
      this.setStatePromise({phoneErrorText: 'ContractPersonalData.validation.phone'})
      isError = true
      utils.scrollToTop()
    }

    //5 - birthdate
    this.setStatePromise({birthDateErrorText: ''})
    if (momentBirthdate === '' || !utils.validateDate({ date: momentBirthdate, minDate: this.momentMinBirthDate, maxDate: this.momentMaxBirthDate })) {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'ContractPersonalData.validation.birthdate', type: constants.NOTIFICATION_TYPE.ERROR})

      isError = true
      utils.scrollToTop()
    }

    //6 - birthplace
    this.setStatePromise({birthPlaceErrorText: ''})
    if (birthPlace === '') {
      this.setStatePromise({birthPlaceErrorText: 'ContractPersonalData.validation.birthPlace'})
      isError = true
      utils.scrollToTop()
    }

    //7 - countryBird
    this.setStatePromise({countryBirdErrorText: ''})
    if (countryBird === '') {
      this.setStatePromise({countryBirdErrorText: 'ContractPersonalData.validation.country'})
      isError = true
      utils.scrollToTop()
    }

    if(isError){
      return
    }

    this.hideErrorsText()
    .then(() => {
      return this.setStatePromise({formatBirthdate: moment(birthdate).format(this.formatPersonalDataDate)})
    })
    .then(() => {
      this.props.updateModel({
        ...this,
        step1: this.state,
      })
    })
    
    this.props.nextStep()
  }

  render(){
    return(
      <div className="contractStep1">
        <div className="content text-center">
          <ContractTabs values={this} blockNextItems={true} actualStep={this.state.actualStep} titleKey="contractGeneralView.title1" elementActive="2"></ContractTabs>
          <div className="container contentData">
            <div className="row">
              <TitleLabel 
                title="1"
                descriptionKey="contractStep.title9"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={true} theme="new"
              placeholder="ContractPersonalData.name"
              isError={this.state.firstNameErrorText != ''}
              errorTextKey={this.state.firstNameErrorText}
              name="firstName" value={this.state.firstName} 
              callBackOnChange={this.commonHandleChange.bind(this)}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="2"
                descriptionKey="contractStep.title10"
                theme="new"
                isTooltip={true}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={true} theme="new"
              placeholder="ContractPersonalData.surname" 
              isError={this.state.lastNameErrorText != ''}
              errorTextKey={this.state.lastNameErrorText}
              name="lastName" value={this.state.lastName} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="3"
                descriptionKey="contractStep.title11"
                theme="new">
              </TitleLabel>
              <InputTextLabel theme="new"
              name="email" 
              value={this.state.email} readOnly={true}
              isError={this.state.emailErrorText != ''}
              errorTextKey={this.state.emailErrorText}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="4"
                descriptionKey="contractStep.title12"
                theme="new" 
                isTooltip={this.state.hasDefaultProfile}
                urlClickTooltip={urls.internal.profile}>
                <FormattedMessage 
                id="contractStep.text.changeProfile"></FormattedMessage>
              </TitleLabel>
              <InputTextLabel readOnly={this.state.hasDefaultProfile} 
              type="number" theme="new"
              placeholder="ContractPersonalData.telefono" 
              name="phone" value={this.state.phone}
              isError={this.state.phoneErrorText != ''}
              errorTextKey={this.state.phoneErrorText}
              callBackOnChange={this.commonHandleChange.bind(this)}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="5"
                descriptionKey="contractStep.title13"
                isTooltip={true}
                theme="new">
                <FormattedMessage id="suitabilityStep1.validation.age"></FormattedMessage>
              </TitleLabel>
              <DatePickerLabel 
                className="theme-new"
                name="birthdate"
                showLeadingZeros={true}
                disableCalendar={true}
                minDate={this.state.formatMinBirthDate}
                maxDate={this.state.formatMaxBirthDate}
                dayPlaceholder={"DD"}
                monthPlaceholder={"MM"}
                yearPlaceholder={"AAAA"}
                callBackOnChange={this.commonHandleChange.bind(this)}
                clearIcon={null}
                value={this.state.birthdate}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="6"
                descriptionKey="contractStep.title14"
                theme="new">
              </TitleLabel>
              <InputTextLabel theme="new" 
              placeholder="ContractPersonalData.birthPlace" 
              name="birthPlace" 
              isError={this.state.birthPlaceErrorText != ''}
              errorTextKey={this.state.birthPlaceErrorText}
              value={this.state.birthPlace} callBackOnChange={this.commonHandleChange.bind(this)} />

            </div>
            <div className="row">
              <TitleLabel 
                title="7"
                descriptionKey="contractStep.title16"
                theme="new">
              </TitleLabel>
              <SelectLabel theme="new"
              name="countryBird" 
              placeholder="ContractPersonalData.pais" 
              isError={this.state.countryBirdErrorText != ''}
              errorTextKey={this.state.countryBirdErrorText}
              options={transverser.get('listOptions.countryOptions', '')(this.props.model)} 
              selectedValue={this.state.countryBird} callBackOnChange={this.commonHandleChange.bind(this)} />

            </div>
          </div>
          <div className="buttonsContainer">
            <FintupButton fintupIcon="flecha-seleccionable-izquierda"
            iconRight={true} textKey="button.common.next" 
            className="buttonContainer buttonPrevMiniTest"
            buttonClassName="btn-bold" size={constants.BUTTON_SIZE.ONLY_ICON} action={this.props.prevStep} />
            <FintupButton
              fintupIcon="flecha-seleccionable-derecha" 
              iconRight={true} textKey="button.common.next" 
              className="buttonContainer buttonAttention"
              buttonClassName="btn-bold"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={() => this.next()}/>
          </div>
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        <ContractExpertHelp></ContractExpertHelp>
      </div>
    )
  }
}
export default injectIntl(ContractStep1)
