import React from 'react'
import { injectIntl } from "react-intl";
import FintupComponent from '../../FintupComponent'
import './SliderText.scss'
import FintupSlider from "./FintupSlider";
import classNames from 'classnames'
import utils from "../../../utils/chartjs_utils";

class SliderText extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {

    }
  }

  get componentClasses () {
    return classNames(
      'sliderText',
      this.props.className
    )
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <div className="col-sm-9 col-xs-8 slider">
          <FintupSlider name={this.props.name} step={this.props.step} showTooltip={false} min={this.props.min} max={this.props.max} start={this.props.start} callBackOnChange={this.props.onChange}/>
        </div>
        <div className="col-sm-3 col-xs-4">
          <h4 className="input">{this.props.valueFormat? this.props.valueFormat : this.props.start}</h4>
        </div>
      </div>
      
    )
  }
}

export default injectIntl(SliderText)
