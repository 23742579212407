import React, { Component } from 'react'
import constans from '../../utils/constants'
import classNames from 'classnames'
import './ManagerImage.scss'
import AdviserModel from '../common/AdviserModel'

class ManagerImage extends Component {
  get arrowClass () {
    return this.props.direction || constans.MANAGERIMAGE.DIRECTION.BOTTOM
  }

  render () {
    return (
      <div className={classNames('managerImage', this.props.theme? 'theme-'+this.props.theme : '')}>
        <div className="containerImage">
          <div className={classNames('imageFrame', this.arrowClass)}>
            <img className="mainImage" src={AdviserModel.adviserPhoto} alt="Gestor" />
          </div>
        </div>
      </div>
    )
  }
}

export default ManagerImage
