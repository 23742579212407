import React from 'react'
import FintupComponent from "../FintupComponent";
import Terms from "../common/Terms";
import FintupButton from '../common/forms/FintupButton';
import constants from '../../utils/constants';
import './RegisterNew.scss';
import SocialLogin from '../common/SocialLogin';
import InputTextLabel from '../common/forms/InputTextLabel';
import CheckboxNew from '../common/forms/CheckboxNew';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import TermsModel from '../common/TermsModel';
import SessionController from '../../core/SessionMobx';
import utils from '../../utils/utils'
import _ from 'lodash'
import transverser, { tGet } from '../../utils/transverser'
import { injectIntl } from 'react-intl'
import { doRegister } from '../../utils/fintup-api'
import FintupIcon from '../common/FintupIcon';
import RegisterCodeNew from './RegisterCodeNew';
import AdviserModel from '../common/AdviserModel';
import config from '../../config/config';
import RSVP from 'rsvp'
import loginHelper from '../home/loginHelper';

/**
 * minitest: {true|false}
 * showBreadcrumbs: {true|false}
 */
class RegisterNew extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      user: {},
      showButtons: true,
      showFields: false,
      showActivationCode: false,
      registerButtonActive: false,
      invitationCode: undefined,
      isPrivateSession: SessionController.isLogged(),
      values: {},
      //errors
      nameFieldErrorText: '',
      surnameFieldErrorText: '',
      emailFieldErrorText: '',
      passwordFieldErrorText: '',
      passwordConfirmFieldErrorText: '',
      buttonGoogleDisabled: true
    }
  }

  handleChange (data) {
    let fieldName = data.ev.target.name
    let fieldValue = data.ev.target.value
    this.setStatePromise({ [fieldName]: fieldValue }).then(data => {
      console.log("THIS STATE: ", this.state)
    })
  }

  showButtons(){
    this.setStatePromise({showButtons: true, showFields: false})
  }

  showFields(){
    this.setStatePromise({showButtons: false, showFields: true})
  }

  //Form by email
  register(){
    let isError = false;

    this.setStatePromise({nameFieldErrorText: ''})
    if (utils.isEmpty(this.state.user.name)) {
      this.setStatePromise({nameFieldErrorText: 'regsterView.validation.name'})
      isError = true
    }
    
    this.setStatePromise({surnameFieldErrorText: ''})
    if (utils.isEmpty(this.state.user.surname)) {
      this.setStatePromise({surnameFieldErrorText: 'regsterView.validation.surname'})
      isError = true
    }
    
    this.setStatePromise({emailFieldErrorText: ''})
    if (!utils.validateEmail(this.state.user.email)) {
      this.setStatePromise({emailFieldErrorText: 'regsterView.validation.email'})
      isError = true
    }
    
    let password = _.trim(tGet('user.password')(this.state))
    let passwordConfirm = _.trim(tGet('user.passwordConfirm')(this.state))

    this.setStatePromise({passwordFieldErrorText: ''})
    if (password === '') {
      this.setStatePromise({passwordFieldErrorText: 'ChangePassword.fillPassword'})
      isError = true
    }

    this.setStatePromise({passwordFieldErrorText: ''})
    if (!utils.validatePassword(password)) {
      this.setStatePromise({passwordFieldErrorText: 'ChangePassword.validatePassword'})
      isError = true
    }
    
    this.setStatePromise({passwordConfirmFieldErrorText: ''})
    if (password !== passwordConfirm) {
      this.setStatePromise({passwordConfirmFieldErrorText: 'ChangePassword.samePassword'})
      isError = true
    }
    //First fields, after validation checkbox
    if(isError)
      return;
    
    //Check validation conditions
    isError = this.validateConditions()
    
    if(isError)
      return;

    let registerParam = tGet('routeParams.registerParam')(this.props)
    let registerData = _.merge({
      origin: registerParam,
      invitation: this.state.invitationCode
    }, this.state.user)
    doRegister(registerData)
    .then(() => {
      console.log("REGISTER DATA: ", registerData)
      return this.setStatePromise({
        showFields: false, 
        showActivationCode: true})
        .then(() => {
          this.handleNotification({titleKey: 'regsterView.validation.oktitle', textKey: 'regsterView.validation.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
        })
    })
    .catch((result) => {
      console.log(result)
      this.handleNotification({titleKey: 'regsterView.error', textKey: result.message, type: constants.NOTIFICATION_TYPE.ERROR})
    })
  }
  
  validateConditions(){
    let errorValidate = this.errorValidateConditions()
    if(errorValidate){
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'regsterView.validation.dataProcessingTerms', type: constants.NOTIFICATION_TYPE.ERROR})
    }
    return errorValidate;
  }

  errorValidateConditions(){
    
    let isError = false
    //Checkbox conditions
    //console.log("CONDITIONS: ", this.state.user)
    if (!this.state.user.dataProcessingTerms || !this.state.user.communicationTerms || !this.state.user.profilesTerms) {
      isError = true
    }
    
    return isError
  }

  validateConditionChange(data){
    return this.commonCheckboxChange(data).then((response) => {
      //console.log("DATA CHECKBOX valdiateConditionsChange", response)
      let disabledConditions = this.errorValidateConditions()
      //console.log("DISABLE GOOGLE BUTTON: ", disabledConditions)
      return this.setStatePromise({buttonGoogleDisabled: disabledConditions})
    })
  }
  
  //Response ok google
  responseOkLogin(){
    this.handleNotification({titleKey: 'regsterView.validation.oktitle', textKey: 'regsterView.validation.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
    return RSVP.resolve()
  }

  //Response error google
  responseErrorLogin (response) {
    this.handleNotification({titleKey: 'regsterView.error', textKey: response.message ? response.message : response.textKey, type: constants.NOTIFICATION_TYPE.ERROR})
    return RSVP.reject()
  }

  get renderConditionsTerms(){
    let acceptText = <FormattedHTMLMessage id="registerView.acceptText" />
    let dataProcessingTermsSubText = <a href={TermsModel.urls.dataProcessingTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.dataProcessingTerms.acceptLink" /></a>
    let dataProcessingTermsText = <FormattedMessage id="regsterView.dataProcessingTerms.new" values={{ tag1: acceptText, tag2: dataProcessingTermsSubText }} />
    let communicationTermsSubText = <a href={TermsModel.urls.communicationTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.communicationTerms.acceptLink" /></a>
    let communicationTermsText = <FormattedMessage id="regsterView.communicationTerms.new" values={{ tag1: acceptText, tag2: communicationTermsSubText }} />
    let profilesTermsSubText = <a href={TermsModel.urls.profilesTerms} target="_blank" role="button" tabIndex="0"><FormattedMessage id="regsterView.profilesTerms.acceptLink" /></a>
    let profilesTermsText = <FormattedMessage id="regsterView.profilesTerms.new" values={{ tag1: acceptText, tag2: profilesTermsSubText }} />

    return (
      <div className="container terms">
        <CheckboxNew theme="new" name="user.dataProcessingTerms" className="checkboxTerm"
        text={dataProcessingTermsText} value={this.state.user.dataProcessingTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />
        <CheckboxNew theme="new" name="user.communicationTerms" className="checkboxTerm"
        text={communicationTermsText} value={this.state.user.communicationTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />
        <CheckboxNew theme="new" name="user.profilesTerms" className="checkboxTerm"
        text={profilesTermsText} value={this.state.user.profilesTerms} 
        onChangeCheckbox={this.validateConditionChange.bind(this)} />
      </div>
    )
  }

  render(){
    return (
      <div className="registerNew">
        <div className="contentFull text-center">
          <div className="registerBlock">
            <div className="title">
            <h3><FormattedMessage id="regsterView.title"/></h3>
            </div>
            <div className="formBlock">
              {
                this.state.showButtons ? 
                (
                  <div>
                    <div className="buttons">
                      <SocialLogin 
                      invitationCode={this.state.invitationCode}
                      register={true}
                      showAlert={this.responseErrorLogin.bind(this)}
                      successLogin={loginHelper.responseSuccessLogin.bind(this)}
                      errorLogin={this.responseErrorLogin.bind(this)}
                      disabled={this.state.buttonGoogleDisabled}
                      onValidate={this.validateConditions.bind(this)}
                      additionalRquestData={{
                        dataProcessingTerms: this.state.user.dataProcessingTerms,
                        communicationTerms: this.state.user.communicationTerms,
                        profilesTerms: this.state.user.profilesTerms
                      }}/>
                      <FintupButton 
                        iconImage="/img/email-register.svg" 
                        textKey="regsterView.email"
                        className="buttonEmail"
                        size={constants.BUTTON_SIZE.FREE}
                        action={this.showFields.bind(this)} />
                    </div>
                    {this.renderConditionsTerms}
                  </div>
                )
                : ''
              }
              {
                this.state.showFields ? 
                (
                  <div className="fieldsBlock">
                    <div className="container fields">
                      <div className="row">
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Nombre"
                            name="user.name" 
                            theme="new"
                            isError={this.state.nameFieldErrorText != ''}
                            errorTextKey={this.state.nameFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Apellidos"
                            name="user.surname" 
                            theme="new"
                            isError={this.state.surnameFieldErrorText != ''}
                            errorTextKey={this.state.surnameFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="text"
                            placeholder="Email"
                            name="user.email" 
                            theme="new"
                            isError={this.state.emailFieldErrorText != ''}
                            errorTextKey={this.state.emailFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                            <InputTextLabel 
                            type="password"
                            placeholder="Contraseña"
                            name="user.password" 
                            theme="new"
                            isError={this.state.passwordFieldErrorText != ''}
                            errorTextKey={this.state.passwordFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4">
                          <InputTextLabel 
                            type="password"
                            placeholder="Confirma tu contraseña"
                            name="user.passwordConfirm" 
                            theme="new"
                            isError={this.state.passwordConfirmFieldErrorText != ''}
                            errorTextKey={this.state.passwordConfirmFieldErrorText}
                            callBackOnChange={this.commonHandleChange.bind(this)} />
                        </div>
                        <div className="col-md-4 buttonsFields">
                          <FintupButton 
                          fintupIcon="flecha-seleccionable-izquierda" 
                          iconRight={true} 
                          textKey="button.common.next" 
                          className="buttonPrev" buttonClassName="btn-bold" 
                          size={constants.BUTTON_SIZE.ONLY_ICON} 
                          action={this.showButtons.bind(this)} />
                          <FintupButton 
                            type="submit"
                            disabled={this.state.registerButtonActive}
                            fintupIcon="flecha-seleccionable-derecha" 
                            iconRight={true}
                            className="buttonAttention"
                            textKey="Registrarte" 
                            size={constants.BUTTON_SIZE.XXLARGE} 
                            action={this.register.bind(this)}/>
                        </div>
                      </div>
                    </div>
                    {this.renderConditionsTerms}
                  </div>
                ) : ''
              }
              {
                this.state.showActivationCode ? 
                (
                  <div className="fieldsBlock">
                    <RegisterCodeNew 
                     classNameContainer="fields"
                     user={this.state.user}></RegisterCodeNew>
                  </div>
                ) : ''
              }
            </div>
          </div>
          <Terms/>
        </div>
      </div>
    )
  }
}

export default injectIntl(RegisterNew)