import React, { Component } from 'react'
import './ModalHeader.scss'

class ModalHeader extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    return (
      <div className="modal-header">
        <button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={this.props.clickClose}>&times;</button>
        <h3 className="modal-title">{this.props.children}</h3>
      </div>
    )
  }
}

export default ModalHeader
