import React from 'react'
import FintupButton from '../common/forms/FintupButton'
import { FormattedMessage, injectIntl } from 'react-intl'
import { doUploadDoc } from '../../utils/fintup-api'
import FintupComponent from '../FintupComponent'
import Dropzone from 'react-dropzone'
import constants from '../../utils/constants'
import SessionController from '../../core/SessionMobx'
import classNames from 'classnames'
import transverser from '../../utils/transverser'
import navUtils from '../../core/navUtils'
import './Document.scss'
import RSVP from 'rsvp'
import FintupIcon from '../common/FintupIcon'
import { observer } from 'mobx-react'
import Spinner from 'react-spinkit'
import _ from 'lodash'

class Document extends FintupComponent {
  /**
   * fileData: Datos del fichero que llega desde el servicio api/files GET
   * canUpdate: Indica si desde el componente se puede subir / actualizar el documento
   * uploadDisableMessageKey: Mensaje que se muestra cuando la subida esta deshabilitada por defecto document.cantUpdate.peding.message -> Pendiente de subir por Fintup
   * allowPdf: Permite subir pdfs
   * allowImage: Permite subir imagenes png y jpeg
   * aditionalParams: Para pasar paametros a los literales
   * newStyle: Nuevo estilo para subir documentos (usado en subida de dni)
   * isLoading: Para mostrar un loading en el caso de nuevo estilo cuando estan cargando cosas
   *
   */
  constructor (props) {
    super(props)

    let imageTypes = [
      {
        type: 'image/jpeg',
        extension: 'jpg'
      },
      {
        type: 'image/png',
        extension: 'png'
      }]
    let pdfTypes = [
      {
        type: 'pdf',
        extension: 'pdf'
      },
      {
        type: 'application/pdf',
        extension: 'pdf'
      }]

    let resultTypes = []
    if (this.props.allowImage) {
      resultTypes = resultTypes.concat(imageTypes)
    }

    if (this.props.allowPdf) {
      resultTypes = resultTypes.concat(pdfTypes)
    }

    this.state = {
      fileData: props.fileData,
      fileTypes: resultTypes.map(resultType => resultType.type).join(','),
      allowedExtenstions: _.uniq(resultTypes.map(resultType => resultType.extension)).join(','),
      isLoading: false
    }
  }

  // El que viene de las propiedades afecta en general a todo el del estado afecta solo al componente
  get showLoading () {
    return this.props.isLoading || this.state.isLoading
  }

  downloadDocument () {
    let sessionToken = SessionController.token
    navUtils.openPrivateWindow(this.state.fileData.uriDownload, sessionToken)
  }
  get postUploadAction () {
    return this.props.postUpload ? this.props.postUpload : () => RSVP.resolve()
  }

  get showDocumentName () {
    return transverser.get('showDocumentName', false)(this.props)
  }

  get canUpdate () {
    return transverser.get('canUpdate', true)(this.props)
  }

  onDrop (accepted, rejected) {
    console.log('soltamos el documento')
    if (accepted.length > 1) {
      alert('Solo es posible subir un documento')
      return false
    }
    if (rejected.length > 0) {
      alert(`Ha ocurrido un error subiendo el fichero, solo es posible subir ficheros con las extensiones ${this.state.allowedExtenstions}`)
      return false
    }

    let formData = new FormData()
    formData.append('file', accepted[0])
    formData.append('fileId', this.state.fileData.id || this.state.fileData.documentType)
    if (this.state.fileData.operationId !== null && !_.isUndefined(this.state.fileData.operationId)) {
      formData.append('operationId', this.state.fileData.operationId)
    }
    this.setStatePromise({isLoading: true})
      .then(() => doUploadDoc(formData))
      .then((response) => {
        let fileData = this.state.fileData
        fileData.fileName = response.data.uploadFileResponse.fileName
        fileData.id = response.data.uploadFileResponse.fileId
        fileData.documentStateType = constants.FILE_STATE.UPLOADED
        fileData.uriDownload = response.data.uploadFileResponse.uriDownload
        return this.setStatePromise({fileData: fileData})
      })
      .then(() => this.postUploadAction(this.state.fileData))
      .catch(error => this.handleServiceError(_.merge(error, {jsAlert: true})))
      .finally(() => this.setStatePromise({isLoading: false}))
  }

  renderUploadedDocumentOldStyle () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    let documentUploadedTypeClasses = classNames({'hidden': !transverser.get('showUploadedType', false)(this.props)})
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-6 documentText">
          <p className={documentUploadedTypeClasses}><FormattedMessage id={documentTypeTextKey} values={this.props.aditionalParams}/></p>
          { this.showDocumentName ? <a className="fLeft documentLink" href={`${this.state.fileData.uriDownload}?token=${SessionController.token}`} target="_blank" rel="noopener noreferrer">{this.state.fileData.fileName}</a> : '' }
          { this.renderFileDescription }
        </div>
        <div className="col-xs-12 col-sm-6">
          <FintupButton textKey="document.button.download" className="fLeft marginRight10" size={constants.BUTTON_SIZE.FREE} action={this.downloadDocument.bind(this)}/>
          { this.canUpdate ? (
            <Dropzone className="fLeft documentUploadContainer documentUpdateContainer" onDrop={this.onDrop.bind(this)} disabled={false} accept={this.state.fileTypes}>
              <FintupButton textKey="document.button.refresh" buttonClassName="alt" size={constants.BUTTON_SIZE.FREE} />
            </Dropzone>
          ) : '' }
        </div>
      </div>
    )
  }

  renderUploadedDocument () {
    return this.props.newStyle
      ? this.renderUploadedDocumentNewStyle()
      : this.renderUploadedDocumentOldStyle()
  }

  get uploadButtonClasses () {
    return classNames(
      'col-xs-12',
      'col-sm-6',
      'documentButtons'
    )
  }

  sendAction () {
    if (this.props.sendAction) {
      this.props.sendAction(this.state.fileData)
    }
  }

  renderUploadedDocumentNewStyle () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    let documentText = this.props.intl.formatMessage({ id: documentTypeTextKey })
    return (
      <div className="row">
        <div>
          <Dropzone className="documentUploadContainer" onDrop={this.onDrop.bind(this)} disabled={this.showLoading} accept={this.state.fileTypes}>
            <div className="documentContainer">
              <div className="documentInner">
                {this.showLoading ? (
                  <div className="content">
                    <Spinner name='circle' fadeIn='none' />
                  </div>
                ) : (
                  <div className="content">
                    <div>
                      <img src="/img/fileOk.svg" alt="subida correcta"/>
                    </div>
                    <p><FormattedMessage id="document.uploadedOk" values={{document: documentText}}/></p>
                  </div>
                )}
              </div>
            </div>
          </Dropzone>
          <div className="documentFooter">
            <FintupIcon icon={this.state.fileData.icon}/>
            <a href={`${this.state.fileData.uriDownload}?token=${SessionController.token}`} target="_blank" rel="noopener noreferrer"><FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`}/></a>
          </div>
        </div>
      </div>
    )
  }

  renderNewStylePendingDocument () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    let documentText = this.props.intl.formatMessage({ id: documentTypeTextKey })
    let fileIcon = this.props.allowImage && this.props.allowPdf
      ? (
        <div>
          <FintupIcon size="15" icon="jpg-icon"/>
          <FintupIcon size="35" icon="pdf-icon"/>
          <FintupIcon size="20" icon="png-icon"/>
        </div>
      )
      : ''
    return (
      <div>
        <div className="documentContainer">
          <div className="documentInner">
            {this.showLoading ? (
              <div className="content">
                <Spinner name='circle' fadeIn='none' />
              </div>
            ) : (
              <div className="content">
                {fileIcon}
                <p><FormattedMessage id="document.dropDocument" values={{document: documentText}}/></p>
                <FintupButton fintupIcon="cloud" buttonClassName="alt" textKey="document.button.findFile" size={constants.BUTTON_SIZE.FREE} />
              </div>
            )}
          </div>
        </div>
        <div className="documentFooter">
          <FintupIcon icon={this.state.fileData.icon}/>
          <FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`}/>
        </div>
      </div>
    )
  }

  renderPendingDocument () {
    let documentTypeTextKey = `document.documentType.${this.state.fileData.documentType}`
    return (
      <div className="row">
        { this.canUpdate ? (
          <Dropzone className="documentUploadContainer" onDrop={this.onDrop.bind(this)} disabled={this.showLoading} accept={this.state.fileTypes}>
            {this.props.newStyle ? this.renderNewStylePendingDocument()
              : (
                <div>
                  <div className="col-xs-12 col-sm-6 documentText">
                    <p><FormattedMessage id={documentTypeTextKey} values={this.props.aditionalParams}/></p>
                    { this.renderFileDescription }
                  </div>
                  <div>
                    <FintupButton className="fLeft" buttonClassName="alt" textKey="document.button.upload" size={constants.BUTTON_SIZE.HALF_DOUBLE_SMALL} />
                  </div>
                </div>
              )
            }
          </Dropzone>
        ) : (
          <div>
            <div className="col-xs-12 col-sm-6 documentText">
              <p><FormattedMessage id={documentTypeTextKey} values={this.props.aditionalParams}/></p>
              { this.renderFileDescription }
            </div>
            <div className={`${this.uploadButtonClasses} documentButtonsText`}>
              <FormattedMessage className="pending" id={this.props.uploadDisableMessageKey || 'document.cantUpdate.peding.message'} />
            </div>
          </div>
        ) }
      </div>
    )
  }

  get renderFileDescription () {
    if (this.props.showFileDescription) {
      return <p className="fileDescription"><FormattedMessage id={`document.documentType.description.${this.state.fileData.documentType}`} /></p>
    } else {
      return ''
    }
  }

  render () {
    let componentClases = classNames(
      'document',
      {'newStyle': this.props.newStyle}
    )
    return (
      <div className={componentClases}>
        {this.state.fileData.documentStateType === constants.FILE_STATE.UPLOADED
          ? this.renderUploadedDocument()
          : this.renderPendingDocument()}
      </div>
    )
  }
}

export default injectIntl(observer(Document))
