import React from 'react'
import { injectIntl } from "react-intl";
import FintupComponent from "../FintupComponent";
import './ContractStep5.scss';
import constants from '../../utils/constants';
import FintupButton from '../common/forms/FintupButton';
import ContractTabs from './ContractTabs';
import TitleLabel from '../common/forms/TitleLabel';
import InputTextLabel from '../common/forms/InputTextLabel';
import RadioGroup from '../common/forms/RadioGroup';
import RadioButton from '../common/forms/RadioButton';
import CheckboxGroup from '../common/forms/CheckboxGroup';
import Checkbox from '../common/forms/Checkbox';
import uniqid from 'uniqid'
import { getUserIncomes, doGetInvestTargetList, doGetInvestOriginList, getContractMoneyLaundering, doUpdateContractForm, doInvest, doContractInvest, doSuitabilityContract, contractInvestPost } from '../../utils/fintup-api';
import transverser from '../../utils/transverser';
import _ from 'lodash'
import RSVP from 'rsvp'
import CheckboxNew from '../common/forms/CheckboxNew';
import utils from '../../utils/utils';
import LoadingMobx from '../../core/LoadingMobx';
import ProductsController from '../../core/ProductsMobx';
import config from '../../config/config';
import SessionController from '../../core/SessionMobx';
import ContractExpertHelp from './ContractExpertHelp';
import navUtils from '../../core/navUtils';
import LoadingApp from '../common/LoadingApp';

class ContractStep5 extends FintupComponent
{
  constructor(props){
    super(props)

    this.state = {
      actualStep: 6,
      isRendered: true,
      //Error fields text
      economicActivityErrorText: '',
      annualIncomeErrorText: '',
    }
  }

  hideErrorsText(){
    return this.setStatePromise({
      economicActivityErrorText: '',
      annualIncomeErrorText: '',
    })
  }


  calculateIncomes () {
    return getUserIncomes()
      .then(response => {
        let suitabilityIncomes = transverser.get('data.incomes', 0)(response) * 14
        return this.setStatePromise({suitabilityIncomes: suitabilityIncomes})
      })
      .catch(() => RSVP.resolve())
  }

  componentDidMount(){
    //Check if exists values fillables
    let state = transverser.get('model.step5')(this.props)
    let existsStep = transverser.get('model.step5', false)(this.props) ? true : false

    console.log("EXISTS STEP5 ", existsStep)
    if(!existsStep){
      this.calculateIncomes()
      .then(() => getContractMoneyLaundering())
      .then(response => {
        let moneyLaundering = response.data || {}
        let familyPublicResponsability = transverser.get('familyPublicResponsability', '2')(moneyLaundering) == true ? "1" : "2"
        let publicResponsability = transverser.get('publicResponsability', '2')(moneyLaundering) == true ? "1" : "2"

        let dataModel = {
          economicActivity: transverser.get('economicActivity', '')(moneyLaundering),
          annualIncome: transverser.get('annualIncome', this.state.suitabilityIncomes || '')(moneyLaundering),
          publicResponsability: publicResponsability,
          familyPublicResponsability: familyPublicResponsability,
          originsInvestTypeId: transverser.get('originsInvestType', [])(moneyLaundering).map(element => `O${element.id.originInvestTypeId}`),
          objetivesInvestmentTypeId: transverser.get('objetivesInvestmentType', [])(moneyLaundering).map(element => `T${element.id.objetivesInvestmentTypeId}`)
        }

        return this.setStatePromise(dataModel)
      })
      .catch(error => console.log(error))
    }else{
      this.setStatePromise(state)
    }
  }

  originIsSelected (originKey) {
    return _.indexOf(this.state.originsInvestTypeId, originKey) !== -1
  }

  targetIsSelected (targetKey) {
    return _.indexOf(this.state.objetivesInvestmentTypeId, targetKey) !== -1
  }

  changeInvestTarget (data) {
    let investTarget = this.state.objetivesInvestmentTypeId
    if (data.value) {
      investTarget.push(data.name)
    } else {
      investTarget = _.remove(investTarget, (item) => item !== data.name)
    }
    this.setStatePromise({ objetivesInvestmentTypeId: investTarget })
  }

  changeInvestOrigin (data) {
    let investOrigin = this.state.originsInvestTypeId
    if (data.value) {
      investOrigin.push(data.name)
    } else {
      investOrigin = _.remove(investOrigin, (item) => item !== data.name)
    }
    this.setStatePromise({ originsInvestTypeId: investOrigin })
  }

   /**
   * Validation fields and check
   * if we can go to the next step
   */
  next(){
    let economicActivity = this._getState('economicActivity', '').trim()
    let annualIncome = this._getState('annualIncome', '')
    let publicResponsability = this._getState('publicResponsability', '')
    let familyPublicResponsability = this._getState('familyPublicResponsability', '')
    let originsInvestTypeId = this._getState('originsInvestTypeId', '')
    let objetivesInvestmentTypeId = this._getState('objetivesInvestmentTypeId', '')

    let isError = false;

    //1 - economicActivity
    this.setStatePromise({economicActivityErrorText: ''})
    if (economicActivity === '') {
      this.setStatePromise({economicActivityErrorText: 'contractBleach.validation.cnae'})
      isError = true
      utils.scrollToTop()
    }

    //2 - annualIncome
    this.setStatePromise({annualIncomeErrorText: ''})
    if (annualIncome === '' || !utils.validatePositiveAmount(annualIncome)) {
      this.setStatePromise({annualIncomeErrorText: 'contractBleach.validation.income'})
      isError = true
      utils.scrollToTop()
    }

    //3 - publicResponsability
    if (publicResponsability === '') {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractBleach.validation.responsibilityPerson', type: constants.NOTIFICATION_TYPE.ERROR})
      isError = true
      utils.scrollToTop()
    }

    //4 - familyPublicResponsability
    if (familyPublicResponsability === '') {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractBleach.validation.responsibilityPersonFamiliar', type: constants.NOTIFICATION_TYPE.ERROR})
      isError = true
      utils.scrollToTop()
    }

    //5 - originsInvestTypeId
    //this.setStatePromise({publicResponsability: ''})
    if (originsInvestTypeId.length === 0) {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractBleach.validation.investOrigin', type: constants.NOTIFICATION_TYPE.ERROR})
      isError = true
      utils.scrollToTop()
    }

    //6 - objetivesInvestmentTypeId
    if (objetivesInvestmentTypeId.length === 0) {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractBleach.validation.investTarget', type: constants.NOTIFICATION_TYPE.ERROR})
      isError = true
      utils.scrollToTop()
    }

    if(isError){
      return
    }

    this.setStatePromise({isRendered: false})

    this.hideErrorsText().then(() => {
      this.props.updateModel({
        ...this,
        step5: this.state,
      }).then(() =>  {
        console.log("GUARDAR TODO: ", this.props.model)
        let contractData = {
          documentTypeId: transverser.get('model.step3.documentTypeId')(this.props),
          document: transverser.get('model.step3.document')(this.props),
          phone: transverser.get('model.step1.phone')(this.props),
          street: transverser.get('model.step2.street')(this.props),
          city: transverser.get('model.step2.city')(this.props),
          province: transverser.get('model.step2.province')(this.props),
          postalCode: transverser.get('model.step2.postalCode')(this.props),
          countryBird: transverser.get('model.step1.countryBird')(this.props),
          nationality: transverser.get('model.step3.nationality')(this.props),
          countryResidence: transverser.get('model.step2.countryResidence')(this.props),
          birthdate: transverser.get('model.step1.formatBirthdate')(this.props),
          maritalTypeId: transverser.get('model.step3.maritalTypeId')(this.props),
          birthPlace: transverser.get('model.step1.birthPlace')(this.props),
          documentExpiryDate: transverser.get('model.step3.formatDocumentExpiryDate')(this.props),
          doubleNationality: parseInt(transverser.get('model.step3.doubleNationality')(this.props)) == 1 ? true : false,
          secondNationality: transverser.get('model.step3.secondNationality')(this.props), 
          iban: transverser.get('model.step0.account')(this.props),
          beneficiariesType: transverser.get('model.step0.beneficiariesType')(this.props),
          beneficiariesList: transverser.get('model.step0.beneficiariesList')(this.props),
          economicActivity: transverser.get('model.step5.economicActivity')(this.props),
          annualIncome: transverser.get('model.step5.annualIncome')(this.props),
          publicResponsability: parseInt(transverser.get('model.step5.publicResponsability')(this.props)) == 1 ? true : false,
          familyPublicResponsability: parseInt(transverser.get('model.step5.familyPublicResponsability')(this.props)) == 1 ? true : false,
          originsInvestTypeId: transverser.get('model.step5.originsInvestTypeId')(this.props),
          objetivesInvestmentTypeId: transverser.get('model.step5.objetivesInvestmentTypeId')(this.props),
        }

        //Normalize some fields
        contractData.originsInvestTypeId = contractData.originsInvestTypeId.reduce((resultList, origin) => {
          let backValue = _.find(transverser.get('listOptions.investOriginOptions', '')(this.props.model), { value: origin }).backValue
          resultList.push(backValue)
          return resultList
        }, [])
        contractData.objetivesInvestmentTypeId = contractData.objetivesInvestmentTypeId.reduce((resultList, origin) => {
          let backValue = _.find(transverser.get('listOptions.investTargetOptions', '')(this.props.model), { value: origin }).backValue
          resultList.push(backValue)
          return resultList
        }, [])
    
        console.log("CONTRACT DATA: ", contractData)

        //It's necessary -> create/update customer potential (responses data + contract data)
        let portfolioId = transverser.get('model.step0.portfolioId')(this.props)
        let portfolioChoosen = transverser.get('model.portfolios')(this.props).find(item => item.id == portfolioId)
        let riskTypeId = portfolioChoosen.riskTypeId
        console.log("risktypeid choosen", riskTypeId)
        //Customer potential, responses data
        let testRequest = {
          email: SessionController.email,
          responsesData: {
            step1: {
              name: transverser.get('model.step1.firstName')(this.props) + " " + transverser.get('model.step1.lastName')(this.props), 
              age: 18
            }, 
            step2: {
              reason: 2,
              time: 1,
              amount: transverser.get('model.step0.amountInvest', 0)(this.props), //initial amount
              amountMonthly: transverser.get('model.step0.periodicAmount', 0)(this.props) //monthly amount
            }, 
            step3: {
              incomes: 0,
              stable: riskTypeId,
              expensesPercent: riskTypeId
            }, 
            step4: {
              investPreviously: riskTypeId,
              activeAmount: 0
            }, 
            step5: {
              validateEmailToken: null,
              lostInvest: riskTypeId,
              profitabilityTarget: riskTypeId,
              email: SessionController.email,
              typeInvestor: riskTypeId
            }
          },
          suitabilityTestKey: config.suitabilityTestKey,
          //sourceSocial: isSocial,
          //tokenSocial: isSocial ? socialData.idToken : null,
          //adviserId: AdviserModel.adviser.id,
        }

        console.log("test responses data request: ", testRequest)


        //Customer potential, contract data
        let testContractDataRequest = {
          entityId: portfolioChoosen.entityId,
          productId: portfolioChoosen.productId,
          suitabilityTestKey: config.suitabilityTestKey,
          portfolioId: portfolioChoosen.id,
          riskTypeId: portfolioChoosen.riskTypeId
        }
        console.log("test contract data request: ", testContractDataRequest)
        LoadingMobx.setLoading()
        .then(() => doContractInvest(testRequest)) //create/update customer potential
        .then(response => {
          console.log("EXTERNAL ID (response potential client): ", response.data.externalId)
          return this.setStatePromise({ externalId: response.data.externalId })
        })
        .then(() => doSuitabilityContract(testContractDataRequest, this.state.externalId, 'contractdata')) //object contractdata customerPotential
        .then(() => doUpdateContractForm(contractData, 'maindata')) //create/update contracts entities: Personal, Financial and Money Laundering data
        .then(this.contractProduct.bind(this)) //Create virtual contract
        .then(() => ProductsController.reloadProducts())
        .then(() => {
          this.setStatePromise({isRendered: true})
          this.handleNotification({titleKey: 'contractAccount.validation.oktitle', textKey: 'contractAccount.validation.ok', type: constants.NOTIFICATION_TYPE.SUCCESS})
          //Redirect to Global Position
          navUtils.goToInitUrl()
        }) 
        
        /*.then(() => {
          return this.props.updateModel({contractAccount: stepModel})
        })*/
        /*.then(() => {
          _.merge(this.state.options, {stepsComplete: true})
          alertData = {
            alertText: <FormattedMessage id="contractAccount.validation.ok" />,
            alertType: constants.alertType.SUCCESS
          }
          return this.setStatePromise({ alert: alertData, readOnly: true, finishForm: true })
        })*/
        
        //.catch(this.handleServiceError.bind(this))
        .catch((error) => {
          this.handleNotification({titleKey: 'regsterView.error', textKey: 'contractView.alert.ko', type: constants.NOTIFICATION_TYPE.ERROR})
          this.setStatePromise({isRendered: true})
        })
        .catch(() => LoadingMobx.unsetLoading())
        })
        
    })
  }

  // Metodo que se encarga de lanzar la contratacion propia del producto
  contractProduct () {
    console.log("mira productToContract: ", transverser.get('model.productToContract', '')(this.props))
    console.log("datos step 0: ", transverser.get('model.step0', "")(this.props))
    let periodicData = {
      hasPeriodic: transverser.get('model.step0.radioButtonPeriodicContribution', 2)(this.props) == 1 ? true : false,
      amount: transverser.get('model.step0.periodicAmount', 0)(this.props),
      periodicType: transverser.get('model.step0.periodicType', 1)(this.props),
      iban: transverser.get('model.step0.account', "")(this.props)
    }
    console.log("periodic data to save: ", periodicData)
    //let contractData = _.merge({}, this.state.periodicData, {hasPeriodic: !this.state.periodicData.noPeriodic, iban: this.state.account})
    //delete contractData.noPeriodic
    switch (transverser.get('model.productToContract', '')(this.props)) {
      case 'inversion':
        return contractInvestPost(periodicData)
      /*case 'plan-pensiones':
        return navUtils.goToInternal(urls.internal.contractPensionPlan)
      case 'ahorro':
        return navUtils.goToInternal(urls.internal.contractSaving)*/
      default:
        return RSVP.resolve()
    }
  }

  render(){
    return (this.state.isRendered) ? 
    (
      <div className="contractStep5">
        <div className="content text-center">
          <ContractTabs values={this} blockNextItems={true} actualStep={this.state.actualStep} titleKey="contractGeneralView.title1" elementActive="4"></ContractTabs>
          <div className="container contentData">
          <div className="row">
              <TitleLabel 
                title="1"
                descriptionKey="contractStep.title28"
                theme="new">
              </TitleLabel>
              <InputTextLabel theme="new" 
              placeholder="contractBleach.cnae" 
              isError={this.state.economicActivityErrorText != ''}
              errorTextKey={this.state.economicActivityErrorText}
              name="economicActivity" value={this.state.economicActivity} 
              callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="2"
                descriptionKey="contractStep.title29"
                theme="new">
              </TitleLabel>
              <InputTextLabel type="number" 
              theme="new"
              placeholder="contractBleach.income" 
              isError={this.state.annualIncomeErrorText != ''}
              errorTextKey={this.state.annualIncomeErrorText}
              name="annualIncome" 
              value={this.state.annualIncome} callBackOnChange={this.commonHandleChange.bind(this)} />

            </div>
            <div className="row">
              <TitleLabel 
                title="3"
                descriptionKey="contractStep.title30"
                theme="new">
              </TitleLabel>
              <RadioGroup theme="new" >
                <RadioButton radioId="radioSi" 
                name="publicResponsability" 
                textKey="YES" 
                value="1"
                selectedOption={this.state.publicResponsability}
                onChangeRadio={this.commonHandleChange.bind(this)} />
                <RadioButton radioId="radioNo" 
                name="publicResponsability" 
                textKey="NO.lower" 
                value="2" 
                selectedOption={this.state.publicResponsability}
                onChangeRadio={this.commonHandleChange.bind(this)} />
              </RadioGroup>
            </div>
            <div className="row">
              <TitleLabel 
                title="4"
                descriptionKey="contractStep.title31"
                theme="new">
              </TitleLabel>
              <RadioGroup theme="new" >
                <RadioButton radioId="radioSi2" 
                name="familyPublicResponsability" 
                textKey="YES" 
                value="1"
                selectedOption={this.state.familyPublicResponsability}
                onChangeRadio={this.commonHandleChange.bind(this)} />
                <RadioButton radioId="radioNo2" 
                name="familyPublicResponsability" 
                textKey="NO.lower" 
                value="2" 
                selectedOption={this.state.familyPublicResponsability}
                onChangeRadio={this.commonHandleChange.bind(this)} />
              </RadioGroup>

            </div>
            <div className="row">
              <TitleLabel 
                title="5"
                descriptionKey="contractStep.title32"
                theme="new">
              </TitleLabel>
              {
                (transverser.get('listOptions.investOriginOptions', '')(this.props.model)).map((originItem) => {
                  return <CheckboxNew
                    theme="new"
                    key={uniqid()}
                    name={originItem.value}
                    value={this.originIsSelected(originItem.value)}
                    text={originItem.text} onChangeCheckbox={this.changeInvestOrigin.bind(this)} />
                })
              }

            </div>
            <div className="row">
              <TitleLabel 
                title="6"
                descriptionKey="contractStep.title33"
                theme="new">
              </TitleLabel>
                {
                  (transverser.get('listOptions.investTargetOptions', '')(this.props.model)).map((targetItem) => {
                    return <CheckboxNew
                      theme="new"
                      key={uniqid()}
                      name={targetItem.value}
                      value={this.targetIsSelected(targetItem.value)}
                      text={targetItem.text} onChangeCheckbox={this.changeInvestTarget.bind(this)} />
                  })
                }
            </div>
          </div>
          <div className="buttonsContainer">
            <FintupButton fintupIcon="flecha-seleccionable-izquierda"
            iconRight={true} textKey="button.common.next" 
            className="buttonContainer buttonPrevMiniTest"
            buttonClassName="btn-bold" size={constants.BUTTON_SIZE.ONLY_ICON} action={this.props.prevStep} />
            <FintupButton
              iconRight={true} textKey="contractStep.button.end" 
              className="buttonContainer buttonAttention"
              buttonClassName="btn-bold"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={() => this.next()}/>
          </div>
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        <ContractExpertHelp></ContractExpertHelp>
      </div>
    ) : <LoadingApp/>
  }
}
export default injectIntl(ContractStep5)
