import React from 'react'
import './Nav.scss'
import { observer } from 'mobx-react'
import SessionController from '../../core/SessionMobx'
import urls from '../../utils/urls'
import navUtils from '../../core/navUtils'
import utils from '../../utils/utils'
import classNames from 'classnames'
import FintupComponent from '../FintupComponent'
import config from '../../config/config'

class Nav extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  get componentClasses () {
    return classNames(
      {hidden: SessionController.isFullScreen},
      'fintupHeader'
    )
  }

  render () {
    return (
      <div className={this.componentClasses}>
        <nav className="nav navbar navbar-default">
          <div className="navbar-header">
            <div className="full-width">
              <div className="brandContainer">
                <a href={utils.fixLinter.noAnchor()} className="navbar-brand">
                  <img className="default-logo hidden-xs" src={config.pubUrlLogo || urls.image.logoV2} alt="Fintup" role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} />
                  <img className="default-logo show-xs" src={config.mobileUrlLogo || urls.image.logoV2Mobile} alt="Fintup" role="presentation" onClick={navUtils.goToLogoUrl.bind(this)} onKeyPress={utils.noop} />
                </a>
              </div>
            </div>
          </div>
        </nav>
      </div>
    )
  }
}

export default observer(Nav)
