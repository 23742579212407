import React, { Component } from 'react'
import classNames from 'classnames'
import './OpenerModal.scss'
import { FormattedMessage } from 'react-intl'

/**
 * className (boolean)
 * click (ModalHeader component)
 */
class OpenerModal extends Component {
  constructor () {
    super()
    this.state = {}
  }
  
  render () {
    let openerClassNames = classNames('openerModal', this.props.className)
    return (
      <a className={openerClassNames} href="#" onClick={this.props.click}>
        <FormattedMessage id={this.props.text} /> <FormattedMessage id=">" />
      </a>
    )
  }
}

export default OpenerModal
