import { extendObservable } from 'mobx'
import { observer } from 'mobx-react'
import urls from '../../utils/urls'
import config from '../../config/config'

class TermsModelMobx {
  constructor () {
    extendObservable(this, {
      show: false,
      urls: {
        dataProcessingTerms: config.activationDataProcessingTermsUrl || urls.external.terms.dataProcessingTerms,
        communicationTerms: config.activationCommunicationTermsUrl || urls.external.terms.communicationTerms,
        profilesTerms: config.activationProfilesTermsUrl || urls.external.terms.profilesTerms
      }
    })
  }

  open () {
    this.show = true
  }

  close () {
    this.show = false
  }

  reset () {
    this.show = false
  }
}
var TermsModel = new TermsModelMobx()
export default observer(TermsModel)
