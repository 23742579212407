import React, { Component } from 'react'
import './Nav.scss'
import NavPrivate from './NavPrivate'
import NavPublic from './NavPublic'
import { observer } from 'mobx-react'
import SessionController from '../../core/SessionMobx'
import NavPublicNew from './NavPublicNew'

class Nav extends Component {
  constructor (props) {
    super(props)
    this.state = {
    }
  }

  render () {
    let token = SessionController.token
    let navNew = SessionController.themeNew
    return token && token !== '' ? <NavPrivate/> : (navNew ? <NavPublicNew/> : <NavPublic/>)
  }
}

export default observer(Nav)
