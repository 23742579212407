import _ from 'lodash'
import constants from '../utils/constants'
import genericUtils from '../utils/utils'
import moment from 'moment'

let type = {
  DONUT: 'Doughnut',
  PIE: 'Pie',
  LINE: 'Line',
  BAR: 'Bar'
}

let PROP_VALUES = {
  SWIPE: {
    NONE: 'NONE'
  }
}

let CHART_COLOR_LIST = [
  constants.CHART_COLORS.COLOR1,
  constants.CHART_COLORS.COLOR2,
  constants.CHART_COLORS.COLOR3,
  constants.CHART_COLORS.COLOR4]

let CHART_COLOR_BAR_LIST = [
  constants.CHART_COLORS.COLOR5,
  constants.CHART_COLORS.COLOR6,
  constants.CHART_COLORS.COLOR7,
  constants.CHART_COLORS.COLOR8,
  constants.CHART_COLORS.COLOR9,
  constants.CHART_COLORS.COLOR10]

let DEFAULT_LINE_DATASET = {
  fill: false,
  lineTension: 0.1,
  borderCapStyle: 'butt',
  borderDash: [],
  borderDashOffset: 0.0,
  borderJoinStyle: 'miter',
  pointBorderWidth: 1,
  pointHoverRadius: 5,
  pointHoverBorderWidth: 2,
  pointRadius: 1,
  pointHitRadius: 10
}

let DEFAULT_BAR_DATASET = {
  barPercentage: 1,
  barThickness: 6,
  maxBarThickness: 8,
  minBarLength: 2,

}

/** Normaliza un formato mas natural de conseguir:
 * {
 *  label: XX
 *  color: YY
 *  data: ZZ
 * }
 *
 * En caso de grafico de linea:
 * {
 *  title: XXXX,
 *  data: [{
 *    label: YYY,
 *    data: value
 *  }]
 * }
 * 
 * * En caso de grafico de barra horizontal (mismo que línea, pero tratado de forma distinta):
 * {
 *  title: XXXX,
 *  data: [{
 *    label: YYY,
 *    data: value
 *  }]
 * }
 * al formato necesario de chartjs para funcionar
 */
function normalizeData(unnormalizeData, graphicType) {
  if (graphicType === type.LINE) {
    // Labels generate
    let graphicLabels = []
    let dataSets = []
    if (unnormalizeData && unnormalizeData.length > 0) {
      graphicLabels = unnormalizeData[0].data.map(item => {
        return item.label
      })
      dataSets = unnormalizeData.map((item, index) => {
        let customData = {
          label: item.title,
          color: CHART_COLOR_LIST[index],
          data: item.data.map((dataItem) => {
            return dataItem.data
          })
        }
        return createLineDataSet(customData)
      })
    }
    let normalizeData = {
      labels: graphicLabels,
      datasets: dataSets
    }
    return normalizeData
  }
  else if (graphicType === type.BAR) {
    // Labels generate
    let graphicLabels = []
    let dataSets = []
    if (unnormalizeData) {
      graphicLabels = [unnormalizeData.title]
      dataSets = unnormalizeData.data.map((item, index) => {
        console.log("index: ", index)
        console.log("color: ", CHART_COLOR_BAR_LIST[index])
        let customData = {
          label: item.label,
          color: CHART_COLOR_BAR_LIST[index],
          data: item.data.map((dataItem) => {
            return dataItem
          })
        }
        return createBarDataSet(customData)
      })
    }
    let normalizeData = {
      labels: graphicLabels,
      datasets: dataSets
    }
    console.log("NORMALIZE DATA: ", normalizeData)
    return normalizeData
  }
  else {
    let graphicLabels = []
    let graphicData = []
    let graphicColors = []
    unnormalizeData.forEach(element => {
      graphicLabels.push(element.label)
      graphicData.push(element.data)
      graphicColors.push(element.color)
    })
    let normalizeData = {
      labels: graphicLabels,
      datasets: [
        {
          data: graphicData,
          backgroundColor: graphicColors
        }]
    }
    return normalizeData
  }
}

/*
data = {
  label: 'Nombre del dataset',
  color: 'Color del dataset',
  data: Array de datos del dataSet
}
*/
function createLineDataSet(data) {
  let customData = {
    label: data.label,
    data: data.data,
    backgroundColor: data.color, // Legend background
    borderColor: data.color, // Legend border y linea
    pointBorderColor: data.color, // Graphic point
    pointBackgroundColor: data.color, // ?????
    pointHoverBackgroundColor: data.color, // Selected graphic point
    pointHoverBorderColor: data.color // Point border color
  }
  return _.merge(customData, DEFAULT_LINE_DATASET)
}

/*
data = {
  label: 'Nombre del dataset',
  color: 'Color del dataset',
  data: Array de datos del dataSet
}
*/
function createBarDataSet(data) {
  console.log("DATA: ", data)
  let customData = {
    label: data.label,
    data: data.data,
    backgroundColor: data.color, // Legend background
    //borderColor: data.color, // Legend border y linea
    //pointBorderColor: data.color, // Graphic point
    //pointBackgroundColor: data.color, // ?????
    //pointHoverBackgroundColor: data.color, // Selected graphic point
    //pointHoverBorderColor: data.color // Point border color

  }
  return _.merge(customData, DEFAULT_BAR_DATASET)
}

function formatCurrencyTooltip(tooltipItem, data) {
  let datasetLabel = data.datasets[tooltipItem.datasetIndex].label
  let yLabel = genericUtils.formatCurrency(tooltipItem.yLabel)
  return datasetLabel + ': ' + yLabel
}

function formatCurrencyDonutTooltip(tooltipItem, data) {
  let dataSet = data.datasets[tooltipItem.datasetIndex]
  let dataLabel = data.labels[tooltipItem.index]
  let dataValue = genericUtils.formatCurrency(dataSet.data[tooltipItem.index])
  return dataLabel + ': ' + dataValue
}

function formatYAxesCurrency(value, index, values) {
  return genericUtils.formatCurrency(value, { precision: 0 })
}

function formatYAxesPercentage(value, index, values) {
  return genericUtils.formatPorcentage(value, { precision: 0 })
}

function formatXAxesDateDay(value, index, values) {
  let formatedValue = moment(value, 'DD MMM YYYY').format('DD MMMM YYYY')
  return formatedValue
}

function formatXAxesDate(value, index, values) {
  let formatedValue = moment(value, 'DD MMM YYYY').format('MMMM YYYY')
  return formatedValue
}

function formatPercentageTooltip(tooltipItem, data) {
  let datasetLabel = data.datasets[tooltipItem.datasetIndex].label
  let yLabel = genericUtils.formatPorcentage(tooltipItem.yLabel)
  return datasetLabel + ': ' + yLabel
}

function formatPercentageBarTooltip(tooltipItem, data) {
  let datasetLabel = data.datasets[tooltipItem.datasetIndex].label
  let yLabel = genericUtils.formatPorcentage(tooltipItem.xLabel)
  return datasetLabel + ': ' + yLabel
}

let utils = {
  createLineDataSet: createLineDataSet,
  normalizeData: normalizeData,
  type: type,
  PROP_VALUES: PROP_VALUES,
  formats: {
    tooltip: {
      formatPercentageBarTooltip: formatPercentageBarTooltip,
      formatCurrencyTooltip: formatCurrencyTooltip,
      formatCurrencyDonutTooltip: formatCurrencyDonutTooltip,
      formatPercentageTooltip: formatPercentageTooltip
    },
    xAxes: {
      formatXAxesDate: formatXAxesDate,
      formatXAxesDateDay: formatXAxesDateDay
    },
    yAxes: {
      formatYAxesCurrency: formatYAxesCurrency,
      formatYAxesPercentage: formatYAxesPercentage
    }
  }
}

export default utils
