import React from 'react'
import { injectIntl } from 'react-intl'
import FintupComponent from '../../FintupComponent'
import classNames from 'classnames'
import './CheckboxNew.scss'
/*
  * Basado en RadioButton.js y adaptado a checkboxs, mucho mejor construido que Checkbox.js
  Props:
    className: Clases que se pondran en la raiz del componente
    disabled: Deshabilita el campo
    isBoldChecked: {true|false} Si está marcado, al hacer checked, el texto se pondrá en negrita
    theme: 'new'
    * 'new': Nuevo estilo de componentes similar al diseño minitest
*/
class CheckboxNew extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
  }

  handleChange (ev) {
    if (this.props.disabled) {
      return false
    }
    let data = {
      ev: ev,
      name: this.props.name,
      value: !this.props.value,
      extraData: this.props.extraData
    }
    this.props.onChangeCheckbox(data)
  }

  get labelText () {
    let text = this.props.textKey ? this.props.intl.formatMessage({ id: this.props.textKey }) : this.props.text
    return text
  }

  get componentClasses () {
    return classNames(
      'fintupCheckboxNew',
      'checkbox',
      this.props.titleCheckbox ? 'titleCheckbox' : '',
      {[`theme-${this.props.theme}`]: this.props.theme},
      this.props.isBoldChecked ? 'boldChecked' : '',
      this.props.className
    )
  }

  render () {
    return (
      <p className={this.componentClasses}>
        <input disabled={this.props.disabled} type="checkbox" id={this.props.name} name={this.props.name} defaultChecked={this.props.value} defaultChecked={this.props.value} onChange={this.handleChange.bind(this)} />
        <label htmlFor={this.props.name}>{this.labelText}</label>
      </p>
    )
  }
}

export default injectIntl(CheckboxNew)
