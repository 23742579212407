import React from 'react'
import { injectIntl } from "react-intl";
import FintupComponent from "../FintupComponent";
import './ContractStep3.scss';
import FintupButton from '../common/forms/FintupButton';
import ContractTabs from './ContractTabs';
import constants from '../../utils/constants';
import TitleLabel from '../common/forms/TitleLabel';
import SelectLabel from '../common/forms/SelectLabel';
import InputTextLabel from '../common/forms/InputTextLabel';
import Checkbox from '../common/forms/Checkbox';
import DatePickerLabel from '../common/forms/DatePickerLabel';
import RadioButton from '../common/forms/RadioButton';
import RadioGroup from '../common/forms/RadioGroup';
import transverser from '../../utils/transverser';
import { getContractPersonalData, existsDniId, profileUpdate, profileUpdatePhoneDni } from '../../utils/fintup-api';
import SessionController from '../../core/SessionMobx';
import utils from '../../utils/utils';
import moment from 'moment'
import ContractExpertHelp from './ContractExpertHelp';
import RSVP from 'rsvp'

class ContractStep3 extends FintupComponent
{
  constructor(props){
    super(props)
    this.formatDocumentExpiryDate = 'DD/MM/YYYY'

    this.state = {
      actualStep: 4,

      //Error fields text
      documentTypeIdErrorText: '',
      documentErrorText: '',
      documentExpiryDateErrorText: '',
      nationalityErrorText: '',
      maritalTypeIdErrorText: '',
      doubleNationalityErrorText: '',
      secondNationalityErrorText: '',
    }

    this.momentMinDocumentExpiryDate = moment()
    this.formatMinDocumentExpiryDate = this.momentMinDocumentExpiryDate.format(this.formatDocumentExpiryDate)
  }

  hideErrorsText(){
    return this.setStatePromise({
      documentTypeIdErrorText: '',
      documentErrorText: '',
      documentExpiryDateErrorText: '',
      nationalityErrorText: '',
      maritalTypeIdErrorText: '',
      doubleNationalityErrorText: '',
      secondNationalityErrorText: '',
    })
  }

  componentDidMount(){
    //Check if exists values fillables
    let state = transverser.get('model.step3')(this.props)
    let existsStep = transverser.get('model.step3', false)(this.props) ? true : false

    if(!existsStep){
      getContractPersonalData()
      .then(response => {
          let defaultDni = SessionController.userData.dni
          let hasDefaultProfile = false
          let hasDefaultDni = false
  
          if (defaultDni) {
            hasDefaultDni = true
          }
    
          let peronalDataModel = {
            documentTypeId: transverser.get('documentTypeId', "1")(response.data),
            document: transverser.get('document', defaultDni)(response.data) || defaultDni,
            documentExpiryDate: transverser.get('documentExpiryDate', '')(response.data),
            nationality: transverser.get('nationality', '')(response.data),
            maritalTypeId: transverser.get('maritalTypeId', '')(response.data),
            doubleNationality: (transverser.get('doubleNationality', "2")(response.data)) == true ? "1" : "2",
            secondNationality: transverser.get('secondNationality', '')(response.data) || '',
            hasDefaultDni: hasDefaultDni,
            hasDefaultProfile: hasDefaultProfile
          }
          peronalDataModel.documentTypeId = peronalDataModel.documentTypeId != '' && peronalDataModel.documentTypeId != null ? peronalDataModel.documentTypeId : 1
          peronalDataModel.documentExpiryDate = peronalDataModel.documentExpiryDate != '' && peronalDataModel.documentExpiryDate != null ? moment(peronalDataModel.documentExpiryDate, this.formatDocumentExpiryDate).toDate() : ''

          console.log("peronalDataModel contractStep3: ", peronalDataModel)

          return this.setStatePromise(peronalDataModel)
      })
    }else{
      this.setStatePromise(state)
    }
  }

  /**
  * Validation fields and check
  * if we can go to the next step
  */
  next(){
    let documentTypeId = this._getState('documentTypeId', '')
    let documentt = this._getState('document', '').trim()
    let nationality = this._getState('nationality', '').trim()
    let maritalTypeId = this._getState('maritalTypeId', '')
    let documentExpiryDate = this._getState('documentExpiryDate', '')
    let momentDocumentExpiryDate = utils.parseDate({ date: documentExpiryDate, format: this.formatDocumentExpiryDate })
    let doubleNationality = this._getState('doubleNationality', '')
    let secondNationality = this._getState('secondNationality', '').trim()

    let isError = false;

    //13 - documentTypeId
    this.setStatePromise({documentTypeIdErrorText: ''})
    if (documentTypeId === '') {
      this.setStatePromise({documentTypeIdErrorText: 'ContractPersonalData.validation.docType'})
      isError = true
      utils.scrollToTop()
    }

    //14 - document
    this.setStatePromise({documentErrorText: ''})
    if (documentTypeId == '1' && (!utils.isNif(documentt) || documentt === '')) {
      this.setStatePromise({documentErrorText: 'ContractPersonalData.validation.docNumber'})
      isError = true
      utils.scrollToTop()
    } else if (documentTypeId == '2' && (!utils.isNie(documentt) || documentt === '')) {
      this.setStatePromise({documentErrorText: 'ContractPersonalData.validation.docNumber'})
      isError = true
      utils.scrollToTop()
    } else if (documentTypeId == '3' && documentt === '') {
      this.setStatePromise({documentErrorText: 'ContractPersonalData.validation.docNumber'})
      isError = true
      utils.scrollToTop()
    }   

    //15 - documentExpiryDate
    this.setStatePromise({documentExpiryDateErrorText: ''})
    if (!utils.validateDate({ date: momentDocumentExpiryDate, minDate: this.momentMinDocumentExpiryDate, allowEmpty: true })) {
      this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'ContractPersonalData.validation.documentExpiryDate', type: constants.NOTIFICATION_TYPE.ERROR})
      isError = true
      utils.scrollToTop()
    }

    //16 - maritalTypeId    
    this.setStatePromise({maritalTypeIdErrorText: ''})
    if (maritalTypeId === '') {
      this.setStatePromise({maritalTypeIdErrorText: 'ContractPersonalData.validation.estadoCivil'})
      isError = true
      utils.scrollToTop()
    }

    //17 - nationality
    this.setStatePromise({nationalityErrorText: ''})
    if (nationality === '') {
      this.setStatePromise({nationalityErrorText: 'ContractPersonalData.validation.nationality'})
      isError = true
      utils.scrollToTop()
    }

    //18 - doubleNationality (radio button)
    //19 - secondNationality
    this.setStatePromise({secondNationalityErrorText: ''})
    if (doubleNationality != null && doubleNationality == 1 && secondNationality === '') {
      this.setStatePromise({secondNationalityErrorText: 'ContractPersonalData.validation.secondNationality'})
      isError = true
      utils.scrollToTop()
    }

    if(isError){
      return
    }

    //Check repeat dni
    return existsDniId({dni: documentt})
    .then((response) => {
      if(response.data && !response.data.exists){
        //Update profile -> dni (it's necessary for user_documents) and phone 
        //Only if hasDefaultDni is false
        let promise
        if(this.state.hasDefaultDni){
          console.log("has default dni, not save dni and phone")
          promise = RSVP.resolve()
        }
        else{
          console.log("NOT default dni, save it and phone")
          promise = profileUpdatePhoneDni({
            dni: documentt,
            phone: transverser.get('model.step1.phone')(this.props)
          })
        }

        console.log("MOMENT: ", momentDocumentExpiryDate)
        
        promise.then(() => {
          console.log("update dni and phone", documentt, transverser.get('model.step1.phone')(this.props))
          SessionController.updateDni(documentt)
          this.hideErrorsText()
          .then(() => {
            if(documentExpiryDate != '')
              return this.setStatePromise({formatDocumentExpiryDate: moment(documentExpiryDate).format(this.formatDocumentExpiryDate)})
            else
              return RSVP.resolve()
          })
          .then(() => {
            this.props.updateModel({
              ...this,
              step3: this.state,
            })
          })
          this.props.nextStep()
        })
        .catch(() => {
          this.handleNotification({titleKey: 'regsterView.validation.error', textKey: 'contractBasicData.error.update', type: constants.NOTIFICATION_TYPE.ERROR})
        })
      }
      else{
        this.setStatePromise({documentErrorText: 'ContractPersonalData.validation.docNumber.repeat'})
        isError = true
        utils.scrollToTop()
      }
    })
  } 

  get renderDoubleNationality(){
    return this.state.doubleNationality == 1 ? 
    (
      <div className="row">
              <TitleLabel 
                title="19"
                descriptionKey="contractStep.title27"
                theme="new">
              </TitleLabel>
              <InputTextLabel placeholder="ContractPersonalData.secondNationality"
               name="secondNationality" theme="new"
               isError={this.state.secondNationalityErrorText != ''}
               errorTextKey={this.state.secondNationalityErrorText}
               value={this.state.secondNationality} callBackOnChange={this.commonHandleChange.bind(this)} readOnly={!this.state.doubleNationality}/>
            </div>
    ) : ''
  }

  render(){
    return(
      <div className="contractStep3">
        <div className="content text-center">
          <ContractTabs values={this} blockNextItems={true} actualStep={this.state.actualStep} titleKey="contractGeneralView.title1" elementActive="2"></ContractTabs>
          <div className="container contentData">
            <div className="row">
              <TitleLabel 
                title="13"
                descriptionKey="contractStep.title21"
                theme="new">
              </TitleLabel>
              <SelectLabel theme="new"
              name="documentTypeId" 
              placeholder="ContractPersonalData.tipoDocumento"
              isError={this.state.documentTypeIdErrorText != ''}
              errorTextKey={this.state.documentTypeIdErrorText}
              options={transverser.get('listOptions.docOptions', '')(this.props.model)} 
              selectedValue={this.state.documentTypeId} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="14"
                descriptionKey="contractStep.title22"
                theme="new">
              </TitleLabel>
              <InputTextLabel readOnly={this.state.hasDefaultDni} theme="new"
               name="document" placeholder="ContractPersonalData.numeroDocumento" 
               isError={this.state.documentErrorText != ''}
               errorTextKey={this.state.documentErrorText}
               value={this.state.document} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="15"
                descriptionKey="contractStep.title23"
                theme="new">
              </TitleLabel>
              <DatePickerLabel 
                className="theme-new"
                name="documentExpiryDate"
                showLeadingZeros={true}
                disableCalendar={true}
                dayPlaceholder={"DD"}
                monthPlaceholder={"MM"}
                yearPlaceholder={"AAAA"}
                callBackOnChange={this.commonHandleChange.bind(this)}
                clearIcon={null}
                value={this.state.documentExpiryDate}/>

            </div>
            <div className="row">
              <TitleLabel 
                title="16"
                descriptionKey="contractStep.title24"
                theme="new">
              </TitleLabel>
              <SelectLabel theme="new" 
              name="maritalTypeId" placeholder="ContractPersonalData.estadoCivil" 
              isError={this.state.maritalTypeIdErrorText != ''}
              errorTextKey={this.state.maritalTypeIdErrorText}
              options={transverser.get('listOptions.maritalTypeOptions', '')(this.props.model)} selectedValue={this.state.maritalTypeId} callBackOnChange={this.commonHandleChange.bind(this)} />
            </div>
            <div className="row">
              <TitleLabel 
                title="17"
                descriptionKey="contractStep.title25"
                theme="new">
              </TitleLabel>
              <InputTextLabel theme="new" 
              placeholder="ContractPersonalData.nacionalidad" 
              isError={this.state.nationalityErrorText != ''}
              errorTextKey={this.state.nationalityErrorText}
              name="nationality" value={this.state.nationality} 
              callBackOnChange={this.commonHandleChange.bind(this)}/>
            </div>
            <div className="row">
              <TitleLabel 
                title="18"
                descriptionKey="contractStep.title26"
                theme="new">
              </TitleLabel>
              <RadioGroup theme="new" >
                <RadioButton radioId="radioSi" 
                name="doubleNationality" 
                textKey="YES" 
                value="1"
                selectedOption={this.state.doubleNationality}
                onChangeRadio={this.commonHandleChange.bind(this)} />
                <RadioButton radioId="radioNo" 
                name="doubleNationality" 
                textKey="NO.lower" 
                value="2"
                selectedOption={this.state.doubleNationality}
                onChangeRadio={this.commonHandleChange.bind(this)} />
              </RadioGroup>
            </div>
            {this.renderDoubleNationality}
          </div>
          <div className="buttonsContainer">
            <FintupButton fintupIcon="flecha-seleccionable-izquierda"
            iconRight={true} textKey="button.common.next" 
            className="buttonContainer buttonPrevMiniTest"
            buttonClassName="btn-bold" size={constants.BUTTON_SIZE.ONLY_ICON} action={this.props.prevStep} />
            <FintupButton
              fintupIcon="flecha-seleccionable-derecha" 
              iconRight={true} textKey="button.common.next" 
              className="buttonContainer buttonAttention"
              buttonClassName="btn-bold"
              size={constants.BUTTON_SIZE.XLARGE} 
              action={() => this.next()}/>
          </div>
          <ContractExpertHelp isMobile={true}></ContractExpertHelp>
        </div>
        <ContractExpertHelp></ContractExpertHelp>
      </div>
    )
  }
}
export default injectIntl(ContractStep3)
