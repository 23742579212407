import React, { Component } from 'react'
import config from '../../../config/config'
import Recaptcha from 'react-google-invisible-recaptcha'
import './Captcha.css'
import SessionController from '../../../core/SessionMobx'

class Captcha extends Component {
  constructor (props) {
    super(props)
    this.state = {
      props: props,
      resolved: false
    }
  }

  execute () {
    if (config.captchaEnable && !SessionController.isLogged()) {
      this.recaptcha.execute()
      setTimeout(() => {
        if (!this.state.resolved && this.props.error) {
          this.props.error()
        }
      }, config.captchaTimeout)
    } else {
      if (this.props.success) {
        this.props.success()
      }
    }
  }

  onResolved () {
    this.setState({ resolved: true })
    if (this.props.success) {
      this.recaptcha.reset()
      this.props.success()
    }
  }

  render () {
    return (
      <div className="captcha">
        { config.captchaEnable && !SessionController.isLogged() ? (
          <Recaptcha
            badge="inline"
            ref={ref => (this.recaptcha = ref)}
            sitekey={config.captchaKey}
            onResolved={this.onResolved.bind(this)} />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default Captcha
