import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'
import classNames from 'classnames'
import './CheckboxGroup.css'

/*
  Props
    theme: 'new'
    * 'new': Nuevo estilo de componentes similar al diseño minitest
*/
class CheckboxGroup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      groupKey: uniqid()
    }
  }

  get childrenWithProps () {
    let childrenWithProps = this.props.children.map((child, index) => {
      return React.cloneElement(child,
        {
          key: `${this.state.groupKey}_${index}`,
          horizontal: this.props.horizontal,
          theme: this.props.theme
        })
    })

    return this.props.horizontal
      ? <div className="col-sm-9">{childrenWithProps}</div>
      : childrenWithProps
  }

  get renderLabel () {
    let text = this.props.textKey
      ? this.props.intl.formatMessage({ id: this.props.textKey })
      : this.props.text
        ? this.props.text
        : ''
    let label = ''
    let labelClasses = classNames(this.props.horizontal ? 'fintupCheckboxLabelHorizontal' : 'fintupCheckboxLabel')
    if (text !== '') {
      label = <label className={labelClasses} htmlFor="group">{text}</label>
    }

    return this.props.horizontal
      ? <div className="col-sm-3">{label}</div>
      : label
  }

  get componentClasses () {
    return classNames(
      'checkboxGroup',
      {'horizontal': this.props.horizontal}
    )
  }

  render () {
    return (
      <div className={this.componentClasses}>
        {this.renderLabel}
        {this.childrenWithProps}
        <div className="clearAll"/>
      </div>
    )
  }
}

export default injectIntl(CheckboxGroup)
