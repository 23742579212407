import { extendObservable } from 'mobx'
import { browserHistory } from 'react-router'
import _ from 'lodash'
import RSVP from 'rsvp'
import urls from '../utils/urls'
import config from '../config/config'
import navUtils from '../core/navUtils'
import classNames from 'classnames'
import constants from '../utils/constants'
import ProductsController from './ProductsMobx'
import moment from 'moment'
import GlobalPositionGraphicLineModel from '../components/globalPosition/GlobalPositionGraphicLineModel'

class SessionMobx {
  constructor () {
    extendObservable(this, {
      user: '',
      name: '',
      token: '',
      userData: {},
      redirectUrl: null,
      oneTimePassword: false,
      emulateSession: false,
      isFullScreen: false,
      themeNew: false,
      showFooter: true,
      isWidget: false,
      showLoginButton: false,
      showRegisterButton: false,
      showOperationBar: false,
      partnerSession: false,
      partnerType: null,
      oldForm: false,
      activeOperation: 'midinero',
      scriptsLoaded: {
        calendly: false
      },
      academyCategoryView: constants.ACADEMY_VIEW.CATEGORIES,
      unloggedUrl: null
    })
  }

  isSessionExpired () {
    let data = sessionStorage.getItem('fintupLoginMessage')
    return data !== null
  }

  exitSession () {
    ProductsController.destroyProducts()
    SessionController.destroySession()
    GlobalPositionGraphicLineModel.destroyGraphicData()
  }

  getSessionExpiredMessage () {
    let data = sessionStorage.getItem('fintupLoginMessage')
    sessionStorage.removeItem('fintupLoginMessage')
    return JSON.parse(data)
  }

  sessionExpired () {
    let isEmulated = this.isEmulatedSession
    SessionController.exitSession()
    let loginMessage = {
      code: 'SESSIONEXPIRED',
      message: 'Su sesión ha expirado, es necesario hacer login de nuevo'
    }
    sessionStorage.setItem('fintupLoginMessage', JSON.stringify(loginMessage))
    navUtils.logoutRedirect(isEmulated)
  }

  get singleName () {
    if (this.userData.surname && this.userData.surname !== '') {
      return this.userData.name
    } else {
      let aName = this.name.split(' ')
      return aName[0]
    }
  }

  get getShowFooter () {
    return this.showFooter
  }

  get appContainerClass () {
    return classNames(
      this.isFullScreen ? 'fintupFullContainer' : 'fintupHeaderContainer',
      this.token !== '' ? 'fintupPrivateContainer' : 'fintupPublicContainer',
      {'fintupFullWidget': this.isWidget},
      {'hasOperationBar': this.showOperationBar},
      {'themeNew': this.themeNew},
      {'oldForm': this.oldForm}
    )
  }

  get email () {
    return this.userData.username
  }

  get isEmulatedSession () {
    return this.emulateSession
  }

  checkSession () {
    if (!config.needLogin) {
      this.restoreSessionDateFromStorage()
      return RSVP.resolve()
    } else {
      if (!this.isLogged()) {
        let location = browserHistory.getCurrentLocation()
        this.unloggedUrl = location.pathname
        navUtils.goToInternal(urls.internal.login)
        return RSVP.reject()
      } else if (this.oneTimePassword) {
        navUtils.goToInternal(urls.internal.changePassword)
        return RSVP.reject()
      } else {
        return RSVP.resolve()
      }
    }
  }

  restoreSessionDateFromStorage () {
    let data = sessionStorage.getItem('fintupData')
    if (data && data !== 'null') {
      let dataObj = JSON.parse(data)
      this.user = dataObj.user
      this.name = dataObj.name
      this.token = dataObj.token
      this.userData = dataObj.userData
      this.partnerType = dataObj.partnerType
      this.oneTimePassword = dataObj.oneTimePassword
      this.emulateSession = dataObj.emulateSession
      this.partnerSession = dataObj.partnerSession
      this.extendData = dataObj.extendData
      this.createSessionTimer()
    }
  }

  isLogged () {
    if (_.isEmpty(this.token)) {
      this.restoreSessionDateFromStorage()
    }
    return !_.isEmpty(this.token)
  }

  destroySession () {
    this.user = ''
    this.token = ''
    this.name = ''
    this.userData = {}
    this.oneTimePassword = false
    this.emulateSession = false
    this.partnerSession = false
    this.partnerType = null
    this.redirectUrl = null
    this.extendData = {}
    sessionStorage.setItem('fintupData', null)
    this.destroySessionTimer()
  }

  createSessionTimer () {
    if (this.extendData && this.extendData.limitSessionDate) {
      let timeoutFunction = () => {
        console.log('expiramos la sesion')
        this.sessionExpired()
      }
      let actualDate = moment()
      let limiteDate = moment(this.extendData.limitSessionDate)
      let sessionMaxTimeMillis = limiteDate.diff(actualDate)
      console.log(`la sesion expirara en ${sessionMaxTimeMillis}`)
      if (sessionMaxTimeMillis > 0) {
        this.sessionTimer = setTimeout(timeoutFunction, sessionMaxTimeMillis)
      }
    }
  }

  destroySessionTimer () {
    if (this.sessionTimer) {
      clearTimeout(this.sessionTimer)
    }
  }

  setSession (sessionData) {
    this.user = sessionData.user
    this.name = sessionData.name
    this.token = sessionData.token
    this.userData = sessionData.userData
    this.oneTimePassword = sessionData.oneTimePassword
    this.emulateSession = sessionData.emulateSession
    this.partnerSession = sessionData.partnerSession
    this.partnerType = sessionData.partnerType
    this.redirectUrl = sessionData.redirectUrl
    this.extendData = sessionData.extendData
    sessionStorage.setItem('fintupData', JSON.stringify(this))
    this.createSessionTimer()
  }

  updateDni (dni) {
    this.userData.dni = dni
    sessionStorage.setItem('fintupData', JSON.stringify(this))
  }

  getLoginUrl () {
    let loginUrl = this.oneTimePassword ? urls.internal.changePassword : urls.internal.initUrl
    return loginUrl
  }

  redirectToUnloggedUrl () {
    if (this.unloggedUrl !== null) {
      let goToUnloggedUrl = this.unloggedUrl
      this.unloggedUrl = null
      navUtils.goToInternal(goToUnloggedUrl)
    } else {
      this.redirectToApp()
    }
  }

  redirectToApp () {
    let goToUrl = this.getLoginUrl()
    navUtils.goToInternal(goToUrl)
  }
}
var SessionController = new SessionMobx()
export default SessionController
