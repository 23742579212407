import React from 'react'
import FintupComponent from "../FintupComponent";
import './FintupButtonIconSelectable.scss'
import FintupIcon from './FintupIcon';
import { FormattedMessage } from 'react-intl';
import FontAwesome from 'react-fontawesome'
import constants from '../../utils/constants';
import classNames from 'classnames'
import Spinner from 'react-spinkit'
import { injectIntl } from 'react-intl'

/*
  Props:
    action: evento onclick del componente
    className: clases que se añaden al componente
    icon: Icon fontAwesome a mostrar junto el boton
    fintupIcon: Icono fintup a mostrar junto el boton
    iconImage: El icono se debe representar como una imagen <img>
    isLoading: Pone un loading en el boton y deshabilita la accion sobre el mismo
    size: Tamaño del boton
      Si se especifica ONLY_ICON el boton no tendrá texto
    noButton: Si se quiere que sea sin efecto botón, solo para mostrar información

*/
class FintupButtonIconSelectable extends FintupComponent {
  constructor (props) {
    super(props)
    this.state = {
      props: props
    }
  }

  doClick (ev) {
    if (!this.props.isLoading && this.props.action) {
      this.props.action(ev)
    }
  }

  get content () {
    let content
    if (this.props.icon) {
      content = (<div><FontAwesome name={this.props.icon}/><p className={this.props.classNameText}><FormattedMessage id={this.props.textKey}/></p></div>)
    } 
    else if (this.props.iconImage) {
      content = (<div><img src={this.props.iconImage} className={this.props.classNameIcon} alt={(<FormattedMessage id={this.props.textKey} />)}/><p className={this.props.classNameText}><FormattedMessage id={this.props.textKey} /></p></div>)
    }
    else if (this.props.size === constants.BUTTON_SIZE.ONLY_ICON) {
      content = <div><FintupIcon icon={this.props.fintupIcon} size={this.props.fintupIconSize} className={this.props.classNameIcon}/></div>
    } else if (this.props.fintupIcon) {
      content = (<div><FintupIcon icon={this.props.fintupIcon} size={this.props.fintupIconSize} className={this.props.classNameIcon}/><p className={this.props.classNameText}><FormattedMessage id={this.props.textKey} /></p></div>)
    } 
    else {
      content = this.props.textKey
        ? <FormattedMessage id={this.props.textKey} />
        : this.props.text
    }
    return content
  }

  render () {
    let containerClass = classNames('fintupButtonIconSelectable', 
    this.props.className, 
    this.loadingButtonClass,
    this.props.noButton ? 'noButton': '')
    let content = this.props.isLoading ? <Spinner name='circle' fadeIn='none' /> : this.content
    return (
      <div className={containerClass} disabled={this.disabledState} onClick={() => this.doClick()}>
        {content}
      </div>
    )
  }
}

export default injectIntl(FintupButtonIconSelectable)
