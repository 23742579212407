import React, { Component } from 'react'
import { Router, Route, browserHistory } from 'react-router'
import Nav from '../components/header/Nav'
import SessionController from '../core/SessionMobx'
import classNames from 'classnames'
import CopyRight from '../components/footer/CopyRight'
import ConfigRoutes from '../core/ConfigRoutes'
import CookiesAdvise from '../components/common/CookiesAdvise'
import { observer } from 'mobx-react'
import './Fintup.scss'
import '../styles/browsers.scss'
import uniqid from 'uniqid'
import FintupHeader from './FintupHeader'
import { detect } from 'detect-browser'
import config from '../config/config'
import CopyRightNew from './footer/CopyRightNew'
import ReactNotification from 'react-notifications-component'

class Fintup extends Component {
  constructor (props) {
    super(props)
    const browser = detect()
    this.state = {
      props: props,
      browserData: browser || {}
    }
    ConfigRoutes.checkRoute()
  }

  get copyRight () {
    //console.log("SESSION CONTROLLER fintup.js: ", SessionController)
    return SessionController.getShowFooter ? 
            (SessionController.themeNew ? <CopyRightNew/> : <CopyRight />) : ''
  }

  get cookies () {
    return SessionController.getShowFooter ? <CookiesAdvise /> : ''
  }

  render () {
    let containerClasses = classNames(
      'fintup container-fluid',
      SessionController.appContainerClass,
      {'safari': this.state.browserData.name === 'safari'},
      {'ios': this.state.browserData.name === 'ios'},
      {'chrome': this.state.browserData.name === 'chrome'},
      config.mainCustomClass
    )
    return (
      <div className={containerClasses}>
        <ReactNotification />
        <FintupHeader/>
        <Nav />
        <div className="appContent">
          <Router history={browserHistory}>
            {ConfigRoutes.routes.map(route => {
              let loadedRoute = route.getView
                ? route.getView()
                : route.view
              return <Route key={uniqid()} path={route.path} component={loadedRoute} nextUrl={route.nextUrl} nextLabel={route.nextLabel}/>
            })}
          </Router>
        </div>
        {this.copyRight}
        {/* {this.cookies} */}
      </div>
    )
  }
}

export default observer(Fintup)
