import React, { Component } from 'react'
import classNames from 'classnames'
import constants from '../../utils/constants'
import { FormattedMessage } from 'react-intl'
import './Alert.css'
import utils from '../../utils/utils'
/**
 * PROPS:
 *  textKey: Texto (multiidioma) a mostrar
 *  values: Variables que se sustituyen en el texto multiidioma
 *  text: Texto a mostrar
 *  scroll: Si viene con tue, cuando la alerta cambia se hace scroll a la parte superior de la pantalla (donde se encuentra la alerta)
 */
class Alert extends Component {
  constructor (props) {
    super(props)
    this.state = {props: props}
  }

  get text () {
    return this.props.textKey
      ? <FormattedMessage id={this.props.textKey} values={this.props.values}/>
      : this.props.text
  }

  // componentDidUpdate(prevProps, prevState, snapshot)
  componentDidUpdate (prevProps) {
    // Si el componente tiene activo el funcionamiento de scroll y cambia el texto de la alerta respecto al ciclo anterior
    if (this.props.scroll &&
      (this.props.text !== '' && this.props.textKey !== '') &&
      (this.props.textKey !== prevProps.textKey || this.props.text !== prevProps.text)) {
      utils.scrollToTop()
    }
  }

  render () {
    let showAlertClass = this.props.show ? '' : 'hideAlert'
    let alertTypeClass = (() => {
      switch (this.props.type) {
        case constants.alertType.ERROR:
          return 'alert-danger'
        case constants.alertType.SUCCESS:
          return 'alert-success'
        default:
          return 'alert-danger'
      }
    })()
    let alertClasses = classNames('alert', alertTypeClass, showAlertClass, this.props.className)
    return (
      <div data-componen="alert" className={alertClasses} role="alert">
        {this.text}
      </div>
    )
  }
}

export default Alert
