import React from 'react'
import FintupComponent from "../../FintupComponent";
import { injectIntl } from "react-intl";
import classNames from 'classnames'
import './TitleLabel.scss'
import FontAwesome from 'react-fontawesome'
import FintupTooltip from '../FintupTooltip';
import FintupIcon from '../FintupIcon';
import utils from '../../../utils/utils';
import navUtils from '../../../core/navUtils';

class TitleLabel extends FintupComponent {
  constructor(props) {
    super(props)
    this.state = {
      tooltipId: utils.randomNumber(200)
    }
  }

  get titleText() {
    let text = this.props.titleKey ? this.props.intl.formatMessage({ id: this.props.titleKey }) : this.props.title
    return text
  }

  get descriptionText() {
    let text = this.props.descriptionKey ? this.props.intl.formatMessage({ id: this.props.descriptionKey }) : this.props.description
    return text
  }

  onClickInternalHandle() {
    if (this.props.urlClickTooltip && this.props.urlClickTooltip != "") {
      navUtils.goToExternal(this.props.urlClickTooltip)
    }
  }

  get tooltip() {
    return this.props.isTooltip ? (
      <div onClick={() => this.onClickInternalHandle()}>
        <span className="tooltipIcon" data-tip data-for={`tooltipId-${this.state.tooltipId}`}><FintupIcon icon="info" size="15" weight="500" /></span>
        <FintupTooltip tooltipId={`tooltipId-${this.state.tooltipId}`}>
          {this.props.children}
        </FintupTooltip>
      </div>
    ) : ''
  }

  render() {
    let classContainer = classNames(
      'titleLabel',
      { [`theme-${this.props.theme}`]: this.props.theme },
      this.props.showArrow === false ? 'no-arrow' : '',
      this.props.className)
    let title = this.props.title ||
      this.props.titleKey ?
      <span className="title">{this.titleText}</span> : ''
    let description = this.props.description ||
      this.props.descriptionKey ?
      <span tooltipId="uploadLaterTooltip" className="description">{this.descriptionText}</span> : ''

    let arrow = this.props.showArrow === false ? '' : <img src="/img/arrow-right.svg" />
    return (
      <div className={classContainer}>
        {title}
        {/*<FontAwesome name="arrow-right" size="xs"></FontAwesome>*/}
        {arrow}
        {description}
        {this.tooltip}
      </div>
    )
  }
}

export default injectIntl(TitleLabel)