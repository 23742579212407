import React, { Component } from 'react'
import classNames from 'classnames'

class ModalBody extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    let classNamesContainer = classNames('modal-body', this.props.className)
    return (
      <div className={classNamesContainer}>
        {this.props.children}
      </div>
    )
  }
}

export default ModalBody
