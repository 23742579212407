import React from 'react'
import utils from '../../utils/utils'
import constants from '../../utils/constants'
import { reactivateEmailService } from '../../utils/fintup-api'
import SingleFormContainer from '../../components/common/SingleFormContainer'
import { FormattedMessage, FormattedHTMLMessage, injectIntl } from 'react-intl'
import Captcha from '../common/forms/Captcha'
import InputTextLabel from '../../components/common/forms/InputTextLabel'
import FintupButton from '../common/forms/FintupButton'
import FintupForm from '../common/forms/FintupForm'
import FintupView from '../FintupView'
import './Reactivate.scss'
import urls from '../../utils/urls'
import navUtils from '../../core/navUtils'
import HeaderTags from '../common/HeaderTags'
import RSVP from 'rsvp'

class Reactivate extends FintupView {
  constructor (props) {
    super(props)
    this.state = {
      props: props,
      email: '',
      alertText: '',
      alertType: '',
      resolved: false,
      usernameFieldErrorText: ''
    }
  }

  doSendEmail () {
    let email = this.state.email
    let isError = false;

    this.setStatePromise({usernameFieldErrorText: ''})
    if (!utils.validateEmail(email)) {
      this.setStatePromise({usernameFieldErrorText: 'Reactivate.emailValidate'})
      isError = true
    }
    
    if(!isError)
      this.captcha.execute()
  }

  doSend () {
    let email = this.state.email
    reactivateEmailService({
      email: email
    })
    .then(response => {
      this.handleNotification({titleKey: 'Reactivate.titulo', textKey: 'Reactivate.sendEmail', type: constants.NOTIFICATION_TYPE.SUCCESS})
    })
    .catch((response) => {
      this.handleNotification({titleKey: 'Reactivate.titulo', text: response.message, type: constants.NOTIFICATION_TYPE.ERROR})
    })
  }

  get renderTags(){
    return (this.props.isWidget) ? 
    '' : 
    <HeaderTags title="headerTags.reactivate.title" description="headerTags.reactivate.description"></HeaderTags>

  }

  render () {
    return (
      <div className="newStyles row reactvate">
        {this.renderTags}
        <div className="col-sm-8 col-sm-offset-2 col-xs-12">
          <FintupForm>
            {/*<h1><FormattedMessage id="Reactivate.titulo"></FormattedMessage></h1>*/}
            <p className="reactivateLegend"><FormattedMessage id="Reactivate.legend1"/></p>
            <p className="reactivateLegend"><FormattedHTMLMessage id="Reactivate.legend2"/></p>
            <InputTextLabel 
            placeholder="Reactivate.email"
            theme="new"
            isError={this.state.usernameFieldErrorText != ''}
            errorTextKey={this.state.usernameFieldErrorText} name="email" 
            callBackOnChange={this.commonHandleChange.bind(this)} />
            <div className="buttonContainer">
              <FintupButton type="submit" 
                    size={constants.BUTTON_SIZE.XXLARGE} 
                    textKey="Reactivate.enviar" 
                    action={this.doSendEmail.bind(this)} />
            </div>
            {/*<p className="subLegendContainerLeft" onClick={() => navUtils.goToInitUrl()} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="Reactivate.init"/></p>*/}
            {/*<p className="subLegendContainerRight" onClick={() => navUtils.goToInternal(urls.internal.register())} role="presentation" onKeyPress={utils.noop}><FormattedMessage id="Reactivate.register"/></p>*/}
            <Captcha ref={ref => (this.captcha = ref)} success={this.doSend.bind(this)}/>

          </FintupForm>
        </div>
      </div>
    )
  }
}

export default injectIntl(Reactivate)
