import _ from 'lodash'
import globalConfig from './config.common'
import SessionController from '../core/SessionMobx'
import utils from '../utils/utils'
import constants from '../utils/constants'

let mergeConfig = {}
let location = window.location.href

console.log(`Cargando la configuracion para la url -> ${location}`)
if (location.startsWith('http://localhost')) {
  console.log('Cargando la configuracion local')
  let enviromentConfig = require('./config.local')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('preapp.fintup.com') !== -1 || location.indexOf('d2azorllb6oxny.cloudfront.net') !== -1) {
  console.log('cargando la configuracion pre')
  let enviromentConfig = require('./config.pre')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('front-test.fintup.com') !== -1) {
  console.log('cargando la configuracion test')
  let enviromentConfig = require('./config.test')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
} else if (location.indexOf('cnp.clienteswebapp.com') !== -1) {
  console.log('cargando la configuracion de cnp')
  let enviromentConfig = require('./config.cnp')
  if (enviromentConfig) {
    mergeConfig = enviromentConfig.default
  }
}

let config = _.merge(globalConfig, mergeConfig)

function getBooleanConfig (name, defaultValue = false) {
  let value = config[name]
  if (value) {
    return JSON.parse(value)
  } else {
    return defaultValue
  }
}

function getArrayConfig (name, defaultValue = []) {
  let value = config[name]
  if (value) {
    value = utils.removeBlanks(value).split(constants.CHARS.COMMA)
    return value
  } else {
    return defaultValue
  }
}

function getFlowMeterConfig (flowMeterName) {
  let userDni = SessionController.userData.dni
  let flowMeterData = config[flowMeterName] || ''
  if (flowMeterData === '*') return true
  let aFlowMeterData = flowMeterData.split(',').map(a => _.trim(a))
  return _.includes(aFlowMeterData, userDni)
}

export { getBooleanConfig, getFlowMeterConfig, getArrayConfig }

export default config
