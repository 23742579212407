import React, { Component } from 'react'
import { injectIntl } from 'react-intl'
import uniqid from 'uniqid'
import classNames from 'classnames'
import './RadioGroup.css'

/*
  Props
    disabled: deshabilita todos los componentes hijo
    theme: 'new'
    * 'new': Nuevo estilo de componentes similar al diseño minitest
*/
class RadioGroup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      groupKey: uniqid()
    }
  }

  get divSeparator () {
    return this.props.horizontal ? <div className="clearAll"/> : ''
  }

  get childrenWithProps () {
    let childrenWithProps = this.props.children.map((child, index) => {
      return React.cloneElement(child,
        {
          key: `${this.state.groupKey}_${index}`,
          horizontal: this.props.horizontal,
          disabled: this.props.disabled,
          theme: this.props.theme
        })
    })
    return childrenWithProps
  }

  render () {
    let text = this.props.text ? this.props.intl.formatMessage({ id: this.props.text }) : this.props.textLiteral
    let label = ''
    let labelClasses = classNames(this.props.horizontal ? 'labelGroupHorizontal' : 'labelGroup')
    if (text) {
      label = <label className={labelClasses} htmlFor="group">{text}</label>
    }

    return (
      <div className="radioGroup">
        {label}
        {this.divSeparator}
        {this.childrenWithProps}
        {this.divSeparator}
      </div>
    )
  }
}

export default injectIntl(RadioGroup)
