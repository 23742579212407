import React from 'react'
import { injectIntl, FormattedMessage } from 'react-intl'
import FintupComponent from '../../FintupComponent'
import 'react-rangeslider/lib/index.css'
import transverser from '../../../utils/transverser'
import classNames from 'classnames'
import _ from 'lodash'
import './SliderLabel.scss'

/* Props:
  text: Texto del label
  textKey: Clave del texto del label a traducir
  img: Imagen del slider
*/
class SliderLabel extends FintupComponent {
  get text () {
    return this.props.text
      ? this.props.text
      : <FormattedMessage id={this.props.textKey}/>
  }

  get img () {
    return this.props.img
      ? <img src={this.props.img} alt="img"/>
      : ''
  }

  get barLine () {
    let showLine = transverser.get('showLine', true)(this.props)
    return showLine
      ? (<div className="verticalLine">
        <div className="verticalLineContent">&nbsp;</div>
      </div>)
      : ''
  }

  getWithoutClass () {
    let hasImage = !_.isUndefined(this.props.img)
    let hasBar = transverser.get('showLine', true)(this.props)
    if (!hasImage && !hasBar) {
      return 'withoutImageAndBar'
    }
    if (!hasImage) {
      return 'withoutImage'
    }
    if (!hasBar) {
      return 'withoutBar'
    }
  }

  get componentClasses () {
    return classNames(
      'sliderLabel text-center',
      this.getWithoutClass()
    )
  }

  render () {
    return (
      <div className={this.componentClasses}>
        {this.img}
        {this.barLine}
        <p className="sliderLabelText">{this.text}</p>
      </div>
    )
  }
}

export default injectIntl(SliderLabel)
