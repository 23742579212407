import RSVP from 'rsvp'

function setStatePromise (newData, resolvePromise = true) {
  return new RSVP.Promise((resolve, reject) => {
    this.setState(newData, () => {
      resolvePromise ? resolve() : reject()
    })
  })
}

let componentUtils = {
  setStatePromise: setStatePromise
}

export default componentUtils
